@import "datepicker-config";

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */

.datepicker--nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid map_get($datepickerBorderColor, nav);
  min-height: $datepickerNavigationHeight;
  padding: $datepickerPadding;

  .-only-timepicker- & {
    display: none;
  }
}

.datepicker--nav-title,
.datepicker--nav-action {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.datepicker--nav-action {
  width: $datepickerDayCellSize;
  border-radius: $datepickerBorderRadius;
  user-select: none;

  &:hover {
    background: map_get($datepickerBG, hover);
  }

  &.-disabled- {
    visibility: hidden;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  path {
    fill: none;
    stroke: map_get($datepickerTextColor, navArrows);
    stroke-width: 2px;
  }
}

.datepicker--nav-title {
  border-radius: $datepickerBorderRadius;
  padding: 0 8px;

  i {
    font-style: normal;
    color: map_get($datepickerTextColor, navArrows);
    margin-left: 5px;
  }

  &:hover {
    background: map_get($datepickerBG, hover);
  }

  &.-disabled- {
    cursor: default;
    background: none;
  }
}

//  Buttons
// -------------------------------------------------

.datepicker--buttons {
  display: flex;
  padding: $datepickerPadding;
  border-top: 1px solid map_get($datepickerBorderColor, nav);
}

.datepicker--button {
  color: map_get($datepickerTextColor, currentDate);
  cursor: pointer;
  border-radius: $datepickerBorderRadius;
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;

  &:hover {
    color: map_get($datepickerTextColor, common);
    background: map_get($datepickerBG, hover);
  }
}