$datepickerDayCellSize: 32px !default;
$datepickerWidth: 250px !default;
$datepickerMinBodyHeight: 170px !default;
$datepickerBorderRadius: 4px !default;
$datepickerPadding: 4px !default;
$datepickerZIndex: 100 !default;

$datepickerFontFamily: Tahoma !default;
$datepickerFontSize: 14px !default;

$datepickerYearsPerRow: 4 !default;

$datepickerTextColor: (
  button: #5cc4ef,
  otherMonth: #dedede,
  otherMonthInRange: #ccc,
  disabled: #aeaeae,
  currentDate: #4EB5E6,
  common: #4a4a4a,
  dayNames: #FF9A19,
  navArrows: #9c9c9c
) !default;

$datepickerBG: (
  selected: #5cc4ef,
  selectedHover: darken(#5cc4ef, 5),
  inRange: rgba(#5cc4ef, .1),
  hover: #f0f0f0
) !default;

$datepickerBorderColor: (
  nav: #efefef,
  inline: #d7d7d7,
  default: #dbdbdb
) !default;

$datepickerNavigationHeight: 32px !default;
$datepickerNavigationButtonsOffset: 2px !default;

$datepickerPointerSize: 10px !default;
$datepickerPointerOffset: 10px !default;

// Transitions
$datepickerTransitionSpeed: .3s !default;
$datepickerTransitionEase: ease !default;
$datepickerTransitionOffset: 8px !default;

// Objects
%otherMonth {
  color: map_get($datepickerTextColor, otherMonth);

  &:hover {
    color: darken(map_get($datepickerTextColor, otherMonth), 10);
  }

  &.-disabled- {
    &.-focus- {
      color: map_get($datepickerTextColor, otherMonth);
    }
  }

  &.-selected- {
    color: #fff;
    background: lighten(map_get($datepickerBG, selected), 15);
    &.-focus- {
      background: lighten(map_get($datepickerBG, selected), 10);
    }
  }
  
  &.-in-range- {
    background-color: map_get($datepickerBG, inRange);
    color: darken(map_get($datepickerTextColor, otherMonth), 7);

    &.-focus- {
      background-color: rgba(map_get($datepickerBG, inRange), .2);
    }
  }


  &:empty {
    background: none;
    border: none;
  }
}