﻿@import "../variables.scss";
@import "../mixins.scss";

.mobile-header-display-none {
    @media screen and (max-width: 600px){
        display: none;
    }
}

.articles {
    article {
        width: 100%;
        position: relative;


        header {
            /*margin-bottom: 12px;*/
        }

        h3 {
            color: $color_14;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 5px;

            @include tablet-landscape {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 10px;
            }
        }

        p {
            font-size: 14px;
            line-height: 16px;
            color: $color_3;

            @include tablet-landscape {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 8px;
            }
        }

        figure {
            overflow: hidden;
            text-align: left;
            /*width: 32%;*/
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 95px;
            max-height: calc(95px*0.68);
            @include myTransition();

            @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: no-wrap, horizontal: center, vertical: center )));

            @include tablet {
                width: 145px;
                max-height: calc(145px*0.67);
            }

            @include tablet-landscape {
                width: 180px;
                max-height: 120px;

                img {
                    width: 100%;
                }
            }
        }

        img {
            height: 100%;
            display: inline-block;
            vertical-align: top;
        }

        .article-link {
            position: absolute;
            z-index: 1;
            background-color: transparent;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            padding: 250%;
        }

        .article-holder {
            position: relative;
            padding-left: 95px;
            min-height: calc(95px*0.69);

            p {
                color: black;
            }

            @include tablet-landscape {
                padding-left: 180px;
                min-height: 120px;
            }
        }

        .description {
            padding: 0 0 0 10px;

            @include tablet {
                padding: 0 50px 0 25px;
            }

            @include tablet-landscape {
                padding-left: 30px;
            }
        }

        .date {
            font-size: 15px;
            display: block;
            color: #909191;

            @include tablet-landscape {
                font-size: 15px;
            }
        }

        .read-more {
            display: none;

            @include tablet-landscape {
                display: inline-block;
            }
        }

        &.hero-article {
            background-color: #DAF0F6;

            @include max-tablet-landscape {
                margin-top: -16px;
            }

            h3 {
                font-size: 20px;
                line-height: 26px;
                color: $color_14;
                margin-bottom: 5px;

                @include tablet-landscape {
                    font-size: 36px;
                    line-height: 40px;
                    margin-bottom: 12px;
                }
            }

            header {
                margin-bottom: 12px;

                @include tablet-landscape {
                    margin-bottom: 36px;
                    margin-top: 36px;
                }
            }

            .date {
                @include tablet-landscape {
                    font-size: 16px;
                }
            }

            p {
                font-size: 12px;
                line-height: 16px;
                color: $color_1;

                @include tablet-landscape {
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 16px;
                }
            }

            figure {
                width: 100%;
                max-width: 100%;
                height: auto;
                max-height: 30vh;
                position: static;

                @include tablet {
                    max-height: 40vh;
                }

                @include tablet-landscape {
                    max-height: none;
                    position: absolute;
                    width: 50%;
                    max-width: 507px;
                }

                @include max-tablet-landscape {
                    min-height: 230px;
                }
            }

            img {
                height: auto;
                width: 100%;

                @include tablet-landscape {
                    height: 100%;
                }
            }

            .article-holder {
                padding-left: 0;

                @include tablet {
                    padding-left: 0;
                }

                @include tablet-landscape {
                    min-height: 320px;
                    padding-left: 43%;
                    margin-top: -4px;
                }

                p {
                    color: #FFFFFF;
                }
            }

            .description {
                width: 100%;
                padding: 14px 22px;

                @include tablet-landscape() {
                    padding: 0 82px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 320px;
                    padding-left: 148px;
                }

                @include desktop() {
                    padding-left: 82px;
                }
            }

            @include max-tablet-landscape {
                background: transparent;

                h3 {
                    color: $color_14;
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 12px;
                }


                .description {
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-top: 7px;
                }

                .article-holder {
                    padding: 0 10px;
                }
            }
        }
    }
}

.article-list {

    @include tablet {
    }

    @include tablet-landscape {
        @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: wrap, horizontal: space-between )));
        margin-top: 50px;

        .all-news-items:nth-child(odd) {
            border-right: 1px solid #D5D5D5;
            padding-bottom: 60px;
        }

        .all-news-items:nth-child(even) {
            padding-left: 54.5px;
            padding-bottom: 60px;
        }

        .all-news-items:nth-last-of-type(2), .all-news-items:last-of-type {
            padding-bottom: 0px;
        }
    }


    article {
        @include tablet-landscape {
            width: 50%;
        }
    }
}

.article-page {
    article {
        padding-bottom: 40px;
    }

    header {
        margin-top: 12px;
        margin-bottom: 12px;

        @include max-tablet-landscape {
            margin-bottom: 10px;
            margin-top: 7px
        }

        h3 {
            font-size: 18px;
            line-height: 22px;
            color: $color_14;

            @include max-tablet-landscape {
                font-size: 24px;
                color: $color_14;
                margin-bottom: 5px;
                font-weight: bold;
                line-height: 26px;
            }

            @include tablet-landscape {
                color: #0C2733;
                line-height: 40px;
                margin-bottom: 15px;
                font-size: 36px;
                font-weight: bold;
            }
        }

        @include tablet-landscape {
            margin-top: 0;
        }
    }

    .article-holder {
        width: 100%;

        @include max-tablet-landscape {
            display: flex;
            flex-direction: column;
        }

        @include tablet-landscape {
            @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: nowrap, horizontal: center )));
        }

        &[data-width="small"] {
            @include form-container(map-merge($form-container-default, ( max-width: 600px )));
        }

        &[data-width="middle"] {

            @include form-container(map-merge($form-container-default, ( max-width: 740px )));
        }


        &[data-width="large"] {


            @include form-container(map-merge($form-container-default, ( max-width: 866px )));

            @include tablet {
                @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: no-wrap, horizontal: center )));
            }
        }
    }

    .figure-holder {
        &.large-img {
            @include tablet-landscape {
                @include flex();
                max-width: 400px;
                width: 100%;
                margin-right: 60px;
            }
        }

        &.small-img {
            max-width: 130px;
            width: 100%;
            padding-right: 20px;
            padding-left: 10px;

            @include tablet {
                max-width: 120px;
                padding-left: 0px;
                @include flex();
            }

            @include tablet-landscape {
                padding-right: 40px;
            }

            figure {
                padding: 50%;
            }
        }
    }


    figure {
        overflow: hidden;
        height: auto;
        width: 100%;
        background: #e8e8e8;
        position: relative;
        padding: 34% 50%;

        img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }
    }

    .news-details {
        figure {
            width: 400px;
            height: 266.66px;
            padding: 0;
            margin-bottom: 0;
            background: transparent;


            @include max-tablet-landscape {
                width: 100%;
                height: 230px;
                padding: 0 10px;
                margin: 0 auto;
            }

            img {
                width: 100%;
                position: static;
                margin: 0;
            }

            @media screen and (min-width: 601px) and (max-width:990px) {
                padding: 0;
            }
        }
    }

    .description {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        padding: 0 10px;

        @include tablet-landscape {
            width: calc(100% - 620px);
            padding: 0;
            @include flex2(1);
        }

        img {
            max-width: 100% !important;
            height: auto !important;
            margin: 5px 0 10px;
            box-shadow: 0 8px 6px -6px black;
        }
    }

    .date {
        font-size: 15px;
        display: block;
        color: $color_7;

        @include tablet-landscape {
            font-size: 16px;
            color: $color_7;
        }
    }

    .text-format {
        font-size: 14px;
        line-height: 18px;
        color: $color_14;

        @include tablet-landscape {
            font-size: 16px;
            line-height: 26px;
            margin-top: 22px;
        }

        ol {
            padding-left: 24px;
        }

        p {
            margin-bottom: 28px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                display: inline-block;
                vertical-align: top;
                max-width: 100%;
            }
        }
    }
}


.lates-news {
    margin-bottom: 10px;

    @include tablet-landscape {
        margin-top: -10px;
    }

    &.sub-content-section .sub-content-header h2 {
        color: $color_15;
    }

    figure {
        overflow: hidden;
        width: 100%;
        position: relative;
        padding: 34% 50%;
        margin-bottom: 10px;
        background: #e0dee0;

        @include myTransition();
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        min-height: 100%;
        width: 100%;
        bottom: 0;
    }

    h3 {
        font-size: 20px;
        color: #0C2733;
        line-height: 32px;

        @include tablet-landscape {
            font-size: 24px;
        }

        a {
            display: block;
            text-overflow: ellipsis;
            width: 100%;
            color: $color_15;
            overflow: hidden;
        }
    }

    .date {
        font-size: 15px;
        color: #909191;
        margin-bottom: 10px;
        display: block;
    }

    p {
        font-size: 12px;
        line-height: normal;

        @include tablet-landscape {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .article-holder {

        p {
            color: black;
        }
    }

    .grid {
        .grid-item {

            @include tablet-landscape {
                margin-right: 15px;
            }

            @include tablet-landscape {
                -ms-flex-preferred-size: calc((100% - 328px)/3);
                flex-basis: calc((100% - 328px)/3);
                max-width: calc((100% - 328px)/3);
            }

            &:last-child {
                margin-right: 0;
            }

            &.balance-container {
                width: 100%;
                -ms-flex-preferred-size: 340px;
                flex-basis: 340px;
                text-align: left;

                @include tablet-landscape {
                    margin-right: 15px;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                    -ms-flex-preferred-size: 285px;
                    flex-basis: 285px;
                    max-width: 285px;
                }

                .balance-list-header {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 24px;
                    text-align: left;
                    color: #0C2733;
                    padding-bottom: 7px;
                }

                .balance-list-former-label {
                    text-align: center;
                    padding: 4px;
                    background: #8DA3A9;
                    color: #FFFFFF;
                    font-size: 14px;
                }

                .current-relation {
                    background: #E7F9FE;
                }

                .relation-row {
                    width: 100%;
                    height: 80px;
                    font-size: 14px;
                    line-height: 19px;
                    margin-bottom: 3px;
                    background-color: #DAF0F6;
                    border-radius: 8px;

                    a {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: space-between;
                        text-decoration: none;
                        color: inherit;
                        padding: 15px;
                        padding-right: 10px;
                    }

                    .relation-container {
                        display: block;
                        width: 80%;

                        .relation-name {
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .relation-balance {
                            font-size: 26px;
                            line-height: 34px;
                            font-weight: bold;
                            text-align: left;
                            padding-bottom: 10px;
                            word-break: break-all;
                            white-space: nowrap;
                            overflow: hidden;
                            flex: 1 0 0;

                            &.negative-balance {
                                color: #A73B0B;
                            }

                            &.positive-balance {
                                color: #369B86;
                            }
                        }
                    }

                    .arrow-icon-container {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        border: 2px solid #00A9D6;
                        padding: 5px;
                        margin: auto 10px auto 24px;

                        .arrow-icon {
                            &:before {
                                display: flex;
                                font-family: 'icomoon';
                                content: "\e911";
                                font-size: 20px;
                                color: #00A9D6;
                                transform: rotate(180deg);
                            }
                        }

                        @include tablet-landscape {
                            margin-left: 12px;
                        }
                    }

                    @include tablet-landscape {
                        margin-bottom: 4px;
                    }
                }

                .expired-relation {
                    background-color: #EEEEEE;
                }

                .relation-row-small {
                    a {
                        padding: 10px;
                        padding-top: 17px;
                        padding-bottom: 15px;
                        color: #0C2733;
                        display: flex;
                    }

                    font-size: 13px;
                    line-height: 18px;
                    border-bottom: 1px solid #8DA3A9;
                }


                .relations-list-frame li:last-child {
                    margin-bottom: 0px;
                }

                @include max-tablet-landscape {
                    .relation-row {
                        a {
                            padding-top: 11px;
                            padding-bottom: 11px;
                        }
                    }
                }

                .hide-button {
                    display: none;
                    color: #00A9D6;
                    padding: 9px;
                    border: none;
                    background-color: white;
                    width: 100%;
                    font-size: 13px;
                    line-height: 18px;
                    border-bottom: 2px solid #DAF0F6;
                    margin-bottom: 5px;
                }

                .desktop-button {
                    @include tablet-landscape {
                        display: block;
                    }
                }

                .mobile-button {
                    display: block;

                    @include tablet-landscape {
                        display: none;
                    }
                }

                .relation-row:hover {
                    background: #BCF1FF;
                }

                .relations-list-frame li:nth-of-type(n+3),
                .relations-list-frame .balance-list-former-label:nth-child(2),
                .relations-list-frame .balance-list-former-label:nth-child(2) + li {
                    display: none;

                    @include tablet-landscape {
                        display: flex;
                    }
                }

                .relations-list-frame-expanded li:nth-of-type(n+3),
                .relations-list-frame-expanded .balance-list-former-label:nth-child(2),
                .relations-list-frame-expanded .balance-list-former-label:nth-child(2) + li {
                    display: flex;
                }

                .relations-list-frame li:nth-of-type(n+4),
                .relations-list-frame .balance-list-former-label:nth-child(3),
                .relations-list-frame .balance-list-former-label:nth-child(3) + li {

                    @include tablet-landscape {
                        display: none;
                    }
                }

                .relations-list-frame-expanded li:nth-of-type(n+4),
                .relations-list-frame-expanded .balance-list-former-label:nth-child(3),
                .relations-list-frame-expanded *:nth-child(n+3),
                .relations-list-frame-expanded .balance-list-former-label:nth-child(3) + li {

                    @include tablet-landscape {
                        display: flex;
                    }
                }
            }

            @media screen and (max-width: 991px) and (min-width: 601px) {
                &.balance-container {
                    max-width: 550px;
                    margin: auto;
                }
            }
        }
    }
}

.error-balance-container, 
.error-issues-container, 
.error-news-container {
    color: #767676;
    font-size: 13px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.error-news-container {
    width: 74%;

    @include max-mobile-landscape {
        width: 100%;
    }
}

.error-issues-container {
    @include max-mobile-landscape {
        display: none;
    }
}

.hidden {
    display:none !important;
}

.latest-news-grid {

    &-container {
        width: 74%;
        display: block;
        margin-bottom: 20px;
        margin-top: 31px;

        @include max-tablet-landscape {
            width: 100%;
            padding-top: 0px;
        }
    }

    &-inner {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 45px;
        flex-direction: row;

        @include max-tablet-landscape {
            flex-direction: column;
            margin-bottom: 21px;
        }
    }

    &-header {
        display: none;

        @include max-tablet-landscape {
            display: block;
            border-bottom: 2px solid #0C2733;
            padding-bottom: 7px;

            h2 {
                font-size: 15px;
                color: #0C2733;
                text-transform: uppercase;
            }
        }
    }

    &-item {
        padding: 0px;
        margin-right: 15px;
        max-width: 280px;
        width: 50%;

        @include max-tablet-landscape {
            max-width: 100%;
            width: 100%;
            border-bottom: 1px solid #CCCCCC;
            padding-bottom: 6px;
            margin-top: 20px;

            .article-holder {
                display: flex;
                justify-content: flex-start;

                figure {
                    min-width: 95px;
                    width: 95px;
                    min-height: 63px;
                    height: 63px;
                    margin-right: 0;
                    padding: 0;
                }
            }

            .description {
                margin-top: -3px;
                margin-left: 16.5px;

                h3 {
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 5px;
                    line-height: 20px;
                }
            }
        }
    }

    article:last-of-type {
        margin-right: 0;
    }
}

    .to-all-issues-container {
        margin-top: 20px;
        margin-bottom: 55px;
    }

    .to-all-news-link, .to-all-issues-link {
        display: block;
        margin: 0 13px 20px auto;
        width: 130px;
        border-radius: 14px;
        text-align: center;
        background-color: #DAF0F6;
        color: #00A9D6;
        font-size: 13px;
        padding: 7px 12px 7px 5px;

        @include max-tablet-landscape {
            width: 230px;
            height: 30px;
            font-size: 12px;
            line-height: 16px;
            margin: auto;
            margin-bottom: 35px;
            margin-top: 15px;
        }

        @media screen and (max-width: 1200px) and (min-width: 991px) {
            margin-right: 0;
        }

        .arrow-right {
            vertical-align: bottom;
            margin-left: 3px;

            &::before {
                vertical-align: middle;
                font-family: 'icomoon';
                content: "\e911";
                font-size: 13px;
                margin-bottom: 1px;
                display: inline-block;
                color: #00A9D6;
                transform: rotate(180deg);
            }
        }



        &:hover {
            background-color: #BCF1FF;
        }
    }

    .to-all-issues-link {
        width: 135px;
    }

    @media screen and (max-width: 1200px) and (min-width: 991px) {
        .latest-news-grid {

            &-container {
                justify-content: space-around;
                width: 66%;
                display: block;
            }

            &-inner {
                width: 100%;
            }
        }
    }

    .home-table {
        @include max-tablet-landscape {
            display: none;
        }

        .create-maintenance-btn {
            margin-bottom: 5px;
        }
    }

.home-issues-header {
    display: block;
    border-bottom: 2px solid #0C2733;
    padding-bottom: 7px;
    margin-bottom: 15px;

    h2 {
        font-size: 20px;
        color: #0C2733;
        text-transform: uppercase;

        @include max-tablet-landscape {
            font-size: 15px;
        }
    }

    @include max-tablet-landscape {
        margin: 10px;
    }

    @include max-mobile-landscape {
        display: none;
    }
}

    .maintenance-header {
        margin-bottom: 6px;
        margin-top: -8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include max-tablet-landscape {
            flex-direction: column;
            margin: 0 10px;
            margin-bottom: 3px;
        }

        .create-maintenance-btn {
            height: 40px;
            font-size: 15px;
            line-height: 20px;

            @include max-tablet-landscape {
                width: calc(100%);
                height: 45px;
                margin-bottom: 20px;
                margin-top: -4px;
                font-size: 16px;
                line-height: 21px;
            }

            a {
                padding: 11px;
            }
        }

        .search-box-container {
            width: 500px;
            height: 40px;

            @include max-tablet-landscape {
                width: calc(100%);
            }

            .icon-search-position {
                position: absolute;
                top: 8px;
                left: 11px;
                font-size: 23px;
                color: #767676;
            }

            .input-pos {
                padding-left: 36px;
                width: 100%;
                height: 40px;
            }
        }
    }

    .mobile-header {
        @include tablet-landscape {
            display: none;
        }
    }

    .mobile-header-container {
        @include max-tablet-landscape {
            display: none;
        }
    }

    .all-news-items {
        @include max-tablet-landscape {
            max-width: 100%;
            width: 100%;
            border-bottom: 1px solid #CCCCCC;
            padding-bottom: 14px;
            margin-top: 18px;

            .article-holder {
                display: flex;
                justify-content: flex-start;

                figure {
                    min-width: 95px;
                    width: 95px;
                    min-height: 63px;
                    height: 63px;
                    margin-right: 0;
                    padding: 0;
                }
            }

            .description {
                margin-top: -5px;
                margin-left: 2px;

                h3 {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }

    .article-list {
        @include max-tablet-landscape {
            border-top: 1px solid #CCCCCC;
        }
    }

    .details-news-header {
        @include tablet-landscape {

            a {
                display: none;
            }
        }

        @include max-tablet-landscape {
            #contentHeaderMobile {
                color: #00A9D6;
                font-size: 15px;
                margin-left: 6.75px;
            }

            .header-hide {
                display: none !important;
            }

            a {
                margin-left: 0;
                display: flex;
                align-items: center;
            }

            .arrow-left {

                &::before {
                    font-family: 'icomoon';
                    content: "\e911";
                    font-size: 15px;
                    display: inline-block;
                    color: #00A9D6;
                }
            }
        }

        @media screen and (min-width: 768px) and (max-width:990px) {
            #contentHeaderMobile {
                font-size: 22px;
            }

            .arrow-left::before {
                font-size: 22px;
            }
        }
    }

.news-details-container{
    margin-top: 16px;
    @include max-tablet-landscape{
        margin-top:-14px;
    }
}

    