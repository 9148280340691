﻿@import "variables.scss";
@import "mixins.scss";

:root {
    //Initial DPI scale factor for original design
    --dpi-scale-factor: 1;
}

* {
    box-sizing: $default-box-sizing;
}

html {
    /*height: 100%;*/
}

body {
    margin: 0;
    height: auto;
    min-height: 100%;

    &.mobile-nav-open {
        overflow: hidden;
    }
}

#hidden-elements {
    & > div {
        display: none;
    }
}

.wrapper {
    height: 1px;
    min-height: 100vh;
    @include flex-container(map-merge($flex-container-default, ( direction: column, horizontal: stretch, vertical: stretch, wrap: nowrap )));
}

.content {
    padding-top: calc(#{$page-header-mobile-height} + 1px);
    @include flex();

    @include tablet {
        padding-top: calc(#{$page-header-tablet-height} + 1px);
    }

    @include tablet-landscape {
        padding-top: calc(#{$page-header-height} + 1px);

        &.left-navigation {
            padding-top: $page-header-mobile-height;
        }
    }

    .body-center {
        position: absolute;
        top: 40%;
        height: 30%;
        width: 100%;
        text-align: center;
    }
}

.content-wrap {
    max-width: $page-conteiner-width;
    margin: auto;

    @include tablet-landscape {
        padding: 0 10px;
    }

    &.left-navigation {
        @include navigation-min() {
            padding-left: $left-navigation-area-width !important;
        }
    }
}

.content-wrap-no-padding {
    @include tablet-landscape {
        padding: 0 0;
    }
}

.mobile-wrapper {
    padding: 0 10px;

    @include tablet-landscape {
        padding: 0;
    }
}
.content-apartments {
    margin-top: -1px;
    padding-top: $page-header-mobile-height;

    .content-wrap {
        max-width: inherit;
        margin: auto;

        &.left-navigation {
            padding-left: $left-navigation-area-width;

            @include navigation-max {
                padding-left: 0px;
            }
        }

        @include tablet-landscape {
            padding: 0;
        }

        .content-section {
            display: none;
        }

        .sub-header {
            display: none;
        }

        .iframe-wrapper {
            margin: auto;

            @include tablet-landscape {
                padding: 0 10px;
            }
        }
    }
}

.content-admin {
    margin-top: -1px;
    padding-top: calc(#{$page-header-tablet-height} + 1px);
}

.content-contracts {

    .content-wrap {
        max-width: inherit;
        margin: auto;

        @include tablet-landscape {
            padding: 0;
        }

        .content-section {
            display: none;
        }

        .sub-header {
            display: none;
        }

        .iframe-wrapper {
            margin: auto;

            @include tablet-landscape {
                padding: 0 10px;
            }
        }
    }
}

.content-register-payment {

    .content-wrap {
        .sub-header {
            padding: 10px 0;
        }

        .content-section {

            .content-header {
                padding: 18px 10px;

                h2::before {
                    display: none;
                }

                .icon-ArrowBack-White {
                    display: block;
                    color: $color_1;
                    position: relative;
                    width: 28px;
                    height: 28px;
                    line-height: 22px;
                    padding: 4px;
                    margin-left: -4px;
                    text-align: left;
                    font-weight: 500;
                    margin-right: 9px;
                    margin-bottom: -4px;
                    margin-top: -4px;

                    &:before {
                        display: block;
                        content: "\e911";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 22px;
                        height: 22px;
                        margin-left: 5px;
                    }

                    &:hover {
                        background: #0FB4E1;
                        margin-right: 9px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}

.content-successful-payment-registration {

    .content-wrap {
        .sub-header {
            padding: 10px 0;
        }

        .content-section {

            .content-header {
                padding: 18px 23px;

                h2::before {
                    display: none;
                }
            }

            .content-header h2 {
                font-size: 20px;
            }
        }
    }
}

.content-apartment-details {

    .content-wrap {
        .sub-header {
            padding: 10px 0;
        }

        .content-section {

            .content-header {
                max-width: 900px;

                h2::before {
                    display: none;
                }

                .icon-ArrowBack-White {
                    display: block;
                    color: $color_1;
                    position: relative;
                    width: 28px;
                    height: 28px;
                    line-height: 22px;
                    padding: 4px;
                    margin-left: -4px;
                    text-align: left;
                    font-weight: 500;
                    margin-right: 9px;
                    margin-bottom: -4px;
                    margin-top: -4px;

                    &:before {
                        display: block;
                        content: "\e911";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 22px;
                        height: 22px;
                        margin-left: 5px;
                    }

                    &:hover {
                        background: #0FB4E1;
                        margin-right: 9px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}

.content-contacts {

    .content-wrap {

        .sub-header {
            padding: 10px 0;
        }

        max-width: 1170px;

        @include tablet-landscape {
            padding: 0;
            margin: 0 auto;
        }

        .content-section {

            .content-header {
                max-width: $page-conteiner-width;
                margin: auto;

                @include tablet-landscape {
                    padding: 20px 0px;
                }

                @include mobile-portrait {
                    margin: 0 20px;
                    padding: 10px 0;
                }

                h2 {
                    text-transform: uppercase;
                    line-height: 24px;
                    font-size: 20px;
                }

                .users-link {
                    display: none;
                }

                .icon-ArrowNext {
                    display: none;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .iframe-wrapper {
            max-width: $page-conteiner-width;
            margin: auto;
        }
    }
}

.content-contact-details {

    .content-wrap {
        .sub-header {
            padding: 10px 0;
        }

        .content-section {

            .content-header {

                #contentHeader {
                    text-transform: none;
                }

                .users-link {
                    display: block;
                    color: #0FB4E1;
                    font-size: 20px;
                    cursor: pointer;
                }

                .icon-ArrowNext {
                    display: block;
                    color: $color_3;
                    position: relative;
                    width: 28px;
                    height: 28px;
                    line-height: 22px;
                    padding: 4px;
                    margin-left: 6px;
                    text-align: left;
                    font-weight: 500;
                    margin-right: 3px;
                    margin-bottom: -4px;
                    margin-top: -4px;

                    &:before {
                        display: block;
                        content: "\e90f";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 22px;
                        height: 22px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
/* Login */
.anonymous-page-light,
.anonymous-page {

    .wrapper {
        height: 100vh;
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .wrapper-top-padding {
        margin-top: 78px;
        height: calc(100vh - 78px - 78px);
        min-height: 50vh;
        display: flex;
    }

    @include max-tablet {
        .wrapper-top-padding {
            margin-top: 60px;
            height: calc( 100vh - 60px - 83px);
        }
    }

    .content, .page-footer {
        display: table-row;
        min-height: 1px;
    }

    .content {
        height: 100%;
    }
}

.anonymous-page-light {
    .msg {
        &.msg {
            &.msg-success.msg-box {
                background-color: #46CE78;
            }

            &.msg-error.msg-box {
                background-color: #FF0000;
            }

            &.msg-box {
                padding: 6px;
                text-align: center;
                line-height: 18px;
                font-size: 13px;
                margin: 0;
            }
        }
    }

    .wrapper {
        min-width: 250px;
    }

    .header-light {
        display: table-cell;
        background-image: url(../images/background2.jpg);
        background-size: 100% auto;

        @include tablet-landscape {
            background-position-y: -34px;
        }
    }

    .logo {
        width: 100%;
        margin: 30px 0 30px 0;

        @include mobile-portrait {
            margin: 16px 0 16px 0;
        }

        @include tablet-landscape {
            margin: 55px 0 55px 0;
        }

        figure {
            width: 100%;
            text-align: center;
            margin: 0;
            padding: 0;
        }

        img {
            display: inline-block;
            vertical-align: top;
            width: 32%;
            max-width: 332px;
            min-width: 126px;

            @include tablet-landscape {
                width: auto;
                min-width: 200px;
                max-height: 70px;
            }
        }
    }

    .anonymous-wrap {
        background: $page_background;
        display: table-cell;
        vertical-align: top;

        .anonymous-singlePlainText {
            margin: 40px 0;
            margin-bottom: 28px;
            font-size: 15px;
            line-height: 22px;
            color: $color_2;
        }

        .main-form-validation-container {
            min-height: 30px;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 5px;
            max-width: 350px;

            @include max-tablet {
                margin-top: 0;
                margin-bottom: 10px;
            }

            @include mobile-portrait {
                max-width: none;
            }
        }
    }
}

.anonymous-wrap {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.embeded-file-viewer-full {
    width: 100%;
    height: calc(100vh - 78px - 78px );
    display: block;
}

@include max-mobile-landscape {
    .anonymous-page {
        .wrapper-top-padding {
            height: calc( 100vh - 60px - 78px);
        }
    }

    .embeded-file-viewer-full {
        height: calc(100vh - 60px - 78px );
    }
}

@include max-tablet {
    .anonymous-page {
        .wrapper-top-padding {
            height: calc( 100vh - 60px - 80px);
        }
    }

    .embeded-file-viewer-full {
        height: calc(100vh - 60px - 80px );
    }
}

@include tablet {
    .anonymous-page {
        .wrapper-top-padding {
            height: calc( 100vh - 78px - 80px);
            margin-top: 78px;
        }
    }

    .embeded-file-viewer-full {
        height: calc(100vh - 78px - 80px );
    }
}

@include tablet-landscape {
    .anonymous-page {
        .wrapper-top-padding {
            height: calc( 100vh - 78px - 100px);
        }
    }

    .embeded-file-viewer-full {
        height: calc(100vh - 78px - 100px );
    }
}

//Query for 75% zoom with on 125% Windows DPI
@media (min-resolution: 90dpi) and (max-resolution: 95.9dpi)  {
    :root {
        --dpi-scale-factor: 0.70;
    }
}

//Query for 110% zoom with 100% Windows DPI
@media (min-resolution: 97dpi) and (max-resolution: 108dpi) {
    :root {
        --dpi-scale-factor: 1.07;
    }
}

//Query for 80%-90% zoom with 150% Windows DPI / 110% zoom with 125% Windows DPI
@media (min-resolution: 109dpi) and (max-resolution: 132dpi) {
    :root {
        --dpi-scale-factor: 1.45;
    }
}

//Query for 75% zoom with 150% Windows DPI / 90% zoom with 125% Windows DPI
@media (resolution: 108dpi) {
    :root {
        --dpi-scale-factor: 1.25;
    }
}

//Query for 100% zoom with 125% Windows DPI / 125% zoom with 100% Windows DPI
@media (resolution: 120dpi) {
    :root {
        --dpi-scale-factor: 1.165;
    }
}

//Query for 100% zoom with 150% Windows DPI
@media (resolution: 144dpi) {
    :root {
        --dpi-scale-factor: 1.654;
    }
}

