﻿@use 'sass:math';
@import "../variables.scss";
@import "../mixins.scss";

.btn {
    
            transition: background-color .2s ease-in;
    -webkit-transition: background-color .2s ease-in;
    border-radius: $btn-radius;
    display: inline-block;
    vertical-align: top;

    font-size: 15px;
    font-weight: 400;
    text-align: center;

    line-height: 18px;
    padding: 10px;

    background-color: $color_1;
    color: $color_34;
    border: 1px solid $color_7;
    min-width: 180px;
    &:hover{
        background-color: $color_23;
        color: $color_7;
        border: 1px solid $color_7;
    }


    &.btn-red {
        background-color: $red_btn;
        color: $red_btn_text;
        border: 1px solid $red_btn;

        &:hover{
            background-color: $red_btn_hover;
            color: $red_btn_hover_text;
            border: 1px solid $red_btn_hover;
        }
    }

    &.btn-aqua{
        background-color: $aqua_btn;
        color: $aqua_btn_text;
        border: 1px solid $aqua_btn;

        &:hover{
            background-color: $aqua_btn_hover;
            color: $aqua_btn_hover_text;
            border: 1px solid $aqua_btn_hover;
        }
    }

    &.btn-blue{
        background-color: $blue_btn;
        color: $blue_btn_text;
        border: 1px solid $blue_btn;

        &:hover{
            background-color: $blue_btn_hover;
            color: $blue_btn_hover_text;
            border: 1px solid $blue_btn_hover;
        }
    }

     &.btn-brown{
        background-color: $brown_btn;
        color: $brown_btn_text;
        border: 1px solid $brown_btn;

        &:hover{
            background-color: $brown_btn_hover;
            color: $brown_btn_hover_text;
            border: 1px solid $brown_btn_hover;
        }
    }

    &[disabled], &.disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.pay-button-desktop{
    display: none;
     @include tablet{
      display: block;
    }
}
}



.pay-button-mobile{
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
    background: #ffffff;
    width: 100%;
    padding: 7px 10px 8px 10px;
    border-top: 1px solid #95989A;
  z-index: 1;
    >.btn{
      width: 100%;
    }
    @include tablet{
  display: none;
  }
}

.map-link{
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    vertical-align: top;
    background-color: $blue_btn;
    color: $blue_btn_text;
    font-size: 15px;
    text-align: center;
    border-radius: 35px;
    border: none;

    @include tablet-landscape {
        font-size: 24px;
        width: 35px;
        height: 35px;
        line-height: 35px;
    }

    &:before{
        @include icomoon("\e917");
        width: 25px;
        height: 25px;
        line-height: 25px;
        display: inline-block;
         border-radius: 50%;
        vertical-align: top;

        @include tablet-landscape {
             width: 35px;
            height: 35px;
            line-height: 35px;
        }
    }

    &:hover{
        background-color: $blue_btn_hover;
        color: $blue_btn_hover_text;
    }
}

.link{
    
            transition: background-color .2s ease-in;
    -webkit-transition: background-color .2s ease-in;

    display: inline-block;
    vertical-align: top;

    font-size: 15px;
    font-weight: 400;
    color: $color_7;


    background-color: transparent;
    border: none;
    outline: none;
    &:hover{
        color: $color_7;
    }

    &.link-red {
        color: $red_btn;

        &:hover{
            color: $red_btn_hover;
        }
    }

    &.link-aqua{
        color: $aqua_btn;

        &:hover{
            color: $aqua_btn_hover;
        }
    }

    &.link-blue{
        color: $blue_btn;

        &:hover{
            color: $blue_btn_hover;
        }
    }

    &.link-brown{
        color: $brown_btn;

        &:hover{
            color: $brown_btn_hover;
        }
    }

    &.back-icon{
        position: relative;
        &:before{
            @include icomoon("\e911");
            line-height: 23px;
            display: inline-block;
            vertical-align: top;
            margin-right: 5px;
            font-size: 15px;
        }
    }
}

.create-maintenance-btn {
    font-size: 15px;
    width: 290px;
    text-align: center;
    border-radius: 5px;
    background-color: #F57E4A;

    &:hover {
        background-color: #C45A2C;
    }

    a {
        padding: 10px;
        color: #FFFFFF;
        display: inline-block;
        width: 100%;
    }
}

.group-btn {
    display: inline-block;
    vertical-align: top;
    @include clearfix();

    a {
        float: left;
        padding: 2px 19px;
        line-height: 24px;
        font-size: 14px;
        border-top: 1px solid $color_24;
        border-bottom: 1px solid $color_24;
        border-right: 1px solid $color_24;
        background-color: $color_1;
        color: $color_7;
        &:hover{
            background-color: $color_23;
            color: $color_7;
        }

        &:first-child{
            border-left: 1px solid $color_24;
            border-radius: $btn-radius 0 0 $btn-radius;
        }

        &:last-child{
            border-radius: 0 $btn-radius $btn-radius 0;
        }

        &.active{
            background-color: $blue_btn;
            color: $blue_btn_text;

            &:hover{
                background-color: $blue_btn_hover;
                color: $blue_btn_hover_text;
            }
        }
    }
}

.anonymous-page-light {
    .link {
        color: $link_redirect;

        &:hover {
            color: $link_redirect;
        }
    }

    .btn {
        font-size: 15px;
    }

    .socialLoginList {
        .btn {
            background: white;
            border: 1px solid #CCCCCC;
            border-radius: 4px;
            color: black;
            margin: 15px 0 0 0;
            width: 100%;
            padding: 11px;
            vertical-align: middle;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 3px 6px #00000029;

            img {
                width: 26px;
            }

            span {
                margin-left: 9px;
            }

            .btn:focus {
                outline: none;
            }
        }
    }

    .socialSeparator {
        color: $field_border;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;

        .separatorSideCell {
            width: 50%;
            display: table-cell;
            vertical-align: middle;

            .separatorSideLine {
                height: 1px;
                background: #CCCCCC;
                width: 100%;
            }
        }

        .separatorText {
            display: table-cell;
            font-size: 13px;
            padding: 0 12px;
            line-height: 18px;
        }
    }

    .socialLoginListHideSepr {
        .separatorSideCell,
        .separatorText {
            display: none;
        }

        .socialSeparator {
            height: 18px;
        }
    }
}

.anonymous-page {
    .link {
        color: $color_1;

        &:hover {
            color: $color_1;
        }
    }
}




// Hamburger
// ==================================================

// Settings
// ==================================================
$hamburger-padding-x                       : 0 !default;
$hamburger-padding-y                       : 0 !default;
$hamburger-layer-width                     : 28px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 4px !default;
$hamburger-layer-color                     : $color_1 !default;
$hamburger-layer-border-radius             : 0 !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  /*3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,*/
  spin,
  spin-r
  /*spring,
  spring-r,
  stand,
  stand-r,
  squeeze,
  vortex,
  vortex-r*/
) !default;


.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  -webkit-transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  -webkit-transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;
  -webkit-transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: math.div($hamburger-layer-height, -2);

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        -webkit-transition-property: transform;
        transition-duration: 0.15s;
        -webkit-transition-duration: 0.15s;
        transition-timing-function: ease;
        -webkit-transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &.property-manager-hamburger {
        &,
        &::before,
        &::after {
            background-color: #C6D0D2;
        }
    }
}


@if index($hamburger-types, spin) {
  /*
   * Spin
   */
  .hamburger--spin {
    .hamburger-inner {
      transition-duration: 0.22s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.1s 0.25s ease-in,
                    opacity 0.1s ease-in;
      }

      &::after {
        transition: bottom 0.1s 0.25s ease-in,
                    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.1s ease-out,
                      opacity 0.1s 0.12s ease-out;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease-out,
                      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}


@if index($hamburger-types, spin-r) {
  /*
   * Spin Reverse
   */
  .hamburger--spin-r {
    .hamburger-inner {
              transition-duration: 0.22s;
      -webkit-transition-duration: 0.22s;

              transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.1s 0.25s ease-in,
                    opacity 0.1s ease-in;

        -webkit-transition: top 0.1s 0.25s ease-in,
                    opacity 0.1s ease-in;
      }

      &::after {
                transition: bottom 0.1s 0.25s ease-in,
                    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        -webkit-transition: bottom 0.1s 0.25s ease-in,
                    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
                transform: rotate(-225deg);
        -webkit-transform: rotate(-225deg);
                transition-delay: 0.12s;
        -webkit-transition-delay: 0.12s;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
                  transition: top 0.1s ease-out,
                      opacity 0.1s 0.12s ease-out;
          -webkit-transition: top 0.1s ease-out,
                      opacity 0.1s 0.12s ease-out;
        }

        &::after {
          bottom: 0;
          transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transition: bottom 0.1s ease-out,
                      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
          -webkit-transition: bottom 0.1s ease-out,
                      transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

