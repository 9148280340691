/* Settings */
$default-box-sizing: border-box;
$page-conteiner-width: 1190px;
$page-header-height: 76px;
$page-header-tablet-height: 78px;
$page-header-mobile-height: 60px;
$border-radius: 5px;
$btn-radius: 5px;
$modal-radius: 9px;

/* Fonts family */
$font_1: 'Roboto', sans-serif;

/* Font size */
$heading_font: 36px;
$date_font: 16px;
$text_font: 18px;

/* Line height */
$text_line: 26px;
$heading_line: 42px;

/* beackpoints */
$mobile-portrait-width: 400px;
$mobile-landscape-width: 551px;
$tablet-width: 768px;
$tablet-landscape-width: 991px;
$small-desktop: 1024px;
$desktop: 1200px;

//if you change this size, you should update accordingly in the javascript in _HeaderPropertyManager. 
//There is a fix to show all items in the hamburger menu
/*$navigation-min: 1282px;*/
$navigation-min: 1250px;
$navigation-max: $navigation-min - 1px;

$email-length: 100px;

$user-navigation-min: $navigation-min + $email-length;
$user-navigation-max: $user-navigation-min - 1px;



/* colors */
$color_1: #ffffff; // bg
$color_2: #191919; // text
$color_3: #000000; // table text

/* gray */
$color_4: #e8e8e8;  // table header bg
$color_5: #767676; // table header text
$color_35: #737474;  // table header text #2
$color_6: #cccccc; // table borders
$color_36: #c8d8dc;  // article icon disable
$color_16: #9e9e9e; // coppy
$color_23: #dfdfdf; // active gray btn
$color_24: #95989a; // dd borders, checkbox
$color_33: #d2d2d2; // info icon color


$color_7: #909191;  // fields borders / btn white border 
$color_34: #767676; // btn white text

/* blue */
$color_8: #b9c2c5;  // table pagination / unactive state of pagi btns
$color_9: #e7f9fe;  // fields bg with value
$color_10: #8da3a9; // link main-nav/ article date / footer nav
$color_11: #bcf1ff; // table active/hover row
$color_12: #00A9D6; // button bg / active link main menu
$color_13: #2f9fbc; // page panels ( current balance )
$color_14: #0c2733; // header/footer bg /article titles
$color_15: #003c4b; // page titles 
$color_17: #002730; // login bg fields + 70% opacity
$color_18: #0687aa; // hover blue btn
$color_25: #005065; // news header
$color_26: #0d8cb0;
$color_27: #d9e6e9;

/* aqua */
$color_19: #3fc8c3;
$color_20: #32a29e;

/* red */
$color_21: #f04f4f;
$color_22: #b52e20;
$color_28: #e74c3c;

/* green */
$color_29: #319537;
$color_30: #2DA534;

/* brown */
$color_31: #B26D5C;
$color_32: #885143;
$color_33: #C6D0D2;
/* blue btn */
$blue_btn: $color_12;
$blue_btn_text: $color_1;
$blue_btn_hover: $color_18;
$blue_btn_hover_text: $color_1;


/* aqua btn */
$aqua_btn: $color_19;
$aqua_btn_text: $color_1;
$aqua_btn_hover: $color_20;
$aqua_btn_hover_text: $color_1;

/* red btn */
$red_btn: $color_21;
$red_btn_text: $color_1;
$red_btn_hover: $color_22;
$red_btn_hover_text: $color_1;

/* brown btn */
$brown_btn: $color_31;
$brown_btn_text: $color_1;
$brown_btn_hover: $color_32;
$brown_btn_hover_text: $color_1;

/* light login color scheme*/
$btn_main_light: #00A9D6;
$field_focused_border: #00A9D6;
$field_border: #909191;
$link_redirect: #00A9D6;
$validation_accent: #FF0000;
$page_background: #FFFFFF;
$text_color: #000000;

$color-left-navigation-header: #DDE6E8;
$left-navigation-gap: 25px;
$left-navigation-top-padding: 15px;
$left-navigation-area-height: 33px;
$left-navigation-area-width: 80px;
$left-navigation-margin-left: 8px;