﻿@use 'sass:math';
@import "variables";

@mixin clearfix {
    & {
        *zoom: 1;
    }

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin mobile-portrait {
    @media (max-width: #{$mobile-portrait-width}) {
        @content;
    }
}

@mixin mobile-landscape {
    @media (min-width: #{$mobile-landscape-width}) {
        @content;
    }
}

@mixin max-mobile-landscape {
    @media (max-width: #{$mobile-landscape-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin max-tablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}



@mixin tablet-landscape {
    @media (min-width: #{$tablet-landscape-width}) {
        @content;
    }
}

@mixin max-tablet-landscape {
    @media (max-width: #{$tablet-landscape-width - 1px}) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: #{$small-desktop}) {
        @content;
    }
}


@mixin desktop {
    @media (min-width: #{$desktop}) {
        @content;
    }
}

@mixin navigation-min {
    @media (min-width: #{$navigation-min}) {
        @content;
    }
}

@mixin navigation-max {
    @media (max-width: #{$navigation-max}) {
        @content;
    }
}

@mixin user-navigation-min {
    @media (min-width: #{$user-navigation-min}) {
        @content;
    }
}

@mixin user-navigation-max {
    @media (max-width: #{$user-navigation-max}) {
        @content;
    }
}


/* flex */

$flex-container-default: ( direction: column, wrap: wrap, horizontal: start, vertical: start );

@mixin flex-container($flex-container-default) {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -ms-flex-direction: map_get($flex-container-default, direction);
    flex-direction: map_get($flex-container-default, direction);
    -webkit-flex-direction: map_get($flex-container-default, direction);
    flex-wrap: map_get($flex-container-default, wrap);
    -webkit-flex-wrap: map_get($flex-container-default, wrap);
    align-items: map_get($flex-container-default, vertical);
    -webkit-align-items: map_get($flex-container-default, vertical);
    justify-content: map_get($flex-container-default, horizontal);
    -webkit-justify-content: map_get($flex-container-default, horizontal);
}

@mixin flex($grow: 1, $shrink: 0, $basis: auto) {
    -ms-flex: $grow $shrink $basis;
    flex: $grow $shrink $basis;
    -webkit-flex: $grow $shrink $basis;
    -webkit-box-flex: $grow $shrink $basis;
    /*flex-grow: $grow;
    -webkit-flex-grow: $grow;

            flex-shrink: $shrink;
    -webkit-flex-shrink: $shrink; 


            flex-basis: $basis;
    -webkit-flex-basis: $basis;*/
}

@mixin flex2($value) {
    -ms-flex: $value;
    flex: $value;
    -webkit-flex: $value;
    -webkit-box-flex: $value;
}

/* forms */
$form-container-default: ( width: 100%, max-width: 100%, margin: 0 auto );

@mixin form-container($form-container-default) {
    width: map_get($form-container-default, width);
    max-width: map_get($form-container-default, max-width);
    margin: map_get($form-container-default, margin);
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

@mixin myTransition($elements: all, $time: 0.3s, $animation: ease-in) {
    transition: $elements $time $animation;
    -webkit-transition: $elements $time $animation;
}

@mixin myTranslate($x, $y) {
    transform: translate($x, $y);
    -webkit-transform: translate($x, $y);
}


@mixin icomoon($code) {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: $code;
}

/**
 * Ratios
 * Returns the ratio for specified dimensions.
 */
@function ratio($width, $height) {
    @return percentage( math.div($height, $width));
}

@mixin generateRatios($width, $height, $prefix: "ratio-") {
    $class-name: $prefix + $width + "x" + $height;

    .#{$class-name} {
        padding-bottom: ratio($width, $height);
    }
    // Output example: .ratio-16x9 {}
}
