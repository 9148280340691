﻿@import "../variables.scss";
@import "../mixins.scss";

.main-nav {
    @include clearfix();

    a {
        color: $color_3;
        display: block;
        padding: 19px 0;
        line-height: 16px;
        font-size: 14px;
        text-align: left;

        &:hover,
        &.active {
            color: $color_12;
        }
    }

    .prime-nav, .user-mobile-nav {
        a {
            //text-transform: uppercase;
            color: $color_33;
            padding: 21px 0;
            line-height: 24px;
            font-size: 18px;
            text-align: left;
            /*@include tablet{
                padding: 30px 0;
                line-height: 24px;
                font-size: 24px;
            }*/

            @include navigation-min {
                text-align: center;
                padding: 17px 12px;
                line-height: 16px;
                font-size: 13px;
            }

            &:hover,
            &.active {
                color: $color_26;
            }
        }

        .only-desktop {
            a:hover {
                color: #FFFFFF;
            }
        }

        .only-mobile {
            a {
                color: $color_14;
                border-bottom: 1px solid #CCCCCC;
                font-size: 16px;
                line-height: 19px;
                padding: 18px 0 18px 24px;
            }
        }

        .only-mobile:last-child {
            a {
                border-bottom: none;
            }
        }

        .only-mobile:hover {
            background-color: $color_9;
        }

        .line-breaker {
            a {
                color: $color_14;
                border-bottom: 2px solid #0C2733;
                font-size: 16px;
                line-height: 19px;
                padding: 18px 0 18px 24px;
            }
        }

        .email {
            color: $color_3;
            font-size: 16px;
            text-align: left;
            padding-bottom: 24px;
            display: inline-block;
            vertical-align: top;

            &:before {
                font-size: 22px;
                line-height: 22px;
                font-family: "icomoon";
                width: 22px;
                height: 22px;
                content: "\e904";
                margin: 0 auto 14px;
                display: block;
                color: $color_33;
            }
        }
    }

    .user-mobile-nav {
        .nav-active-mobile {
            color: $color_12;
        }

        a {
            color: $color_14;
            border-bottom: 1px solid $color_24;
            font-size: 14px;
            line-height: 19px;
            padding: 18px 0 18px 24px;

            &:hover {
                color: $color_14;
                background-color: $color_9;
            }
        }
    }

    .mobile-nav, .user-actions {

        .nav-active {
            background-color: $color_12;
            color: #FFFFFF;
            border-radius: 2px;

            span {
                color: #FFFFFF;
                &.left-navigation {
                    color: #FFFFFF !important;
                }
            }

            &:before {
                color: #FFFFFF !important;
            }

            .tooltipstered {
                &:before {
                    color: #FFFFFF !important;
                }
            }
        }

        .prime-nav .only-mobile .nav-active-mobile {
            color: $color_12;
        }

        > ul {
            float: left;
        }

        .first-menu-item {
            margin-left: 116px !important;
        }

        .menu-item {
            margin-left: 36px;
            padding-top: 9px;

            .item-wrapper {
                a {
                    font-size: 14px;
                    height: 32px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    line-height: 19px;
                }
            }

            .item-wrapper:hover {
                background-color: #3E6070;
                border-radius: 2px;
            }
        }

        .resident-menu-items {
            @include navigation-min {
                text-transform: uppercase;
            }
        }

        li {
            @include navigation-min {
                float: left;
                margin: 0;
            }


            &.only-mobile {
                display: block;

                @include navigation-min {
                    display: none;
                }
            }

            &.only-desktop {
                margin-left: 36px;

                .custom-dropdown {
                    .dropdown-content {
                        .content-holder {
                            .custom-scroll {
                                ul {
                                    li {
                                        a {
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .flex {
                        display: flex;

                        .dropdown-label:before {
                            top: -4px;
                        }
                    }
                }

                display: none;

                @include navigation-min {
                    display: inline-block;
                }
            }
        }

        .undistributed-list-item {
            margin-left: 42px !important;
        }

        a {
            display: block;
        }

        .login-btn {
            text-align: center;
            height: 35px;
            padding: 12px;
            background: $color_14;
            border: 1px solid #2B5060;
            color: #0FB4E1;
        }
    }

    .mobile-nav {
        .menu {
            #projectsDropDown-mobile {
                a {
                    color: $color_14;
                    padding-left: 24px;

                    &:hover {
                        color: $color_14;
                        background-color: $color_9;
                    }
                }
            }
        }

        .my-profile-mobile-nav {
            .nav-header {
                height: 0;
                padding: 0;

                .icon-Close {
                    margin-top: 15px;
                    margin-right: -13px;
                }
            }

            .email {
                display: flex;
                margin-top: 22px;
                font-size: 14px;
                line-height: 19px;
                padding-bottom: 18px;

                &:before {
                    margin: 0 7px 0 0;
                }
            }

            ul li:last-child {
                a {
                    border-bottom: none;
                }
            }
        }
    }

    .user-actions {
        float: right;
        list-style: none;
        margin: 0;
        height: $page-header-mobile-height;
        padding: 14px 10px 14px 0;

        @include tablet {
            padding: 14px 0;
            height: $page-header-tablet-height;
        }

        @include navigation-min {
            padding: 0;
            height: auto;
        }

        @include navigation-max {
            position: absolute;
            right: 0;
            top: 0;
            margin: auto;
            bottom: 0;
        }

        &.left-navigation {
            height: 100%;
        }

        li {
            float: left;
            margin: 0;

            &.mobile-menu {
                display: inline-block;

                @include navigation-min {
                    display: none;
                }
            }
        }

        .login-btn {
            @include tablet {
                height: 40px;
                margin-top: 5px;
            }
        }

        a {
            color: $color_33;
            display: block;
            /*text-transform: uppercase;*/

            &.left-navigation {
                color: $color_3;
            }

            &:hover, &.active {
                color: $color_26;
            }

/*            @include tablet {
                color: $color_33;
            }*/
        }

        .mobile-nav-to-home {
            margin-right: 28px;

            a {
                color: #F5F5F5;
                font-size: 11px;
                line-height: 13px;
                text-align: center;
                margin: auto;
                padding: 2px 0;

                &:before {
                    vertical-align: middle;
                    font-family: 'icomoon';
                    content: "\e94a";
                    font-size: 24px;
                    display: inline-block;
                    height: 18px;
                    color: $color_1;
                }

                &.nav-active-mobile, &:active {
                    &:before {
                        content: "\e947";
                    }
                }

                @include tablet {
                    padding: 10px 0;
                }
            }

            @include tablet {
                margin-right: 10px;
            }
        }

        span {
            display: block;

            &:before {
                display: inline-block;
                vertical-align: top;
            }
        }


        .hamburger {
            width: 32px;
            height: 32px;
            line-height: 31px;
            text-align: center;

            @include tablet {
                width: 50px;
                height: 50px;
                line-height: 45px;
            }

            .hamburger-box {
                vertical-align: middle;

                @include tablet {
                    transform: scale(0.78);
                    -webkit-transform: scale(0.78);
                }
            }
        }

        .custom-dropdown {
            .dropdown-content {
                left: auto;
                right: 0;
            }
        }

        .user-mobile-menu {
            padding: 4px 10px;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @include tablet {
                padding: 12px 10px;
                /*max-width: 150px;*/
            }
            /*&:before{
                color: $color_10;
                display: none;
                @include tablet{
                    display: block;
                }
            }*/

            &:before {
                vertical-align: middle;
                font-family: 'icomoon';
                content: "\e904";
                font-size: 24px;
                display: inline-block;
                height: 24px;
                line-height: 24px;
                color: $color_33;
            }
        }

        .undistributedPayments-header-menu {
            padding: 4px 10px;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            position: relative;

            @include tablet {
                padding: 12px 10px;
                /*max-width: 150px;*/
            }
            /*&:before{
                color: $color_10;
                display: none;
                @include tablet{
                    display: block;
                }
            }*/
            .undistributedPayments-header-count {
                background: #3FC8AC;
                position: absolute;
                top: 2px;
                right: -3px;
                color: $color_14;
                border: 2px $color_14 solid;
                border-radius: 4px;
                font-size: 10px;
                padding: 1px 2px 2px 3px;
                line-height: 10px;

                @include max-tablet {
                    top: -5px;
                }
            }



            &:before {
                vertical-align: middle;
                font-family: 'icomoon';
                content: "\e93b";
                font-size: 25px;
                display: inline-block;
                height: 25px;
                line-height: 25px;
                color: $color_14;
                background: $color_33;
                border-radius: 4px;
            }

            &:hover:before {
                background: #3E6070;
                color: #FFFFFF;
            }
        }
    }

    .user-actions-login {
        right: 24px;
    }

    .mobile-nav {
        display: inline-block;
        vertical-align: top;

        > .menu {
            width: 100%;
            max-width: 280px;



            @include tablet {
                width: 100%;
                max-width: 459px;
            }

            @include navigation-min {
                max-width: none;
                width: auto;
            }

            &[data-level] {
                @include navigation-min {
                    display: none;
                }
            }

            &.prime-nav {
                @include navigation-min {
                    display: block;
                }
            }

            > ul, .nav-header {
                padding-right: 30px;
                padding-left: 30px;

                @include tablet {
                    padding-left: 44px;
                }

                @include navigation-min {
                    padding-left: 0;
                }
            }

            > ul {
                @include tablet {
                    padding-right: 45px;
                }
            }

            .nav-header {
                @include tablet {
                    padding-right: 30px;
                    padding-left: 24px;
                }
            }
        }

        .nav-header {
            display: none;
            position: relative;

            &:after {
                content: attr(data-title);
                position: absolute;
                left: 68px;
                top: 23px;
                bottom: 0;
                height: 30px;
                line-height: 19px;
                font-size: 14px;
                color: $color_14;
                width: auto;
            }
        }

        .mobile-mask {
            display: none;
        }

        @include navigation-max {
            position: absolute;
            right: 0;
            top: 0;
            text-align: left;
            z-index: 101;
            left: 0;


            & > .menu {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 103;
                height: 100vh;
                background: $color_1;

                @include myTransition(all, 0.3s);
                @include myTranslate(100%, 0);

                .animate-on-open {
                    will-change: transform;
                    @include myTransition(all, 0.5s);
                    transform: scale(0);

                    &:before {
                        will-change: transform;
                        @include myTransition(all, 0.5s);
                        transform: rotate(0);
                        -webkit-transform: rotate(0);
                    }
                }

                > ul {
                    height: calc(100% - 45px);
                    display: block;
                    overflow: auto;
                }
            }


            .nav-header {
                display: block;
                padding-top: 17px;
                padding-bottom: 15px;
                line-height: 30px;

                button, .link, .btn {
                    font-size: 16px;
                    line-height: 16px;
                    padding: 7px;
                }

                .icon-Close {
                    color: $color_14;
                    position: relative;
                    width: 30px;
                    height: 30px;
                    line-height: 16px;
                    padding: 7px;
                    margin-right: -12px;
                    text-align: center;
                    font-weight: bold;

                    &:before {
                        position: fixed;
                        top: 1px;
                        right: 16px;
                        bottom: 0;
                        margin: auto;
                        width: 16px;
                        height: 16px;
                        font-size: 25px;
                    }
                }

                .icon-ArrowBack {
                    color: $color_14;
                    position: relative;
                    width: 30px;
                    height: 30px;
                    line-height: 22px;
                    padding: 4px;
                    margin-left: -4px;
                    text-align: left;
                    font-weight: bold;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        width: 22px;
                        height: 22px;
                        font-size: 23px;
                    }
                }
            }



            li {
                &:first-child {
                    border-top: none;
                }
            }

            .mobile-mask {
                display: block;
                position: absolute;
                z-index: 102;
                right: 0;
                top: 0;
                height: 100vh;
                width: 100%;
                background: rgba(0, 0, 0, 0.6);
                opacity: 0;
                // @include myTransition(all, 0.3s);


                transition: all .3s;
                -webkit-transition: all .3s; /* Safari */

                transform: translate(100%, 0) scale(0);
                -webkit-transform: translate(100%, 0) scale(0);
            }

            &.open {
                > .menu {
                    &.active {
                        @include myTranslate(0, 0);

                        .animate-on-open {
                            transform: scale(1);

                            &:before {
                                transform: rotate(360deg);
                                -webkit-transform: rotate(360deg);
                            }
                        }
                    }
                }

                .mobile-mask {
                    opacity: 1;
                    transform: translate(0, 0) scale(1);
                    -webkit-transform: translate(0, 0) scale(1);
                }
            }
        }

        .only-mobile {
            .toggle-mobile-menu {
                position: relative;

                &:before {
                    font-family: "icomoon";
                    content: "\e90f";
                    font-size: 10px;
                    width: 10px;
                    height: 10px;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                    position: absolute;
                    line-height: 10px;
                    margin-right: 17.3px;
                }
            }
        }
    }

    .resident-menu-items {
        .nav-active {
            background-color: #3E6070;
        }
    }

    .resident-nav-right {
        display: flex;

        .user-actions-list {
            display: flex;

            .profile-label-desktop {
                margin-top: -2px;
            }
        }

        .services-link a.nav-active, .services-link a.nav-item {
            padding-top: 6px;
            line-height: 19px;
            width: 96px;
        }


        .services-link a {
            border: 2px solid #00A9D6;
        }
    }

    .service-desk {
        color: $color_33;

        &:hover, &:active {
            color: $color_1 !important;
        }

        &:before {
            vertical-align: middle;
            font-family: 'icomoon';
            content: "\e94f";
            font-size: 24px;
            display: inline-block;
            height: 24px;
            padding: 0 10px 0 10px;
        }
    }

    .clients-portal {

        &.nav-active {
            background-color: $color_12;

            &:after {
                color: $color_1;
            }
        }

        @include navigation-min {
            color: $color_33;

            &:hover {
                color: $color_1;
            }

            &::after {
                vertical-align: top;
                font-family: 'icomoon';
                content: "\e950";
                font-size: 24px;
                display: inline-block;
                height: 24px;
            }
        }
    }

    .property-manager-menu-item {
        margin-top: -4px !important;

        &.left-navigation {
            margin-top: 0px !important;
            height: 100%;
        }
    }    
}

ul {
    &.left-navigation {
        display: flex;
        flex-direction: column;
        gap: $left-navigation-gap;
        padding-top: $left-navigation-top-padding;
    }
}

.left-navigation-divider {
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-color: #0C4A5F;
}


// LEFT NAVIGATION ICONS    BEGIN
.left-navigation-item {
    // 24px refers to icomoon's height/width
    /*padding-left: calc(($left-navigation-area-width - $left-navigation-margin-left - 24px) / 2);
    padding-right: calc(($left-navigation-area-width - $left-navigation-margin-left - 24px) / 2);
    padding-top: calc(($left-navigation-area-height - $left-navigation-margin-left - 24px) / 2);
    padding-bottom: calc(($left-navigation-area-height - $left-navigation-margin-left - 24px) / 2);*/

    margin-left: -$left-navigation-margin-left;

    &.dropdown-item {
        margin-left: calc($left-navigation-margin-left / 2);
    }

    &.show-apartments::before {
        content: "\e95f";
        vertical-align: top;
        font-family: 'icomoon';
        font-size: 24px;
        display: inline-block;
        height: 23px;
        width: 24px;
        color: white;
        position: relative;
    }

    &.show-contracts::before {
        content: "\e960";
        vertical-align: top;
        font-family: 'icomoon';
        font-size: 24px;
        display: inline-block;
        height: 23px;
        width: 24px;
        color: white;
        position: relative;
    }

    &.show-history {
        &::before {
            content: "\e957";
            vertical-align: top;
            font-family: 'icomoon';
            font-size: 24px;
            display: inline-block;
            color: white;
            height: 25px;
            width: 24px;
        }

        &::after {
            font-family: 'icomoon';
            content: "\e909";
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            top: 0;
            bottom: 0;
            margin: auto;
            color: white;
            position: relative;
            left: 5px;
            display: inline-block;
            transform: matrix(0, -1, 1, 0, 0, 0);
        }
    }

    &.undistributedPayments-header-count {
        background: #3FC8AC;
        position: absolute;
        top: -2px;
        left: 47px;
        color: $color_14;
        border-radius: 4px;
        font-size: 10px;
        padding: 3px 5px 3px 5px;
        line-height: 10px;

        @include max-tablet {
            top: -5px;
        }
    }
    &.undistributedPayments-header-count-error {
        background: #f04f4f;
        color: $color_1;
        font-size: 12px;
    }

    &.show-undistributed {

        &::after {
            content: "\e95e";
            vertical-align: top;
            font-family: 'icomoon';
            font-size: 24px;
            display: inline-block;
            color: white;
            height: 24px;
            width: 24px;
        }
    }

    &.show-reports {
        &::before {
            content: "\e955";
            vertical-align: top;
            font-family: 'icomoon';
            font-size: 24px;
            display: inline-block;
            color: white;
            height: 24px;
            width: 24px;
        }

        &::after {
            font-family: 'icomoon';
            content: "\e909";
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            top: 0;
            bottom: 0;
            margin: auto;
            color: white;
            position: relative;
            left: 5px;
            display: inline-block;
            transform: matrix(0, -1, 1, 0, 0, 0);
        }
    }

    &.show-clients-portal {
        &::before {
            content: "\e956";
            vertical-align: top;
            font-family: 'icomoon';
            font-size: 24px;
            display: inline-block;
            color: white;
            height: 24px;
            width: 24px;
        }

        &::after {
            font-family: 'icomoon';
            content: "\e909";
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            top: 0;
            bottom: 0;
            margin: auto;
            color: white;
            position: relative;
            left: 5px;
            display: inline-block;
            transform: matrix(0, -1, 1, 0, 0, 0);
        }
    }

    &.show-jira {
        &::after {
            content: "\e954";
            vertical-align: top;
            font-family: 'icomoon';
            font-size: 24px;
            display: inline-block;
            color: white;
            height: 24px;
            width: 24px;
        }
    }

    &.show-language {
        &::before {
            content: "\e953";
            vertical-align: top;
            font-family: 'icomoon';
            font-size: 24px;
            display: inline-block;
            color: white;
            height: 25px;
            width: 24px;
        }

        &::after {
            font-family: 'icomoon';
            content: "\e909";
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            top: 0;
            bottom: 0;
            margin: auto;
            color: white;
            position: relative;
            left: 5px;
            display: inline-block;
            transform: matrix(0, -1, 1, 0, 0, 0);
        }
    }

    &.show-logo-dropdown {
        &::after {
            font-family: 'icomoon';
            content: "\e909";
            font-size: 10px;
            line-height: 18px;
            text-align: center;
            top: 0;
            bottom: 0;
            margin: auto;
            color: white;
            position: relative;
            left: 5px;
            display: inline-block;
            transform: matrix(0, -1, 1, 0, 0, 0);
        }
    }

    .fmc-logo {
        width: 22px;
        height: 22px;
        vertical-align: middle;
    }
}

.language-menu-position {
    position: fixed;
    bottom: 74px;
}

.logo-menu-position {
    position: fixed;
    bottom: 16px;
}

// LEFT NAVIGATION ICONS    END


.sub-nav {
    line-height: 22px;
    padding: 14px 0;
    text-align: center;
    border-bottom: 1px solid $color_14;
    margin-bottom: 6px;
    margin-top: -12px;

    > nav {
        @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: center, wrap: wrap )));
    }

    a {
        font-size: 13px;
        padding: 0 10px;
        /*display: block;*/
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: $color_33;

        @include mobile-landscape {
            margin: 0 10px;
        }



        @include tablet {
            padding: 0 20px;
            margin: 0 29px;
        }

        &.active, &:hover {
            color: $color_12;
        }
    }
}

.brand{
    display: inline-block;
    vertical-align: top;
    max-width: 50%;
    

    @include tablet-landscape{
        max-width: none;
    }

    a {
        display: inline-block;
        vertical-align: top;
        height: 32px;
        padding: 0;
        @include tablet{
            height: 50px;
        }
        @include tablet-landscape{
            min-width: 200px;
            max-width: 400px;
        }
    }

    img{
        display: inline-block;
        vertical-align: top;
        width: auto;
        height: 100%;
    }
}

.project-link{
    display: inline-block;
    vertical-align: top;
    max-width: 50%;
    

    @include tablet-landscape{
        max-width: none;
    }

    img{
        display: inline-block;
        vertical-align: top;
        width: auto;
        height: 100%;
    }

    .project {
        vertical-align: top;
        min-width: 1px;
        display: table;
        table-layout: fixed;
        height: 25px;
        @include tablet{
            height: 50px;
        }
        @include tablet-landscape{
            min-width: 200px;
            max-width: 400px;
        }
    }

    .project-img{
        /*position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0;
        font-size: 0;*/
        background-color: $color_1;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display: table-cell;
        width: 32px;
        height: 32px;
        line-height: 32px;

        @include tablet {
            width: 50px;
            height: 50px;
            line-height: 50px;
        }
    }

     .project-title{
        color: $color_1;
        font-size: 12px;
        line-height: 16px;
        padding:0 9px;
        border: none;
        background-color: transparent;
        border-radius: 0;
        text-transform: none;
        display: table-cell;
        vertical-align: middle;

        &:before{
            color: $color_1;
                
                
        }

        @include tablet{
            padding: 0 20px;
            font-size: 16px;
            line-height: 18px;
        }
    }
}

.notification-message {
    display: flex;
    align-self: center;

    &-text {
        font-size: 14px;
        color: #FFFFFF;
    }
}

.project-change {
    display: inline-block;
    vertical-align: top;
    max-width: 50%;
    position: relative;
    padding-left: 32px;

    &.left-navigation {
        height: 100%;

        &:hover {
            background-color: #BBCDD1;

            .project.left-navigation {
                background-color: #3E6070;
            }
        }
    }

    @include tablet {
        padding-left: 50px;
    }

    @include tablet-landscape {
        max-width: none;
    }

    img {
        display: inline-block;
        vertical-align: top;
        width: auto;
        height: 100%;
    }

    .project {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding: 0;
        font-size: 0;
        height: 25px;

        @include tablet {
            /*float: left;*/
            height: auto;
        }
    }

    .project-img {
        display: inline-block;
        vertical-align: top;
        background-color: $color_1;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        line-height: 25px;

        @include tablet {
            width: 50px;
            height: 50px;
            line-height: 50px;
        }
    }

    .custom-dropdown {
        vertical-align: top;
        min-width: 1px;
        display: table;
        height: 32px;

        @include tablet {
            height: 50px;
        }

        @include tablet-landscape {
            /*min-width: 200px;*/
            max-width: 400px;
        }

        .dropdown-label {
            color: $color_33;
            font-size: 11px;
            line-height: 12px;
            padding: 0 36px 0 0;
            border: none;
            background-color: transparent;
            border-radius: 0;
            text-transform: none;
            display: table-cell;
            vertical-align: middle;

            &:before {
                color: $color_33;
            }

            &.left-navigation:before {
                color: $color_3;
            }

            &:hover::before {
                color: $color_1;
            }

            &.left-navigation:hover::before {
                color: $color_3;
            }

            &.left-navigation {
                color: $color_3;
            }

            @include tablet {
                padding: 0 48px 0 20px;
                font-size: 14px;
                line-height: 19px;
            }
        }

        .dropdown-label:hover {
            color: $color_1;

            &.left-navigation:hover {
                background-color: #BBCDD1;
                color: $color_3;
            }
        }

        li {

            a {
                text-align: left;
                text-transform: none;
            }
        }

        .dropdown-content {
            top: calc(100% + 14px);
            min-width: 300px;
        }
    }
}

.preview-as-user {
  padding-left: 5px;
  float:left;
}

.user-actions.pm-actions {
    li.only-mobile {
        display: flex;

        @include navigation-min {
            display: none;
        }

        .settings-mobile-menu::before {
            font-family: 'icomoon';
            content: "\e91d";
            line-height: 24px;
            color: $color_33;
            font-weight: bold;
            font-size: 30px;
        }

        .settings-mobile-menu {
            /*padding: 4px 0;*/
            @include tablet {
                /*padding: 13px 0px;*/
            }
        }
    }

    li.only-desktop {
        .settings-icon {
            /*padding: 13px 0px 13px 0px;*/
        }

        .settings-icon::after {
            font-family: 'icomoon';
            content: "\e91d";
            line-height: 24px;
            color: $color_33;
            font-weight: bold;
            font-size: 30px;
        }

        .settings-icon:hover:after {
            color: #0d8cb0;
        }

        .header-dropdown {
            @include navigation-min {
                display: flex;
            }
        }
    }   
}

@media (max-width: 1700px){
    .main-nav {
        .mobile-nav {
            .first-menu-item {
                margin-left: 0px !important;
            }

            .only-desktop {
                margin-left: 16px !important;
            }

            .first-item-app {
                margin-left: 35px !important;
                margin-right: -2px;
            }
        }
    }
}

.header-pm {
    padding: 0 !important;
    height: 60px !important;
    padding-left: 10px !important;

    &.left-navigation {
        padding-left: 0px !important;
    }

    .main-nav {
        height: 60px;
        padding-top: 5px;
        text-align: center;

        &.left-navigation {
            padding-top: 0px;
        }
    }

    .project {
        display: flex;
        align-items: center;

        &.left-navigation {
            background-color: #0C4A5F;
            padding-left: 20px !important;
            padding-right: 20px !important;
            height: 100%;

            &:hover {
                background-color: #3E6070;
            }
        }
    }

    .project-img {
        width: 40px;
        height: 40px;
        line-height: 40px;

        &.left-navigation {
            border-radius: 30px;
            border-width: 2px;
            border-color: white;
            border-style: solid;
        }
    }

    .pm-dropdown {
        margin-top: -3px;
    }

    .project-change {

        @include max-tablet {
            margin-top: 9px;

            &.left-navigation {
                margin-top: 0px;
            }
        }

        .custom-dropdown {
            margin-left: -18px !important;

            .dropdown-label {
                padding: 0 33px 0 20px !important;

                &.left-navigation {
                    padding: 0 33px 0 16px !important;
                }
            }

            &.left-navigation {
                height: 100%;
                margin-left: 30px !important;
            }

            @include max-tablet {
                margin-left: 0px !important;

                &.left-navigation {
                    margin-left: 50px !important;
                }
            }
        }
    }


    @media (max-width: 1700px) {
        .main-nav {
            .mobile-nav {
                .first-menu-item {
                    margin-left: 0px !important;
                }

                .only-desktop {
                    margin-left: 40px !important;
                }

                .first-item-app {
                    margin-left: 35px !important;
                    margin-right: -2px;
                }
            }
        }
    }

    .mobile-nav {
        margin-left: 88px;

        @media (max-width: 1400px) {
            margin-left: -7px;
        }

        .only-desktop {
            margin-left: 37px !important;
        }

        .only-desktop.left-navigation {
            height: $left-navigation-area-height;
            width: $left-navigation-area-width;
            margin-left: 0px !important; //this should be calculated
        }

        &.left-navigation {
            margin-left: 0px;
        }
    }

    .first-item-app {
        margin-right: -1px !important;
    }

    .publish-item, .reports-item {
        @media (min-width: 1400px) {
            margin-right: 4px !important;
        }
    }

    .profile-dropdown {
        cursor: pointer;
        padding: 0 !important;

        .label-wrapper {
            padding: 0 !important;
        }

        &-link {
            height: 58px !important;
            vertical-align: middle;
            padding: 13px 37px 10px 20px !important;

            &::before {
                right: 20px;
            }
        }
    }

    .clients-dropdown {
        margin-top: -3px;
    }

    .hide-element {
        visibility: hidden;
    }
}

.left-navigation-hover-zone {
    border-radius: 8px 0px 0px 8px;
    height: $left-navigation-area-height;
    width: calc($left-navigation-area-width - $left-navigation-margin-left);
    margin-left: $left-navigation-margin-left;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #3E6070 0% 0% no-repeat padding-box;
        cursor: pointer;
    }

    &.nav-active {
        background-color: $color_12;
    }

    &.clients-portal {
        top: 3px;
    }

    &.finance-wizard {
        top: 3px;
    }
}