@use 'sass:math';
@import "variables.scss";
@import "mixins.scss";

/** test */

body {
    font-family: $font_1;
    font-weight: 400;
    line-height: normal;
    font-size: $text_font;
}

h1, h2, h3, h4, h5, p {
    margin: 0;
    font-weight: 400;
}

a {
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

input,
input[type=button]
input[type=submit] {
    outline: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}


.page-header {
    background-color: $color_14;
    color: $color_33;
    padding: 14px 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $page-header-mobile-height;
    z-index: 100;

    &.shrink {
    }

    @include tablet {
        height: $page-header-tablet-height;
        padding: 14px 30px;
    }

    @include tablet-landscape {
        padding: 12px 24px;
        height: $page-header-height;
    }
}

.left-navigation-header {
    display: inline;
    float: left;
    height: 100%;

    @include navigation-max {
        display: none;
    }
}

.occupant-navigation-header {
    display: inline;
}

.left-navigation-left-panel {
    background: #0C2733 0% 0% no-repeat padding-box;
    //display: inline-block;
    width: 80px;
    height: 100vh;
}

.notification-message-header {
    display: none;
    justify-content: center;
    width: 270px;
    height: 40px;
    padding: 10px 0px;
    margin: 10px 0px;
    text-align: center;
    opacity: 0;
    animation: fadeInOut 4s;

    &.visible {
        display: inline-flex;
    }

    &.success {
        background-color: #3FC8C3;
    }
}
.normal-navigation-header {
    display: none;
    float: left;

    .notification-message-header {
        margin-top: 5px;
        margin-right: 15%;
    }

    @include navigation-max {
        display: inline-flex;
    }
}

.page-header-left-navigation {
    background: $color-left-navigation-header 0% 0% no-repeat padding-box;
    padding: 14px 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $page-header-mobile-height;
    z-index: 100;

    &.shrink {
    }

    @include tablet {
        height: $page-header-tablet-height;
        padding: 14px 30px;
    }

    @include tablet-landscape {
        padding: 12px 24px;
        height: $page-header-height;
    }

    @include navigation-max {
        background-color: $color_14;
    }
}

.sub-header {
    @include clearfix;
    padding: 12px 0;
}

.page-footer {
    background-color: $color_14;
    color: $color_10;

    .footer-wrap {
        min-height: 78px;
        padding: 29px 36px;
        @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: space-between, wrap: wrap )));

        @include tablet-landscape {
            @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: wrap )));
        }

        @include max-tablet-landscape {
            padding: 18px 24px 21px 40px;
        }

        @include max-mobile-landscape {
            padding: 16px 20px;
            align-items: flex-start;
            padding-bottom: 13px;
        }

        .flex {
            @include flex();
        }
    }

    a {
        display: inline-block;
        vertical-align: top;
    }

    .copyright-container {
        font-size: 12px;
        text-align: right;
        margin-top: 0px;

        p {
            color: $color_16;
            font-size: 12px;

            @include max-mobile-landscape {
                font-size: 10px;
            }
        }

        @include max-mobile-landscape {
            text-align: center;
            width: 100%;
        }
    }

    .center-copyright {
        text-align: center;
        width: 100%;
    }

    .footer-brand {
        /*@include max-tablet-landscape{
            // float:left;
            width: 40%;
        }*/
        a {
            height: 37px;
            line-height: 35px;
            padding: 0;
            margin: 0;
            margin-left: 4px;
            min-width: 1px;
            max-width: none;

            @include max-tablet-landscape {
                height: 37px;
                line-height: 37px;
                margin-top: 1px;
                margin-left: 0;
            }

            @include max-mobile-landscape {
                height: 28px;
                line-height: 28px;
            }

            > img {
                display: inline-block;
                vertical-align: top;
                height: 100%;
                width: auto;
            }
        }
    }


    .language {
        text-align: right;
        /*@include max-tablet-landscape{
            // float:right;
            width: 59%;
            text-align: right;
        }*/
        @include max-mobile-landscape {
            width: 120px;
        }

        > a {
            padding: 6px 12px;
            margin: 0 24px;
            color: $color_1;
            font-size: 13px;
            display: none;

            @include tablet-landscape {
                display: inline-block;
            }

            @include max-tablet-landscape {
                font-size: 12px;
                margin: 0px;
            }

            &.modal-lang-change {
                display: inline-block;
                font-size: 13px;
                position: relative;
                padding-right: 16px;

                &:after {
                    content: "...";
                    color: $color_1;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    line-height: 10px;
                    top: 1px;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    font-size: 18px;
                }

                @include tablet-landscape {
                    display: none;
                }
            }

            &:hover, &.active {
                color: $color_26;
            }

            &:first-child {
                margin-left: 48px;

                @include max-tablet-landscape {
                    margin-left: 4px;
                    margin-top: 4px;
                }

                @include max-mobile-landscape {
                    margin-left: 0px;
                    padding-top: 7px;
                    margin-top: 0px;
                }
            }

            &:nth-child(2) {
                margin-left: 54px;
            }
        }
    }

    .footer-nav {
        @include tablet {
            margin: 0;
        }

        a {
            font-size: 13px;
            color: $color_10;
            margin: 10px 12px;
            margin-top: 3px;

            @include max-tablet-landscape {
                margin-top: 2px;
            }

            @include max-mobile-landscape {
                margin-top: 9px;
                margin-bottom: 6px;
            }

            &:last-child {
                @include mobile-landscape {
                    margin-right: 0;
                }
            }

            &:hover, &.active {
                color: $color_26;
            }
        }
    }

    &.left-navigation {
        display: none;

        @include navigation-max {
            display: inline;
        }
    }
}

.custom-scroll {
    position: relative;
}

.content-section {

    .content-header {
        border-bottom: 2px solid $color_14;
        padding: 15px 10px;
        line-height: 24px;

        @include tablet {
            padding: 23px 30px;
        }

        @include tablet-landscape {
            padding: 15px 0;
            line-height: 23px;
        }

        h2 {
            font-size: 15px;
            color: $color_14;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: top;

            @include tablet {
                font-size: 22px;
            }

            @include tablet-landscape {
                font-size: 20px;
            }
        }

        .link {
            float: right;
            font-size: 15px;

            @include tablet {
                font-size: 22px;
                line-height: 24px;
            }

            @include tablet-landscape {
                line-height: 23px;
                font-size: 15px;
            }
        }
        /*.custom-dropdown{
            vertical-align: top;
            min-width: 1px;
            .dropdown-label{
                border: none;
                font-size: 15px;
                line-height: 24px;
                padding: 0 38px 0 5px;
                color: $color_12;

                @include tablet{
                    font-size: 22px;
                }

                @include tablet-landscape{
                    line-height: 23px;
                    font-size: 20px;
                }

                &:before{
                    font-size: 18px;
                }
            }

            .dropdown-content{
                top: calc(100% + 18px);
                left: auto;
                right: 0;
            }

            li{
                a{
                    padding: 10px;
                }
            }

            &.open{
                .dropdown-label{
                    background-color: $color_1;
                }
            }
        }*/
    }

    .content-header-small {
        @include max-tablet {
            padding: 3px 10px;

            h2 {
                font-size: 14px;
            }
        }
    }

    .content-header-holder {
        @include flex-container(map-merge($flex-container-default, ( direction: row, horizontal: flex-start, vertical: center )));

        .flex {
            display: flex;
            max-width: 100%;
            @include flex();

            a {
                margin-left: auto;
            }
        }
    }

    .content-actions {
        margin-bottom: 18px;
    }

    .content-inner-wrap {
        padding-top: 18px;

        @include tablet-landscape {
            padding-top: 33px;
        }
    }
}

.sub-content-section {
    .sub-content-header {
        padding: 15px 0;
        line-height: 24px;

        @include tablet {
            padding: 23px 0;
        }

        @include tablet-landscape {
            padding: 15px 0;
            line-height: 23px;
        }

        h2 {
            font-size: 15px;
            color: $color_14;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: top;

            @include tablet {
                font-size: 22px;
            }

            @include tablet-landscape {
                font-size: 20px;
            }
        }
    }
}



.anonymous-page {
    background-image: url(../images/background.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .content {
        padding-top: 0;
    }

    .page-footer {
        background-color: rgba($color_14, 0.6);
    }

    .opaque-page-footer {
        background-color: #0c2733;
        display: block;
    }

    .anonymous-info {
        p {
            color: #fff;
            margin-bottom: 20px;
            background: rgba(0, 0, 0, 0.18);
            padding: 8px 4px;
        }
    }
}

.anonymous-bills-page {
    background-image: none;

    .footer-wrap {
        max-height: 100px;

        @include max-tablet-landscape {
            padding-top: 17px;
        }

        @include max-mobile-landscape {
            padding-bottom: 20px;
        }
    }
}

.anonymous-page-light {
    background: black;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font: normal normal normal 13px/18px Roboto;
    line-height: initial;

    .content {
        padding-top: 0;
    }

    .page-footer {
        background-color: rgba($color_14, 0.6);
    }

    .opaque-page-footer {
        background-color: #0c2733;
        display: block;
    }

    .anonymous-info {
        p {
            color: #fff;
            margin-bottom: 20px;
            background: rgba(0, 0, 0, 0.18);
            padding: 8px 4px;
        }
    }
}

.language-list {
    /*padding: 6%;*/
    > a {
        display: block;
        color: $color_3;
        font-weight: 400;
        line-height: 22px;
        font-size: 16px;
        border-top: 1px solid $color_16;
        text-align: center;
        background-color: transparent;
        padding: 14px;

        @include myTransition();

        &:first-child {
            border-top: none;
        }

        &:active, &.active, &:hover {
            background-color: $color_11;
        }

        @include tablet {
            line-height: 28px;
            font-size: 18px;
            padding: 18px;
        }
    }
}


.color-red {
    color: $color_28;
}

.color-green {
    color: $color_29;
}

.color-blue {
    color: $color_12;
}

.color-aqua {
}

.color-box {
    padding: 13px 20px;
    line-height: 20px;
    font-size: 18px;
    margin: 10px 0;
    font-weight: normal;

    @include tablet {
        padding: 23px 30px;
        line-height: 30px;
        font-size: 22px;
        margin: 10px 0 14px;
    }

    @include max-tablet {
        margin: 10px 0 0;
    }

    @include tablet-landscape {
    }

    &.blue-box {
        color: #000000;
        font-size: 20px;
    }

    &.red-box {
        background-color: $color_28;
        color: $color_1;
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

dl, dt, dd {
    margin: 0;
}

.mce-path {
    display: none !important;
}

.map-holder {
    position: relative;
    width: 100%;
    padding: 50%;
    margin-bottom: 19px;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;


    @include mobile-landscape {
        padding: calc(50%*0.33) 50%;
        margin-bottom: 32px;
    }

    .map-container {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.gm-style-iw {

    h2 {
        color: #003C4B;
        font-size: 20px;
        font-weight: normal;
    }

    h5 {
        color: #8DA3A9;
        font-size: 10px;
        font-weight: normal;
        margin-bottom: 10px;
    }
}

.infoWindow-1 {
    background-color: #E74C3C !important;
    box-shadow: rgb(231, 76, 60) 0px 1px 4px -1px !important
}

.infoWindow-2 {
    background: red;
}

.infoWindow-3 {
    border-top: 24px solid #E74C3C !important;
}

$grid-space: 15px;

.grid {
    @include tablet-landscape {
        margin-right: -#{$grid-space};
        margin-left: -#{$grid-space};
        @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: wrap, horizontal: flex-start )));
    }

    .grid-item {
        padding-bottom: $grid-space;

        @include tablet {
            width: 50%;

            @include flex(0, 0, auto);
            flex-basis: 50%;
            max-width: 50%;
        }
    }

    @for $i from 1 through 6 {
        &[data-items="#{$i}"] {
            .grid-item {

                @include tablet-landscape {
                    flex-basis: math.div(100%, $i);
                    max-width: math.div(100%, $i);
                }
            }
        }
    }

    &.no-space {

        @include tablet-landscape {
            margin-right: 0;
            margin-left: 0;
        }

        .grid-item {
            padding-bottom: 0;

            @include tablet-landscape {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    $grid-xs-space: 5px;

    &.grid-xs {
        margin-right: -#{$grid-xs-space};
        margin-left: -#{$grid-xs-space};
        @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: wrap, horizontal: flex-start )));

        .grid-item {
            padding-bottom: 0;
            padding-right: $grid-xs-space;
            padding-left: $grid-xs-space;
            @include flex(0, 0, auto);
            flex-basis: 50%;
            max-width: 50%;
        }

        @for $i from 1 through 6 {
            &[data-items="#{$i}"] {
                .grid-item {
                    flex-basis: math.div(100%, $i);
                    max-width: math.div(100%, $i);
                }
            }
        }
    }
}

.grid-cleanMargin {
    @include tablet-landscape {
        margin-right: 0;
        margin-left: 0;
    }
}

.mobile-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 40px;

    .mobile-button {
        display: flex;
        justify-content: space-between;
        width: 49%;
        height: 85px;
        border-radius: 8px;
        padding: 5px 5px 15px 17px;
    }

    .issue-button {
        background-color: #F57E4A;

        &:after {
            vertical-align: top;
            font-family: 'icomoon';
            content: "\e949";
            color: white;
            display: inline-block;
            font-size: 24px;
        }
    }

    .service-button {
        background-color: #9E3FAF;

        &:after {
            vertical-align: top;
            font-family: 'icomoon';
            content: "\e948";
            color: white;
            display: inline-block;
            font-size: 24px;
        }
    }

    .mobile-button-text {
        font-size: 16px;
        line-height: 16px;
        text-align: left;
        color: white;
        margin-top: auto;
        text-transform: uppercase;
    }

    @include tablet-landscape {
        display: none;
    }
}

.ajax-container {
    position: relative;
    min-height: 100px;

    &.ajax-load {
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-image: url(../images/loading.gif);
            background-position: center;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.61);
        }
    }
}

.datepickers-container {
    z-index: 9999;
}

.tooltipster-sidetip {
    .tooltipster-box {
        background: #FFF2D2;
        border: 1px solid $color_34;
        border-radius: 4px;
    }

    .tooltipster-content {
        color: #191919;
        font-size: 13px;
    }

    &.tooltipster-bottom .tooltipster-arrow-border {
        border-bottom-color: transparent;
    }

    &.tooltipster-left .tooltipster-arrow-border {
        border-left-color: transparent;
    }

    &.tooltipster-right .tooltipster-arrow-border {
        border-right-color: transparent;
    }

    &.tooltipster-top .tooltipster-arrow-border {
        border-top-color: transparent;
    }

    & .tooltipster-arrow-background {
        border: 10px solid transparent;
    }

    &.tooltipster-bottom .tooltipster-arrow-background {
        border-bottom-color: transparent;
        left: 0;
        top: 3px;
    }

    &.tooltipster-left .tooltipster-arrow-background {
        border-left-color: transparent;
        left: -3px;
        top: 0;
    }

    &.tooltipster-right .tooltipster-arrow-background {
        border-right-color: transparent;
        left: 3px;
        top: 0;
    }

    &.tooltipster-top .tooltipster-arrow-background {
        border-top-color: transparent;
        left: 0;
        top: -3px;
    }
}


/* STATIC PAGES text style */
.static-pages-content {
    text-align: justify;

    li {
        position: relative;
        padding-left: 20px;

        &:before {
            content: "-";
            width: 20px;
            height: 26px;
            line-height: 26px;
            left: 0;
            top: 0;
            position: absolute;
            text-align: center;
        }
    }
}

.error-pages-content {
    .server-error-image {
        width: 80.69px;
    }

    .page-not-found-image {
        width: 90px;
    }

    p {
        font-size: 26px;
        margin-top: 32px
    }
}

.indented-paragraph {
    text-indent: 20px;
}

.balance-block {
    display: inline-block;
}

.client-number-block {
    @include tablet {
        display: inline-block;
        float: right;
    }
}

.client-number-block-mobile {
    display: inline-block;
    float: left;
    margin-left: 14px;
    margin-top: -19px;
    font-size: 14px;
    padding-bottom: 5px;

    @include tablet {
        display: none;
    }
}

.client-number-block-desktop {
    display: none;

    @include small-desktop {
        display: block;
        font-size: 13px;
        margin-bottom: 25px;

        .number-label {
            font-weight: bold;
        }
    }
}

.bills-ribbon-holder {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: calc(100% - 20px);
    margin-left: 10px;
    background: #DAF0F6;
    padding: 20px;
    border-radius: 8px;
    justify-content: space-between;
    height: 220px;

    .pay-button {
        width: calc(100% - 20px);
        margin-left: 10px;
    }

    a.pay-button {
        @include max-tablet {
            height: 45px;
            padding: 12.5px;
        }
    }

    .ribbon-container {
        width: 100%;
    }

    @include small-desktop {
        height: 100px;
        margin-left: 0;
        width: 100%;
        flex-flow: row nowrap;
        margin-bottom: 10px;

        .cc-content-holder {
            min-width: 260px;
            width: 22%;
        }

        .ribbon-container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        .pay-button {
            min-width: 290px;
            width: 25%;
            margin-left: 0;
        }

        .ribbon-left-side {
            display: flex;
            flex-flow: row nowrap;
        }
    }
}

.no-cost-center-ribbon {
    color: #A2D3DF;
    padding: 9px;
    font-size: 14px;
    background: #296F81;
}

.no-cost-center-label {
    width: 26%;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #0C2733;
    margin: auto;

    @include max-tablet-landscape {
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
    }

    @include max-mobile-landscape {
        text-align: center;
    }
}

.billsCCdropdown {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    height: 40px;

    @include max-tablet {
        width: 100%;
        min-width: 150px;
    }

    .custom-dropdown {
        width: 100%;
        height: 100%;

        .dropdown-content {
            top: calc(100%);
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        & > a {
            background: #DAF0F6;

            & > span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                font-size: 13px;
                background-color: #FFFFFF;
                color: black;
                position: absolute;
                top: 50%;
                margin-left: -5px;
                margin-top: -20px;
                padding: 8px 40px 12px 11px;
                border: 1px solid #909191;
                height: 40px;
                border-radius: 4px;

                @include max-tablet {
                    padding-left: 9px;
                }
            }
        }

        & > a.dropdown-label.dropdown-trigger {
            background-color: #DAF0F6;
            color: #ffffff;
            height: 100%;
        }

        & > a.dropdown-label:before {
            right: 16px;
            font-size: 11px;
            z-index: 1;
            color: #767676;
        }

        &.open > a.dropdown-label:before {
            color: $color_12;
        }

        &.open > a.dropdown-label > span.cc-dropdown-label {
            background-color: $color_9;
            border: 1px solid $color_12;
        }
    }

    @include small-desktop {
        min-width: 260px;
        width: 22%;
    }
}

.balance-label-ribbon {
    display: none;

    @include small-desktop {
        display: flex;
        flex-flow: row nowrap;
        font-size: 13px;
        font-weight: bold;
        min-height: 40px;
        background-color: #DAF0F6;
        border-top: 1px solid #CCCCCC;
        border-bottom: 1px solid #CCCCCC;
        padding: 10px 15px 10px 10px;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 12px;

        .balance-label {
            padding-right: 45px;
        }

        .balance-value {
            font-size: 18px;
            color: #A73B0B;
        }
    }
}

.billsCCribbon {
    padding: 0;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin: 0;

    .current-debt-label {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 4px;
    }

    .client-number-block {
        font-size: 14px;
        width: 120px;
        text-align: right;
    }

    .color-box-content {
        margin-top: 20px;
        line-height: 26px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include max-tablet {
            line-height: 21px;
        }

        .current-balance {
            font-size: 26px;
            color: #A73B0B;
            font-weight: bold;
        }
    }

    .bills-current-date {
        line-height: normal;
        font-size: 12px;
        display: inline-block;
        margin-left: 13px;

        @include max-tablet {
            font-size: 11px;
            margin-left: 0;
        }
    }

    .balance-block {
        font-size: 20px;
        display: flex;
        flex-flow: column wrap;
        flex-shrink: 0;

        @include max-tablet {
            font-size: 12px;

            .current-balance-container {
                display: block;
            }

            .current-balance {
                font-size: 20px;
            }
        }
    }

    .color-box-content {
        margin-bottom: 30px;
    }

    @include max-tablet {
        width: 100%;
        padding: 0;
    }

    @include small-desktop {
        width: 25%;
        min-width: 300px;

        .client-number-block {
            display: none;
        }

        .color-box-content {
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 20px;
        }

        .balance-block {
            flex-flow: row nowrap;

            .current-debt-label {
                font-size: 20px;
            }

            .current-balance {
                font-size: 20px;
            }
        }
    }
}

.color-box-content {
    margin-bottom: 30px;
}
/*.indented-paragraph {
   text-indent: 30px;
}

.static-pages-content ul {
    list-style-type: none;
  }

.static-pages-content li:before {
    content: " - ";
  }*/

.payment-main-holder {
    .custom-form {
        //max-width: 636px;
        padding: 0;

        .commission-label-container {
            .hide-providers {
                display: none;

                .payment-commission {
                    font-size: 13px;
                    margin-bottom: 12px;
                }

                .actual-charge-container {
                    margin-bottom: 30px;
                }
            }

            .actual-charge-container {
                //width: 231px;
                height: 49px;
                margin-bottom: 20px;

                .actualChargeLabel {
                    display: block;
                    font-size: 15px;
                    margin-bottom: 0;
                }

                .actual-charge {
                    display: block;
                    font-size: 20px;
                    margin-bottom: 0;
                }
            }

            label {
                margin-bottom: 8px;
            }
        }
    }

    .form-group,
    .form-group:first-child {
        margin-top: 15px;
        //max-width: 300px;
    }

    .line-separator {
        height: 1px;
        background-color: #0C2733;
    }

    .line-separator-grey {
        height: 1px;
        background-color: #CCCCCC;
        margin-top: 15px;
    }

    .payment-methods-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .flex-fields {
            margin: 35px 43px 35px 0;
        }

        .flex-fields:last-child {
            margin: 35px 0;
        }

        .flex-fields:nth-last-child(2) {
            margin: 35px 41px 35px 0;
        }

        input[type="radio"] {
            opacity: 0;
            position: absolute;
            width: 0;
        }

        label {
            width: 200px;
            height: 130px;
            border: 1px solid #CCCCCC;
            border-radius: 8px;

            &:hover {
                background-color: #E7F9FE;
                border: 1px solid #00A9D6;

                .checkbox-span {
                    border: 1px solid #00A9D6;
                }
            }

            .img-checkbox-container {
                display: flex;
                flex-direction: row;

                .checkbox-span {
                    width: 20px;
                    height: 20px;
                    border: 1px solid #95989A;
                    margin: 10px 0 0 10px;
                }
            }

            .epay-account-img {
                width: 125px;
                height: 35px;
                margin: 30px 36px 21px 7px;
            }

            .card-img {
                display: flex;
                flex-direction: row;
                width: 125px;
                height: 37px;
                margin: 32px 37px 18px 6px;

                .mastercard-img {
                    width: 47.4px;
                    height: 37px;
                    margin-right: 19.5px;
                }

                .visa-logo {
                    width: 58px;
                    height: 37px;
                }
            }

            .paypal-img {
                width: 110px;
                height: 28px;
                margin: 27px 44px 31px 14px;
            }

            .provider-name {
                font-size: 15px;
                color: #767676;
                text-align: center;
                margin: 0 auto 24px auto;
            }
        }

        input[type="radio"]:checked ~ .label {
            background-color: #E7F9FE;
            border: 1px solid #00A9D6;

            .checkbox-span {
                background-color: #00A9D6;
                border: 1px solid #00A9D6;

                &:before {
                    font-family: "icomoon";
                    content: "\e90a";
                    background-color: transparent;
                    font-size: 19px;
                    text-align: center;
                    line-height: 20px;
                    color: #FFFFFF;
                    z-index: 7;
                    -webkit-transition: all .2s ease-in;
                    transition: all .2s ease-in;
                    -webkit-transform: rotate( 360deg ) scale(0);
                    transform: rotate( 360deg ) scale(0);
                }
            }
        }
    }

    .flex-fields {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        //margin-bottom: 35px;
    }

    .main-balance {
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin: 8px 0 2px 0;
    }

    .payment-main-block {
        width: 100%;
        max-width: 100%;

        .flex-fields {
            .total-amount-container {
                margin-bottom: 39px;
            }
            //max-width: 300px;
            .label {
                font-size: 13px;
                color: #000000;
                margin-bottom: 8px;
            }
        }

        .form-control {
            width: 350px;
            height: 40px;
            border: 1px solid #909191;
            border-radius: 4px;
            //margin-bottom: 39px;
        }

        .actualChargeLabel,
        #ActualChargeLabel {
            color: #191919;
            margin-bottom: 8px;
            margin-top: 13px;
            display: inline-block;
        }
    }

    .payment-parameters-holder {
        //.form-group {
        width: 100%;
        display: none;
        //flex-direction: column;
        @include max-mobile-landscape {
            width: 100%;
            max-width: 550px;
        }

        .payment-commission {
            font-size: 13px;
            width: 100%;
            margin-bottom: 12px;
        }
        //}
        .payment-billing-header {
            width: 100%;
            color: #191919;
            font-size: 16px;
            line-height: 21px;
            margin-top: 20px;
        }

        .flex-fields .label {
            font-size: 13px;
        }
    }

    .textbox-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .flex-fields {
            margin: 0 15px 20px 0;
            width: 350px;
            position: relative;

            input {
                width: 350px;
                height: 40px;
            }
        }
    }

    .form-actions {
        margin: 25px 0;
        padding: 0;

        .btn-blue {
            margin-left: 15px;
        }
    }
}

.success-payment-main-holder {
    font-size: 18px;
    line-height: 24px;

    .regarding-container {
        margin: 23px 0 35px 0;
        display: flex;
        flex-direction: row;

        .regarding-label {
            width: 90px;
            margin-right: 148px;
        }
    }

    .paid-amount-container {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        margin-bottom: 20px;

        .paid-amount-label {
            width: 139px;
            margin-right: 99px;
        }
    }

    .payment-provider-error {
        text-align: left;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 20px;
    }

    .payment-not-confirmed {
        border-top: 1px solid #CCCCCC;
        border-bottom: 1px solid #CCCCCC;
        text-align: center;
        padding: 20px 0;

        .important-label {
            font-weight: bold;
        }
    }

    .ok-button {
        float: right;
        margin-top: 26px;
    }
}

.failed-payment-main-holder {
    font-size: 18px;
    line-height: 24px;

    .regarding-container {
        margin: 23px 0 35px 0;
        display: flex;
        flex-direction: row;

        .regarding-label {
            width: 90px;
            margin-right: 161px;
        }
    }

    .total-amount-container {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        border-bottom: 1px solid #CCCCCC;
        padding-bottom: 35px;

        .total-amount-label {
            width: 152px;
            margin-right: 99px;
        }
    }

    .close-button {
        float: right;
        margin-top: 26px;
    }
}

@media screen and (max-width: 600px) {
    .content-section {
        .content-header {
            margin: 0 10px;
            //width: 320px;
            //margin: 0 auto;
            padding: 10px 0;
            //font-size: 15px;
        }
    }

    .payment-main-holder {
        //margin: 0 20px;
        margin: 0 auto;

        .form-actions {
            .btn {
                padding: 16px;
            }

            .btn-blue {
                margin-left: 0;
            }
        }

        .payment-main-block {
            .flex-fields {
                .total-amount-container {
                    margin-bottom: 0;
                }
            }
        }

        .custom-form {
            width: 320px;
            margin: 0 auto;

            .form-group {
                margin-top: 0;

                .main-balance {
                    margin: 3px 0 18px 0;
                }
            }

            .form-control {
                width: 320px;
                height: 40px;
                margin: 0;
            }

            .payment-methods-label {
                margin: 20px 0 13px 0;
                align-self: flex-start;
            }

            .payment-methods-container {
                margin-top: 25px;
                margin-bottom: 10px;

                .flex-fields {
                    margin-bottom: 15px;
                }

                .flex-fields:last-child {
                    margin: 0;
                    margin-bottom: 15px;
                }

                .flex-fields:nth-last-child(2) {
                    margin: 0;
                    margin-bottom: 15px;
                }

                .label {
                    width: 320px;
                    height: 60px;
                    display: flex;
                    flex-direction: row;

                    .checkbox-span {
                        margin: 20px 13px 20px 12px;
                    }

                    .epay-account-img {
                        width: 113px;
                        height: 32px;
                        margin: 14px auto 14px 0;
                    }

                    .card-img {
                        width: 111px;
                        height: 33px;
                        margin: 14px auto 13px 0;

                        .mastercard-img {
                            width: 42.04px;
                            height: 33px;
                            margin-right: 17.31px;
                        }

                        .visa-logo {
                            width: 51.5px;
                            height: 33px;
                        }
                    }

                    .paypal-img {
                        margin: 16px auto 16px 0;
                    }

                    .provider-name {
                        margin: auto 16px auto auto;
                    }
                }
            }
        }

        .payment-parameters-holder {
            .payment-commission {
                margin-bottom: 8px;
            }

            .actual-charge-container {
                margin-bottom: 30px;
            }
        }

        .textbox-container {
            .flex-fields {
                margin: 0 0 20px 0;

                input {
                    width: 320px;
                    height: 40px;
                }
            }
        }

        .line-separator-grey {
            margin-top: 10px;
        }

        .form-actions {
            margin: 25px 0;

            .btn {
                width: 320px;
                height: 50px;
            }
        }
    }

    .content-success-payments, .content-failed-payments {
        & #contentHeader {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .success-payment-main-holder {
        margin: 0 20px;

        .regarding-container {
            margin-top: 8px;
            margin-bottom: 0px;

            .regarding-label {
                margin-right: 120px;
            }
        }

        .paid-amount-container {
            margin-top: 35px;
            padding-bottom: 35px;

            .paid-amount-label {
                width: 139px;
                margin-right: 87px;
            }
        }

        .payment-not-confirmed {
            text-align: left;
            padding: 30px 0;
        }

        .ok-button {
            width: 100%;
            height: 50px;
            padding-top: 17px;
            margin: 25px auto 0 auto;
        }
    }

    .failed-payment-main-holder {
        margin: 0 20px;

        .regarding-container {
            margin: 8px 0 35px 0;

            .regarding-label {
                margin-right: 120px;
            }
        }

        .total-amount-container {
            padding-bottom: 30px;

            .total-amount-label {
                width: 151px;
                margin-right: 53px;
            }
        }

        .close-button {
            width: 100%;
            height: 50px;
            padding-top: 17px;
            margin: 25px auto 0 auto;
        }
    }
}

@media screen and (max-width: 1000px) and (min-width: 601px) {
    .content-wrap {
        margin: 0 20px;

        .content-section {
            .content-header {
                padding-left: 0;
            }
        }

        .payment-main-holder {
            .payment-methods-container {
                .flex-fields {
                    margin: 20px;
                }
            }

            .textbox-container {
                justify-content: space-between;

                .flex-fields {
                    width: 320px;
                    margin: 0 auto 20px auto;

                    input {
                        width: 320px;
                        //margin-right: 0;
                    }
                }
            }
        }
    }

    .mobile-buttons-container {
        max-width: 550px;

        .mobile-button {
            width: 100%;
            max-width: 270px;
        }
    }
}

.content-success-payments .content-wrap .content-section .content-header h2 {
    color: #46CE78;
    font-size: 20px;
    line-height: 26px;

    &::before {
        font-family: 'icomoon';
        content: "\e922";
        line-height: 11px;
        color: #46CE78;
        font-weight: 500;
        font-size: 30px;
        margin-right: 13px;
        vertical-align: middle;
    }
}

.content-failed-payments .content-wrap .content-section .content-header h2 {
    color: #FF0000;
    font-size: 20px;
    line-height: 26px;

    &::before {
        font-family: 'icomoon';
        content: "\e921";
        line-height: 11px;
        color: #FF0000;
        font-weight: 500;
        font-size: 25px;
        margin-right: 13px;
        vertical-align: middle;
    }
}

@include max-tablet-landscape {

    .content-section {
        .content-header {
            margin: 0 10px;
            padding: 10px 0;
        }
    }
}

.pay-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    flex-direction: row;

    @include max-tablet-landscape {
        display: block;
    }

    .bank-info-link-modal {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        @include max-tablet-landscape {
            text-align: left;
            align-self: flex-start;
        }
    }

    .modal-label {
        color: #0FB4E1;

        &::before {
            font-family: "icomoon";
            content: "\e913";
            position: absolute;
            font-size: 18px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            left: 0px;
            top: 0;
            margin: auto;
        }
    }
}

#embed-container {
    height: 99vh; //this is the highest value that doesn't show vertical scrow bar
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

a.forgot-password-link {
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: underline;
}

#password-meter-container {
    width: 212px;
    height: 230px;
    display: none;
    position: fixed;
    z-index: 10000;
    border-radius: 8px;
    border: #00A9D6 1px solid;
    background-color: #FFFFFF;
    padding: 15px;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-50%);
        border: 10px solid transparent;
        border-right-color: #00A9D6;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: -17px;
        transform: translateY(-50%);
        border: 9px solid transparent;
        border-right-color: #FFFFFF;
    }

    @include max-tablet-landscape {
        &::before {
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            border: 10px solid transparent;
            border-bottom-color: #00A9D6;
        }

        &::after {
            top: -17px;
            left: 50%;
            transform: translateX(-50%);
            border: 9px solid transparent;
            border-bottom-color: #FFFFFF;
        }
    }

    #password-strength-meter {
        display: flex;
        margin-top: 8px;
        gap: 4px;
    }

    #password-strength-text {
        font-size: 13px;
        color: #000000;
        text-align: left;
        font-weight: bold;
    }

    .strength-bar {
        height: 4px;
        flex: 1;
        border-radius: 2px;
        background-color: #CCCCCC;
        transition: background-color 0.3s ease;
        margin-bottom: 5px;
    }

    .strength-0 .strength-bar:nth-child(1) {
        background-color: #F04F4F;
    }

    .strength-1 .strength-bar:nth-child(1),
    .strength-1 .strength-bar:nth-child(2) {
        background-color: #F57E4A;
    }

    .strength-2 .strength-bar:nth-child(1),
    .strength-2 .strength-bar:nth-child(2),
    .strength-2 .strength-bar:nth-child(3) {
        background-color: #FFDD00;
    }

    .strength-3 .strength-bar:nth-child(1),
    .strength-3 .strength-bar:nth-child(2),
    .strength-3 .strength-bar:nth-child(3),
    .strength-3 .strength-bar:nth-child(4) {
        background-color: #00A9D6;
    }

    .strength-4 .strength-bar:nth-child(1),
    .strength-4 .strength-bar:nth-child(2),
    .strength-4 .strength-bar:nth-child(3),
    .strength-4 .strength-bar:nth-child(4),
    .strength-4 .strength-bar:nth-child(5) {
        background-color: #3FC8AC;
    }

    #password-requirements-text {
        font-weight: bold;
        font-size: 13px;
        white-space: nowrap;
        margin-top: 8px;
        text-align: left;
    }

    .password-requirement {
        color: #A73B0B;
        font-size: 13px;
        margin-top: 9px;
        text-align: left;

        &.met {
            color: #1ABC9C;
            font-weight: bold;
        }
    }
}