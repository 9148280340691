﻿.height-resizing-iframe {
  width: 1px;
  min-width: 100%;
}

.responsive-iframe-container {
  overflow: hidden;
  position: relative;
  height: 0;
  max-width: 100%;
}

.responsive-iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}