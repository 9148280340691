﻿@import "../variables.scss";
@import "../mixins.scss";
/*layout*/

$sidebar_width: 265px;

.services-view-actions {

    @include clearfix();

    @include tablet-landscape {
        padding: 14px 0;
        padding-left: $sidebar_width;
        float: left;
    }

    .group-btn {
        display: none;

        @include tablet-landscape {
            display: inline-block;
        }
    }
}

.services-section {
    padding: 0px 12px 12px;

    &.map-view {
        padding: 0;

        @include max-tablet-landscape {
            padding: 0 12px;
        }
    }

    @include tablet-landscape {
        padding: 0;
        margin-top: -4px;
        @include flex-container(map-merge($flex-container-default, ( direction: row, horizontal: flex-start, vertical: flex-start, wrap: wrap )));
    }

    .services-sidebar {
        @include tablet-landscape {
            max-width: $sidebar_width;
            @include flex(1, 1, 100%);
            padding-right: 15px;
        }
    }

    .services-content {
        @include tablet-landscape {
            @include flex(1, 1, 100%);
            max-width: calc(100% - #{$sidebar_width});
            padding-left: 31px;
        }
    }

    .description {
        @include max-mobile-landscape {
            display:flex;
            flex-direction:column;
            gap:25px;
        }
    }
}



.services-sidebar {

    .categories-list-holder {
        padding: 12px 0;
        @include clearfix();

        @include max-tablet-landscape {
            padding:9px 0 0;
        }
    }

    .custom-dropdown {
        padding: 5px 0;

        @include tablet-landscape {
            display: block;
        }

        .dropdown-label {
            @include tablet-landscape {
                display: none;
            }
        }


        &.no-border {
            .dropdown-label {
                font-size: 16px;
                color: #000000;
            }
        }

        .dropdown-content {
            left: 0;
            right: auto;
            top: calc(100% + 10px);

            @include tablet-landscape {
                position: static;
                display: block;
            }

            .categories-list {
                padding-right: 13px;

                @include tablet-landscape {
                    margin-top: -6px;
                    display: block;
                    padding-right: 0;
                }

                li {
                    display: block;
                    border-top: 1px solid #ccc;

                    &:first-child {
                        border-top: none;
                    }
                }

                a {
                    padding: 12px 46px 8px 13px;
                    display: block;
                    color: $color_3;
                    line-height: 32px;
                    font-size: 16px;
                    background-color: transparent;
                    position: relative;
                    @include myTransition();

                    > span {
                        position: absolute;
                        left: 0;
                        height: 32px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        line-height: 32px;
                    }

                    &::after {
                        content: var(--counter-string);
                        display: inline-block;
                        width: 40px;
                        height: 32px;
                        line-height: 32px;
                        text-align: right;
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        font-size: 16px;
                        padding: 0 19px 0 4px;
                        color: #191919;
                    }

                    &.active {
                        background-color: #78DDF8;
                    }
                }
            }
        }

        .content-holder {
            @include tablet-landscape {
                border: none;
                padding: 0;
            }

            .custom-scroll {
                @include tablet-landscape {
                    max-height: none;
                    overflow: hidden;
                }

                .ps__scrollbar-y-rail {
                    @include tablet-landscape {
                        display: none !important;
                    }
                }
            }
        }
    }
}

.services-content {
    overflow: hidden;
    padding-bottom: 32px;

    @include max-tablet-landscape {
           padding: 0;
    }
}



.services-list {
    overflow: hidden;
    @include tablet-landscape{
        margin-left: -19px;
    }

    .services-list-holder {
        @include mobile-landscape {
            @include flex-container(map-merge($flex-container-default, ( direction: row, horizontal: flex-start, vertical: flex-start, wrap: wrap )));
        }


        @include max-tablet-landscape{
            flex-direction:column;
            padding-top:4px;
        }
    }

    article {
        color: $color_3;
        margin: 0 0 22px 0;
        position: relative;
        display: block;


  

        @include tablet-landscape {
            width: 280px;
            margin-right: 17px;

            article:nth-child(3n) {
                margin-right: 0;
            }
        }

        @include max-tablet-landscape {
            display: flex;
            padding-bottom: 3px;
            border-bottom: 1px solid #CCCCCC;
            margin-bottom: 18px;
            width:100%;

            &:last-of-type {
                margin-bottom:0;
            }
        }

        figure {
            margin-bottom: 15px;
            overflow: hidden;
            position: relative;
            height: 190px;
            width: 280px;

            @include max-tablet-landscape {
                min-width: 95px;
                width: 95px;
                min-height: 63px;
                height: 63px;
            }
        }

        img {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            position: absolute;
            height: auto;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        h3 {
            color: #0C2733;
            font-size: 28px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;

            @include mobile-landscape {
                font-size: 22px;
                line-height: 24px;
            }

            @include tablet {
                font-size: 24px;
                line-height: 26px;
            }

            @include tablet-landscape {
                font-size: 28px;
                line-height: 30px;
            }

            @include max-tablet-landscape {
                font-size: 16px;
                font-weight: bold;
            }
        }

        h4 {
            color: #909191;
            font-size: 15px;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;

            @include max-tablet-landscape {
                margin-top: 0;
            }
        }

        address {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            margin-top: 14px;

            @include tablet-landscape {
                font-size: 16px;
            }
        }

        .description {
            font-size: 12px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;

            @include tablet-landscape {
                font-size: 16px;
            }
        }

        .map-link {
            position: absolute;
            z-index: 4;
            top: 10px;
            left: 10px;
        }

        .provider-details {
            position: absolute;
            z-index: 3;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
}

    .sticker {
        font-size: 12px;
        color: $color_1;
        background-color: $color_24;
        width: 63px;
        height: 63px;
        border-radius: 63px;
        position: absolute;
        right: 10px;
        top: 10px;

        @include flex-container(map-merge($flex-container-default, ( direction: column, horizontal: center, vertical: center, wrap: wrap )));

        &.positive {
            background-color: $color_30;
        }

        .persent {
            font-size: 21px;
            font-weight: bold;
        }
    }


    .switch-view-mobile {
        display: block;
        float: right;

        @include tablet-landscape {
            display: none;
        }

        a {
            display: inline-block;
            vertical-align: top;
            font-size: 24px;
            color: $color_10;
            margin-left: 4px;
            padding: 5px;

            &.active {
                color: $color_12;
            }
        }
    }

    $procider_img_width: 330px;

    .provider-view {
        .provider-holder {
            @include tablet {
                @include flex-container(map-merge($flex-container-default, ( direction: row, horizontal: flex-start, vertical: flex-start, wrap: wrap )));
            }
        }

        .provider-img {
            @include tablet {
                max-width: $procider_img_width;
                @include flex(1, 1, 100%);
            }

            h2, h3 {
                display: block;

                @include tablet {
                    display: none;
                }
            }
        }

        .provider-description {

            @include tablet {
                max-width: calc(100% - #{$procider_img_width});
                padding-left: 44px;
                @include flex(1, 1, 100%);

                h2, h3 {
                    display: none;
                }
            }

            h2, h3 {
                display: none;

                @include tablet {
                    display: block;
                }
            }

            .custom-form.form-sm-460 {
                margin: 0;
                padding: 0;
                max-width: none;

                p {
                    margin-bottom: 13px;
                }
            }
        }

        figure {
            overflow: hidden;
            position: relative;
            padding: calc(50%*0.69) 50%;
        }

        img {
            width: 100%;
            display: inline-block;
            vertical-align: top;
            position: absolute;
            height: auto;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        h2 {
            font-size: 28px;
            line-height: 32px;
            font-weight: normal;
            color: $color_15;

            @include tablet {
                font-size: 36px;
                line-height: 42px;
            }
        }

        h3 {
            font-size: 15px;
            line-height: 20px;
            font-weight: normal;
            color: $color_10;
            margin-bottom: 16px;

            @include tablet {
                margin-bottom: 27px;
                font-size: 16px;
            }
        }

        address {
            font-size: 12px;
            color: $color_3;
            font-weight: bold;
            font-style: normal;
            padding-top: 12px;
            padding-bottom: 9px;
            line-height: 25px;

            @include tablet {
                padding-top: 0;
                padding-bottom: 0;
                font-size: 16px;
                line-height: 35px;
                margin-bottom: 9px;
            }
        }

        .description {
            font-size: 12px;
            color: $color_3;

            @include tablet {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .provider-header-mobile {
            display: block;

            @include tablet {
                display: none;
            }
        }

        .provider-header-desktop {
            display: none;

            @include tablet {
                display: block;
            }
        }

        .custom-checkbox {
            > span {

                @include max-tablet-landscape {
                    padding-top: 0;
                }
            }
        }
    }

    .provider-servises {

        table {
            /*width: 100%;*/
            margin-top: 29px;
            display: block;
            vertical-align: top;

            @include mobile-landscape {
                display: inline-block;
                vertical-align: top;
            }

            @include tablet {
                display: table;
                /*table-layout: fixed;*/
                width: 100%;
            }

            tbody {
                display: block;

                @include tablet {
                    display: table-row-group;
                }
            }

            tr {
                display: block;
                margin-top: 30px;

                &:first-child {
                    margin-top: 0;
                }

                @include tablet {
                    margin-top: 0;
                    display: table-row;
                }
            }

            td {
                display: block;
                font-size: 16px;

                @include tablet {
                    display: table-cell;
                    padding-left: 16px;
                    padding-bottom: 37px;
                    font-size: 18px;
                }

                &:first-child {
                    padding-left: 0;
                }

                &.price {
                    display: inline-block;
                    padding-left: 36px;
                    margin-top: -16px;

                    @include tablet {
                        margin-top: 0;
                        padding-left: 0;
                        text-align: left;
                        width: 1px;
                        display: table-cell;
                    }

                    & .through-line {
                        padding-left: 18px;
                    }
                }
            }

            .total {
                font-size: 18px;
                line-height: 28px;
                font-weight: bold;
                border-top: 1px solid #e6e6e6;

                @include tablet {
                    border-top: none;
                }

                .price {
                    text-align: left;
                    display: inline-block;
                    margin-top: 0;
                    padding-left: 0;

                    @include tablet {
                    }
                }


                td, td.price {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: inline-block;

                    @include tablet {
                        border-top: 1px solid #e6e6e6;
                        display: table-cell;
                    }
                }
            }
        }


        .custom-checkbox {
            position: relative;
            z-index: 2;
            font-size: 18px;
        }

        .price {
            position: relative;
            z-index: 1;
            white-space: nowrap;


            > span {
                &.through-line {
                    text-decoration: line-through;
                    color: $color_5;
                }

                &:after {
                    content: var(--curency-string);
                    margin-left: 5px;
                }
            }
        }



        .actions {
            margin-top: 14px;
            text-align: center;

            @include tablet {
                text-align: right;
            }

            .btn {
                display: block;

                @include mobile-landscape {
                    display: inline-block;
                }
            }
        }
    }

    .provider-description-small {
        .provider-servises {
            table {
                td {
                    &.price {
                        padding-left: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .servises-map-holde {
        height: calc(100vh - #{$page-header-mobile-height } - 58px - 58px - 78px - 32px);
        position: relative;
        overflow: hidden;

        @include tablet {
            height: calc(100vh - #{$page-header-tablet-height} - 58px - 58px - 78px - 32px);
        }

        @include tablet-landscape {
            height: calc(100vh - #{$page-header-height} - 58px - 58px - 78px - 32px);
            width: 100%;
        }

        .map-container {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }

    .service-details-view {
        .search-box, .categories-list-holder {
            display: none;

            @include tablet-landscape {
                display: block;
            }
        }
    }

    .services-header-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        @include max-tablet-landscape {
            display: none;
        }

        .map-list-menu-container {
            display: flex;

            .list-icon {
                display: flex;
                margin-right: 23px;
                margin-left: 5px;

                &::before {
                    font-family: "icomoon";
                    content: "\e946";
                    color: #0C2733;
                    font-size: 23px;
                }
            }

            .map-icon {
                display: flex;
                margin-top: -2px;

                &::before {
                    font-family: "icomoon";
                    content: "\e917";
                    font-size: 27px;
                    color: #0C2733;
                }
            }

            .map-icon.active, .list-icon.active {

                &::before {
                    color: #78DDF8;
                }
            }
        }

        .service-to-myrequests-button {
            display: flex;
            width: 280px;
            height: 40px;
            background-color: #F57E4A;
            padding-left: 21.19px;
            padding-right: 15.36px;
            padding-top: 10px;
            padding-bottom: 10px;
            justify-content: space-between;
            align-items: center;
            color: #FFFFFF;
            border-radius: 6px;
            font-size: 15px;

            &:hover {
                background-color: #C45A2C;
            }

            &-text, &-icon {
                display: block;
            }

            &-text {
                text-transform: uppercase;
            }

            &-icon::before {
                font-size: 15px;
                font-family: "icomoon";
                content: "\e911";
                transform: rotate(180deg);
                display: block;
            }
        }
    }

.services-list article {
    @include tablet-landscape {
        width: 280px;
        margin:20.5px 0 20.5px 17px;
    }
}

.my-services-header-desktop {
    display: none;
    color: $color_3;
    line-height: 26px;
    text-align: left;
    text-transform: uppercase;

    .services-link {
        color: #00A9D6;
        cursor: pointer;
    }

    #arrow {
        margin: auto 10px auto 10px;
        font-size: 10px;
    }

    @include tablet-landscape {
        display: flex;
        font-size: 20px;
    }
}

.my-services-header-mobile {
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    color: $color_14;

    @include tablet {
        display: inline-block;
        font-size: 22px;
    }

    @include tablet-landscape {
        display: none;
    }
}


.services-buttons-mobile {
    display: flex;
    width: 100%;
    padding: 0px 10px 0px 10px;

    .service-button {
        font-size: 14px;
        color: $color_14;
        line-height: 19px;
        text-transform: uppercase;
        text-align: center;
        width: 50%;
        padding: 16px 0 14px 0;
        border-bottom: 1px solid $color_14;

        &.active {
            color: #F57E4A;
            border-bottom: 4px solid #F57E4A;
        }
    }

    @include tablet-landscape {
        display: none;
    }

}

    .service-menu-mobile {
        @include tablet-landscape {
            display: none;
        }

        @include max-tablet-landscape {
            display: flex;
            justify-content: space-around;
            margin-bottom: 14px;
            margin-top: -24px;

            &-inner {
                width: 50%;
                text-align: center;
                height: 100%;
            }

            &-item {
                display: block;
                width: 100%;
                height: 100%;
                padding: 15px 0;
                color: #0C2733;
                font-size: 14px;
                text-transform: uppercase;
                border-bottom: 1px solid #0C2733;
            }

            &-item.active, &-item:hover {
                color: #F57E4A;
                border-bottom: 4px solid #F57E4A;
                padding-bottom:12px;
            }
        }
    }

    .switch-view-mobile {
        @include max-tablet-landscape {
            .map-icon, .list-icon {
                color: #0C2733;
                font-size: 27px;
            }

            .map-icon {
                padding: 0 2px 0 5px;
                margin-right: -7px;
            }

            .list-icon {
                padding:0 12px 0 5px;
            }

            .active {
                color: #78DDF8 !important;
            }

            .map-icon::before {
                font-family: "icomoon";
                content: "\e917";
            }

            .list-icon::before {
                font-family: "icomoon";
                content: "\e94e";
                font-size:24px;
            }
        }
    }

    .service-info {

        @include max-tablet-landscape {
            margin-left: 15px;
            margin-top: -5px;
        }
    }

.services-sidebar {
    @include max-tablet-landscape {
        .dropdown-label{
            padding-left:0 !important;
        }
    }
}

.service-description {
    font-size: 13px;
    margin-left: 36px;
}
