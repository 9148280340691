﻿@import "../variables.scss";
@import "../mixins.scss";

$dd-height: 300px;

.custom-dropdown-large {
    .dropdown-content {
        width: 280px;
        .content-holder {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

.custom-dropdown {
  display: inline-block;
  text-align: left;
  position: relative;
  min-width: 200px;

  &.property-manager-dropdown {
    padding: 0 10px 0 10px;

    &.nav-active {
      background-color: $color_12;
    }

    &:hover {
      background-color: #3E6070;
      color: $color_1;
    }
  }

  &.left-navigation-dropdown {
    display: block;

    #reportsList {
      min-height: 30px;
    }
  }

  &.property-manager-header-dropdown-left-navigation {
    padding: 0 10px 0 10px;

    &.nav-active {
      background-color: $color_12;
    }

    &:hover {
      background-color: #BBCDD1;
      height: $page-header-mobile-height;
    }
  }

  ul {
    display: block;
    width: 100%;
  }

  li {
    transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    white-space: nowrap;
    border-top: 1px solid $color_24;
    position: relative;
    display: block;
    width: 100%;
    background-color: transparent;

    a {
      display: block;
      font-size: 13px;
      color: $color_3;
      line-height: 20px;
      padding: 10px;
      position: relative;
    }

    &:first-child {
      border-top: 1px solid $color_1;
    }

    &.selected {
      background-color: #78DDF8;

      a {
        color: $color_3;
      }
    }

    &.hovered {
      background-color: $color_9;

      a {
        color: $color_3;
      }
    }
  }

  select {
    display: none;
  }

  .animated {
    animation-duration: .5s;
  }

  .dropdown-label {
    position: relative;
    color: $color_3;
    font-size: 13px;
    line-height: 18px;
    padding: 10px 44px 10px 16px;
    border: 1px solid $color_7;
    display: block;
    background-color: $color_1;
    border-radius: $btn-radius;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: top;
    }

    .cc-dropdown-label {
      @include max-tablet {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .cc-dropdown-label-large {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:before {
      font-family: 'icomoon';
      content: "\e909";
      position: absolute;
      font-size: 16px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .dropdown-content {
    overflow: hidden;
    position: absolute;
    z-index: 6;
    top: calc(100%);
    display: none;
    min-width: 100%;
    left: 0;
  }

  .content-holder {
    min-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background-color: $color_1;
    padding: 5px 10px 5px 10px;
    border: 1px solid $color_24;
    border-radius: 4px;

    .custom-scroll {
      max-height: $dd-height;
      overflow-y: auto;
      overflow-x: hidden;

      &.reports-scroll {
        max-height: 410px; //AN,KD: required 3 more elemenents, before scroll to appear
      }
    }

    .custom-scroll-long {
      max-height: calc(90vh - 120px);
    }
  }

  .cc-content-holder {
    padding: 7px 0px 7px 0px;

    .custom-scroll {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 244px;
      padding: 0px 15px 0px 15px;

      a.cc-former-separator {
        display: block;
        font-size: 13px;
        color: $color_1;
        line-height: 20px;
        position: relative;
        height: 25px;
        padding-left: 26px;
        padding-top: 2px;
        background: #95989A;
        width: calc(100% + 30px);
        margin: 7px -15px 6px -15px;
      }
    }
  }

  @media screen and (max-width: 380px) {
    .cc-content-holder {
      width: calc(100vw - 60px);
    }
  }

  &.dropdown-sm {
    min-width: 1px;

    lI {
      a {
        font-size: 14px;
        line-height: 16px;
        padding: 8px;
      }
    }

    .content-holder {
      padding: 5px 5px 13px 5px;
    }
  }

  &.no-border {
    vertical-align: top;
    min-width: 1px;

    .dropdown-label {
      border: none;
      font-size: 15px;
      line-height: 24px;
      padding: 0 38px 0 5px;
      color: $color_12;

      @include tablet {
        font-size: 22px;
      }

      @include tablet-landscape {
        line-height: 23px;
        font-size: 20px;
      }

      &:before {
        font-size: 18px;
      }
    }

    .dropdown-content {
      top: calc(100% + 18px);
      left: auto;
    }

    .bank-details-dropdown {
      top: calc(100% + 13px);
    }

    li {
      a {
        padding: 10px;

        &.cc-dropdown {
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.open {
      .dropdown-label {
        border: none;
        background-color: $color_1;
      }
    }
  }

  &.open {
    z-index: 6;

    &.left-navigation-dropdown {
      z-index: auto;
    }

    .dropdown-label {
      background-color: $color_9;
      border: 1px solid $color_12;

      &:before {
        color: $color_12;
      }
    }

    .dropdown-content {
      &.left-navigation {
        position: relative;
        left: $left-navigation-area-width;
        bottom: calc($left-navigation-area-height / 3);
      }

      &#logo-dropdown {
        bottom: calc($left-navigation-area-height * 2);
      }

      .active-language {
                color: #00A9D6;
                background-color: #E7F9FE;
            }

      display: block;
    }

    &.multiple {
      .dropdown-label {
        &:before {
          content: "\e90c";
        }
      }
    }
  }

  &.startOpen {
    z-index: 7;
  }


  &.header-dropdown {
    min-width: 1px;

    .label-wrapper {
      padding-top: 12px;
      padding-bottom: 15px;

      a {
        height: 32px;
        line-height: 32px;
      }

      .drop-down-wrapper:hover {
        background-color: #3E6070;
        border-radius: 2px;
      }

      &.left-navigation {
        padding: 0px 0px 0px 0px;
      }
    }

    .dropdown-content {
      a {
        display: block;
        font-size: 13px;
        color: $color_3;
        line-height: 20px;
        padding: 10px;

        &:hover {
          color: $color_3;
        }
      }

      a:hover {
        color: #000000 !important;
      }
    }

    .dropdown-label {
      border: none;
      background: none;
      font-size: 14px;
      line-height: 24px;
      padding: 4px 27px 3px 10px;

      @include tablet {
        padding: 0 27px 0 10px;
      }

      &:before {
        width: 11px;
        height: 12px;
        line-height: 11px;
        color: $color_33;
      }

      &.left-navigation:before {
        color: $color_3;
      }

      span.left-navigation {
        color: $color_3;
      }

      &.user-icon {
        padding: 4px 10px;

        @include tablet {
          padding: 0 33px 0 10px;
        }

        &:before {
          color: $color_33;
          display: none;

          @include tablet {
            display: block;
          }
        }

        span {
          white-space: nowrap;
          max-width: 120px;
          text-overflow: ellipsis;
          overflow: hidden;
          text-transform: none;
          font-size: 0;

          @include user-navigation-min {
            font-size: 13px;
          }

          @include user-navigation-max {
            font-size: 0;
          }

          &:before {
            font-family: 'icomoon';
            content: "\e904";
            font-size: 24px;
            display: inline-block;
            height: 24px;
            line-height: 24px;
            color: $color_33;
            margin-right: 9px;
            margin-top: 5px;
          }
        }
      }


      &:hover {
        color: #FFFFFF;

        &:before {
          color: #FFFFFF;
        }

        &.left-navigation:before {
          color: $color_3;
        }

        span {
          color: #FFFFFF !important;

          &:before {
            color: #FFFFFF;
          }
        }

        span.left-navigation {
          color: $color_3 !important;

          &:before {
            color: $color_3;
          }
        }
      }

      &.nav-active:hover {
        span.left-navigation {
          color: #FFFFFF !important;

          &:before {
            color: #FFFFFF;
          }
        }
      }

      .dropdown-content {
        a {
          display: block;
          font-size: 13px;
          color: $color_3;
          line-height: 20px;
          padding: 10px;

          &:hover {
            color: $color_3;
          }
        }
      }

      &.open {
        .dropdown-label {
          &:before {
            color: $color_1;
          }

          &.left-navigation:before {
            color: $color_3;
          }

          span {
            color: $color_1;

            &.left-navigation {
              color: $color_3;
            }
          }

          &:hover {
            &:before {
              color: $color_33;
            }

            span {
              color: $color_33;
            }
          }
        }
      }
    }

    .bank-details-dropdown {
      top: calc(100% + 13px);
      right: 0;
    }

    .bank-details-content-holder {
      min-width: 200px;
      width: fit-content;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #ffffff;
      border: 1px solid #95989a;
      border-radius: 4px;
      padding: 0px;
    }

    .bank-details-content {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 960px;
      height: 200px;
      width: max-content;
      overflow-y: auto;
      color: #0C2733;
      padding-top: 18px;
    }

    .bank-account-item:nth-child(3n+0),
    .bank-account-item:last-child {
      border-right: none;
    }

    .bank-details-header {
      border-bottom: 1px solid #95989A;
      padding-left: 30px;
      padding-top: 12px;
      padding-bottom: 11px;
      position: relative;

      .icon-Close {
        right: 24px;
      }
    }

    .bank-account-item {
      width: 310px;
      height: 170px;
      max-width: 310px;
      padding-left: 29px;
      flex-basis: auto;
      flex-grow: 1;
      border-right: 1px solid #CCCCCC;
    }

    .bank-account-BankName {
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .bank-account-Iban {
      & > label {
        font-weight: 600;
      }
    }

    .bank-account-Details {
      height: 95px;
      overflow: hidden;
      text-overflow: ellipsis;

      & > label {
        font-weight: 600;
      }
    }

    .close-dropdown {
      color: #928D8D;
      position: absolute;
      width: 12px;
      height: 12px;
      line-height: 28px;
      text-align: center;
      right: 4px;
      top: 0;
      margin: auto;
      padding-top: 7px;
      font-size: 20px;

      @include tablet-landscape {
        right: 24px;
      }

      &:before {
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .header-pm {
    .header-dropdown.open {
      .dropdown-label {
        &:before {
          top: 4px;
        }
      }
    }
  }
}