﻿@import "../variables.scss";
@import "../mixins.scss";

.simple-table {
    table {
        border-collapse: collapse;
        font-size: 16px;
        text-align: left;
    }

    caption {
        text-align: left;
        font-size: 18px;
    }

    th {
    }

    td {
        padding: 0 10px;

        &.th {
            font-weight: bold;
            padding: 10px 0 0 0;
        }
    }
}


/*.table-actions-list {
    > a, > span{
        text-align: center;
        font-size: 13px;
        width: 30%;
        display: inline-block;
        vertical-align: top;
        > span{
            display: block;
        }

        > [class^="icon-"], > [class*=" icon-"]{
            font-size: 32px;
            margin-bottom: 6px;
        }
    }

    a{
        [class^="icon-"], [class*=" icon-"] {
            color: $color_10;
        }

        span{
            color: $color_12;
        }
    }

    span {
        [class^="icon-"], [class*=" icon-"] {
            color: $color_6;
        }

        span{
            color: $color_6;
        }
    }
}*/

.table-info-list {

    dt {
        color: $color_5;
        font-size: 13px;
    }

    dd {
        color: $color_3;
        font-size: 18px;
        padding-bottom: 13px;
        line-height: 21px;
    }

    + div {
        padding-top: 37px;
    }
}

.table-actions-list {
    @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: wrap, horizontal: space-around )));

    > div {
        /*@include flex2(1);*/



        > a, > span {
            color: #0FB4E1;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            display: block;

            > span {
                display: block;
                margin-top: 18px;

                &:first-child {
                    margin-top: 0;
                }
            }

            & [class^="icon-"], & [class*=" icon-"] {
                color: #8DA3A9;
                font-size: 28px;
                width: 28px;
                height: 28px;
                line-height: 28px;
                display: inline-block;

                &.green-icon {
                    color: #45C94D;
                }

                &.gray-icon {
                    color: #C8D8DC;
                }
            }
        }


        > span {
            color: $color_6;

            & [class^="icon-"], & [class*=" icon-"] {
                color: $color_6;
            }
        }
    }
}

$column_range: 20;

.twoLineDate {
    text-align: center;

    @include tablet-landscape {
        text-align: left;
        display: block;
    }

    .day {
        display: block;
        font-size: 20px;
        line-height: 20px;

        @include tablet-landscape {
            display: inline-block;
            vertical-align: top;
            margin-right: 4px;
            font-size: 15px;
        }
    }

    .month {
        font-size: 13px;
        display: block;

        @include tablet-landscape {
            line-height: 20px;
            font-size: 15px;
            display: inline-block;
            vertical-align: top;
        }
    }
}


.custom-checkbox {
    &.disabled {
        > span {
            opacity: 0.5;
        }
    }
}

#conciergeSpTableSection .custom-table-2 .filters .holder .form-group {
    @include tablet-landscape {
        padding: 0px 10px 0px 474px;
    }
}

.custom-table-2 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: $color_3;

    table {
        display: none;
    }

    #notificationDefinitionTable_wrapper {
        width: 100%;
    }

    table.dataTable {
        width: 100%;
        border-bottom: 1px solid #ccc;
        max-width: 100%;

        &.table-draweden {
            display: table;
        }

        thead {
            th {
                font-weight: 400;
                border: none;
                text-align: left;
                padding-left: 10px;
                padding-right: 13px;
                padding-top: 11px;
                padding-bottom: 9px;
                background-color: $color_4;
                color: $color_35;
                line-height: 20px;

                @include small-desktop {
                    padding-left: 25px;
                }

                &.sorting, &.sorting_asc, &.sorting_desc {
                    position: relative;
                    padding-right: 25px;

                    &:before, &:after {
                        content: "";
                        width: 0;
                        height: 0;
                        position: absolute;
                        right: 8px;
                        border-style: solid;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        padding-top: 5px;
                    }

                    &:before {
                        border-width: 4px 4px 0 4px;
                        border-color: #767673 transparent transparent transparent;
                        top: 10px;
                    }

                    &:after {
                        bottom: 10px;
                        border-width: 0 4px 4px 4px;
                        border-color: transparent transparent #767673 transparent;
                    }

                    &.details-control {
                        padding-right: 13px;

                        &:after, &:before {
                            display: none;
                        }
                    }
                }

                &.sorting_asc {
                    &:before {
                        border-color: #e8e8e8 transparent transparent transparent;
                    }
                }

                &.sorting_desc {
                    &:after {
                        border-color: transparent transparent #e8e8e8 transparent;
                    }
                }


                &.mobile-buttons {
                    padding: 11px 0 9px 0;
                    width: 1px;
                }

                &.details-control {
                    width: 1px;

                    & + th {
                        @include small-desktop {
                            padding-left: 0;
                        }
                    }
                }

                &.sm-width {
                    width: 1px;
                }
            }
        }

        tbody {
            th, td {
                font-weight: 400;
                padding-left: 10px;
                padding-right: 13px;
                padding-top: 5px;
                padding-bottom: 5px;
                line-height: 20px;
                text-overflow: ellipsis;
                max-width: 200px;
                overflow: hidden;
                width: auto;

                @include small-desktop {
                    padding-left: 25px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                }

                &.child {
                    padding: 0;

                    ul, li {
                        display: block;
                        width: 100%;
                    }
                }


                &.mobile-buttons {
                    vertical-align: middle;
                    padding: 0;
                }

                &.details-control {
                    & + td {
                        @include small-desktop {
                            padding-left: 0;
                        }
                    }
                }

                &.sm-width {
                    /*padding-right: 0;*/
                    /*max-width: none;*/
                }

                &[data-currency] {
                    white-space: nowrap;

                    &:after {
                        content: attr(data-currency);
                        display: inline-block;
                    }

                    > span {
                        display: inline-block;
                        vertical-align: top;
                        cursor: help;

                        @include tablet-landscape {
                            max-width: 80px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }

            th {
                border: none;
            }

            td {
                background-color: #fff;
                border-top: 1px solid #ccc;

                &.child {
                    border-top: none;
                    line-height: 0;
                    font-size: 0;

                    > .mobile-trigger-modal {
                        display: none;
                    }
                }

                & .mobile-container {
                    display: block;

                    @include small-desktop {
                        display: none;
                    }

                    .date {
                        font-size: 10px;
                    }
                }

                & .desktop-container {
                    display: none;

                    @include small-desktop {
                        display: block;
                    }
                }

                & .mobile-trigger-modal {
                    height: 45px;
                    width: 45px;
                    display: inline-block;
                    vertical-align: top;
                    line-height: 45px;
                    text-align: center;
                }
            }

            tr {

                &.child {
                    &:hover {
                        td {
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        &.responsive-1200 {
            thead {
                th {
                    &.details-control {
                        & + th {
                            padding-left: 25px;

                            @include desktop {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            tbody {
                td {
                    &.details-control {
                        & + td {
                            padding-left: 25px;

                            @include desktop {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }

        &.dtr-inline.collapsed {
            & > thead > tr {
                &[role="row"] {
                    & > td,
                    & > th {
                        &:first-child {
                            padding-left: 50px;
                        }
                    }
                }
            }

            & > tbody > tr {
                &[role="row"] {
                    & > td,
                    & > th {
                        &:first-child {
                            padding-left: 50px;

                            &:before {
                                top: 0;
                                bottom: 0;
                                left: 17px;
                                margin: auto;
                                height: 15px;
                                width: 15px;
                                display: block;
                                position: absolute;
                                color: $color_2;
                                border: none;
                                border-radius: 0;
                                box-shadow: none;
                                box-sizing: border-box;
                                text-align: center;
                                text-indent: 0 !important;
                                font-family: 'icomoon';
                                line-height: 14px;
                                content: "\e90f";
                                background-color: transparent;
                                /*@include myTransition();*/
                                transform: rotate(0);
                                -webkit-transform: rotate(0);
                            }
                        }
                    }
                }

                &.parent {
                    & > td,
                    & > th {
                        &:first-child {
                            &:before {
                                content: "\e90f";
                                background-color: transparent;
                                transform: rotate(90deg);
                                -webkit-transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }



        &.nowrap {
            th, td {
                white-space: nowrap;

                &.nowrap {
                    white-space: nowrap;
                }

                @include tablet {
                    white-space: nowrap;
                }
            }
        }



        & .dataTables_filter {
            display: none;
        }

        ul {
            li {
                display: block;
                position: relative;
                padding: 0 0 0 10px;

                &:before {
                    content: "-";
                    position: absolute;
                    left: 0;
                    width: 10px;
                    height: 10px;
                    top: 5px;
                    line-height: 10px;
                }
            }
        }
    }

    .nowrap {
        white-space: nowrap;
    }


    .dtr-data {
    }

    .dtr-title {
        display: block;
    }

    $table-img-width: 183px;

    .table-details {
        white-space: normal;
        font-size: 15px;
        display: none;

        @include small-desktop {
            display: block;
        }

        .table-details-holder {
            overflow: hidden;
            padding: 20px 0 20px 50px;
            line-height: 20px;

            @include tablet {
                @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: wrap, horizontal: flex-start, vertical: stretch )));
            }
        }

        figure {
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
            width: 183px;
            padding-top: calc((#{$table-img-width}*0.69)/2);
            padding-bottom: calc((#{$table-img-width}*0.69)/2);
            background: #e0dee0;


            @include myTransition();
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            min-height: 100%;
            width: 100%;
            bottom: 0;
        }

        address {
            font-weight: 700;
            color: $color_3;
            font-style: normal;
            display: block;
            margin-bottom: 6px;
        }

        p {
            color: $color_3;
            margin: 0 0 9px 0;
        }

        .description {
            padding-left: 25px;
            box-sizing: content-box;
            max-width: 450px;
            position: relative;
            padding-bottom: 20px;

            .provider-details {
                position: absolute;
                bottom: 0;
            }
        }
    }

    .dataTables_paginate {
        overflow: hidden;
        text-align: center;
        font-size: 0;
        padding: 23px 0;

        @include tablet-landscape {
            text-align: right;
        }

        a, .ellipsis {
            display: inline-block;
            vertical-align: top;
            width: 36px;
            height: 36px;
            border: 1px solid #B9C2C5;
            color: #00A9D6;
            background-color: #fff;
            line-height: 34px;
            font-size: 14px;
            margin-left: 4px;
            text-align: center;
            @include myTransition();

            @include tablet-landscape {
                width: 30px;
                height: 30px;
                line-height: 28px;
                font-size: 15px;
            }

            &.disabled {
                color: #B9C2C5;
            }

            &.current {
                color: #fff;
                background-color: #00A9D6;
                border: 1px solid #00A9D6;
            }

            &.previous, &.next {
                font-size: 0;

                &:before {
                    display: inline;
                    font-family: "icomoon";
                    font-size: 15px;
                }
            }

            &.previous:before {
                content: "\e910";
            }

            &.next:before {
                content: "\e90f";
            }

            &:hover {
                color: #fff;
                background-color: #0FB4E1;
                border: 1px solid #0FB4E1;

                &.disabled {
                    color: #B9C2C5;
                    background-color: #fff;
                    border: 1px solid #B9C2C5;
                    cursor: default;
                }
            }
        }
    }

    .table-actions {
        white-space: nowrap;
        /*width: 100%;*/
        /*@include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));*/

        a {
            color: #8DA3A9;
            display: inline-block;
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                color: $color_18;
            }

            &[class^="icon-"], &[class*=" icon-"] {
                font-size: 25px;
                color: #8DA3A9;
            }

            &.green-icon {
                color: #45C94D;

                &:hover {
                    color: #319537;
                }
            }

            &.gray-icon {
                color: #C8D8DC;
            }
        }

        span {
            margin-left: 20px;
            display: inline-block;
            color: $color_36;

            &[class^="icon-"], &[class*=" icon-"] {
                font-size: 25px;
            }
        }
    }

    .dataTables_filter {
        display: none;
    }

    .filters {
        .holder {
            width: 100%;
            padding: 0 10px;
            // @include flex-container(map-merge($flex-container-default, ( direction: column-reverse, vertical: center, horizontal: flex-start, wrap: wrap )));
            @include flex-container(map-merge($flex-container-default, ( direction: row-reverse, vertical: center, horizontal: flex-start, wrap: wrap )));

            .search-container {
                margin-bottom: 0;
                width: 100%;
                display: table;

                @include tablet-landscape {
                    margin-bottom: 0;
                }



                .search-box, .filter-modal-box {
                    display: table-cell;
                    vertical-align: top;
                }

                .search-box {
                    width: 100%;
                }

                .filter-modal-box {
                    padding-left: 10px;
                    display: table-cell;

                    @include tablet-landscape {
                        display: none;
                    }

                    .btn {
                        min-width: 1px;
                        width: auto;
                        white-space: nowrap;
                    }
                }
            }

            & > div {
                display: none;
                width: 100%;

                &.flex {
                    display: none;
                    @include flex(1, 1, 100%);
                }


                &.responsive {
                    margin-bottom: 10px;
                    display: block;

                    &.action-button {
                        text-align: center;
                        order: 2;

                        @include tablet {
                            text-align: left;
                            order: unset;
                        }

                        .btn {
                            width: 100%;

                            @include tablet {
                                width: 0;
                            }
                        }
                    }
                }

                &:last-child {
                    .form-group {
                        padding-right: 0;
                    }
                }
            }

            &[data-responsive="mobile"] {
                padding: 0 10px;

                @include tablet {
                    padding: 0 10px;
                    @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                }

                @include tablet-landscape {
                    padding: 0;
                }

                & > .bank-details {
                    @include tablet {
                        width: 250px;
                    }
                }

                & > div {
                    @include tablet {
                        display: block;
                        width: auto;
                    }

                    &.flex {
                        @include tablet {
                            display: block;
                            @include flex(1, 1, auto);
                        }
                    }

                    &.responsive {
                        @include tablet {
                            margin-bottom: 0;
                        }
                    }
                }

                .form-group {
                    @include tablet {
                        padding: 0 10px;
                        @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                    }

                    .form-field {
                        @include tablet {
                            @include flex2(1);
                        }
                    }
                }
            }

            &[data-responsive="tablet"] {
                @include tablet-landscape {
                    padding: 0;
                    @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                }

                & > div {
                    @include tablet-landscape {
                        display: block;
                        width: auto;
                    }

                    &.flex {
                        @include tablet-landscape {
                            display: block;
                            @include flex(1, 1, auto);
                        }
                    }

                    &.responsive {
                        @include tablet-landscape {
                            margin-bottom: 0;
                        }
                    }
                }

                .form-group {
                    @include tablet-landscape {
                        padding: 0 10px;
                        @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                    }

                    .form-field {
                        @include tablet-landscape {
                            @include flex2(1);
                        }
                    }
                }
            }

            &[data-responsive="desktop"] {
                @include desktop {
                    padding: 0;
                    @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                }

                & > div {
                    @include desktop {
                        display: block;
                        width: auto;
                    }

                    &.flex {
                        @include desktop {
                            display: block;
                            @include flex(1, 1, auto);
                        }
                    }

                    &.responsive {
                        @include desktop {
                            margin-bottom: 0;
                        }
                    }
                }

                .form-group {
                    @include desktop {
                        padding: 0 10px;
                        @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                    }

                    .form-field {
                        @include desktop {
                            @include flex2(1);
                        }
                    }
                }
            }

            .bank-details {
                flex: 25 0 auto;
            }

            .how-to-pay-label {
                width: fit-content;
                font-size: 14px;
                max-width: 100%;
                white-space: normal;

                span {
                    width: 100%;
                }
            }
        }
    }


    .long-text {
        max-width: 22em;

        @include tablet-landscape {
            max-width: 30em;
        }
    }

    .ellipsis {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.text-green {
    color: #319537;
}

.text-red {
    color: #A72301;
}

.table-header-label {
    font-size: 13px;
    font-weight: bold;
    padding-top: 16px;
    padding-left: 15px;
    padding-bottom: 17px;
    border-top: 1px solid #000000;
    display: none;

    .head-label {
        width: calc(100% - 470px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include small-desktop {
        border-top: none;
        padding-left: 0;
        display: block;
    }
}

.bills-table td {
    background-color: white;
}

.bills-page-details-separator {
    padding: 0 10px 0 10px;
}

.bills-page-previous-nav {
    color: #00A9D6 !important;
    cursor: pointer;
}

.custom-table-payments {
    .payments-header-type.payments-header-type {
        width: 20%;
    }

    .payments-header-month.payments-header-month {
        width: 10%;
    }

    .payments-header-description.payments-header-description {
        width: 48%
    }

    .payments-header-charge.payments-header-charge {
        width: 10%;
    }

    .payments-header-status.payments-header-status {
        width: 10%;

        @media(max-width: 1180px) {
            width: 12%;
        }
    }

    .payments-header {
        vertical-align: top;
        background-color: #E3F3F3;
        font-weight: bold;
        font-size: 13px;
    }

    .totalValue.totalValue {
        text-align: right;
        font-weight: normal;
    }

    .not-distributed {
        color: #8E8686;
    }
}

.has-pending-amount {
    color: #A73B0B;
}

.custom-table-charges {
    .charges-header {
        background-color: #F9E8D9;
        font-weight: bold;
        vertical-align: top;
    }

    .charge-cost-type-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }

    .total-pending-for-group {
        vertical-align: bottom;
    }

    .charges-headers {
        font-weight: bold;
    }

    .pending-charges-row {
        text-align: right;
        font-weight: bold;
    }

    .border-bottom-none {
        border-bottom: none;
    }

    .border-top-none {
        border-top: none;
    }

    .border-right-none {
        border-right: none;
    }

    .border-left-none {
        border-left: none;
    }

    .extra-right-border.extra-right-border {
        border-right: 1px solid #cccccc;
    }

    .charge-parts-compact-middle.chargeParts {
        text-align: right;
    }
}

.custom-table-previous-charges {
    .charges-header {
        background-color: #E8E8E8;
        vertical-align: top;
        font-weight: bold;
    }

    .pending-charges-row {
        text-align: right;
    }

    .group-charge-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        .grouped-charge {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.custom-table-balances {
  table-layout: fixed;

  .balance-header-type.balance-header-type {
    width: 20%;
  }

  .balance-header-filler.balance-header-filler {
    width: 10%;
  }

  .balance-description-header.balance-description-header {
    width: 48%;
  }

  .individual-balance-desktop {
    padding-left: 17px;

    .individual-balance-value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .with-vat.with-vat {
      padding-right: 7px;
    }
  }

  .balance-header {
    width: 20%;
    vertical-align: top;
    font-weight: bold;
    word-break: break-word;
  }

  .border-left-none {
    border-left: none;
  }

  .border-right-none {
    border-right: none;
  }

  .balance-description-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .balance-description {
      flex: 1;
    }
  }
}

.bills-table {
  display: none;
  padding-left: 10px;

  @include small-desktop {
    display: block;
    padding-left: 0;
  }

  .custom-table-bills {
    table-layout:fixed; 
    max-width: none;
  }
}

.mobile-balance-label {
    text-align: left;
    font-weight: bold;
    font-size: 13px;
    padding-left: 10px;
    padding-bottom: 10px;

    @include small-desktop {
        display: none;
    }
}

.align-text-right {
    text-align: right;
}

.bills-table-content-container {

    * {
        border-width: 0.01px !important;
    }


    .info-modal-container {
        position: absolute;
        display: none;
    }

    .info-icon {
        position: relative;
        cursor: pointer;

        &::before {
            font-family: "icomoon";
            content: "\e913";
            color: #00A9D6;
            left: -17px;
            font-size: 13px;
            position: absolute;
        }
    }
}

.bills-details-button-container {
  text-align: right;
  margin-top: 15px;
  display: flex;
  justify-content: center;

  .bill-details-button {
    width: 230px;
    border-radius: 14px;
    border: none;
    background-color: #DAF0F6;
    color: #00A9D6;
    font-size: 12px;
    position: relative;
    padding: 6px;
    vertical-align: middle;

    .arrow-right {
      padding-right: 15px;

      &::before {
        vertical-align: middle;
        font-family: 'icomoon';
        content: "\e911";
        font-size: 12px;
        display: inline-block;
        color: #00A9D6;
        transform: rotate(180deg);
        position: absolute;
        top: 7px;
        left: 82px;
      }
    }
  }

  @include small-desktop {
    display: none;
  }
}

.bills-table-mobile {
  @include small-desktop {
    display: none;
  }

  .balance-description-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 75%;
  }

  .balance-description-container.with-vat {
    width: 75%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .vat-label {
      color: #767676;
    }
  }

  .balance-table {
    display: flex;
    flex-flow: column nowrap;
    font-size: 13px;
    border-top: 1px solid #CCCCCC;

    .individual-balance {
      height: 40px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      border-bottom: 1px solid #CCCCCC;
      align-items: center;
      padding-left: 10px;
      padding-right: 15px;

      .individual-balance-total {
        text-align: right;
        font-weight: bold;
        width: 25%;
        word-break: break-all;
      }
    }

    .individual-balance-total.advance {
      color: #369B86;
    }

    .section-header {
      background-color: #E8E8E8;
      font-weight: bold;
    }

    .balance-cost-type {
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }

    .individual-balance-total.pending {
      color: #A73B0B;
      max-width: 120px;
    }
  }

  .first-balance-container {
    justify-content: space-between;
  }
}

.balance-positive {
    color: #369B86;
}

.bills-desktop {

    .table-header-label {
        margin-top: 30px;
    }

    @include max-tablet {
        display: none;
    }
}

.text-align-center {
    text-align: center !important;
}

tr.no-bills-label {
    background: #F1F1F1;
    height: 80px;

    td {
        vertical-align: middle;
        text-align: center;
        color: #818181;
    }
}

.no-bills-label-mobile {
  background: #F1F1F1;
  height: 80px;
  vertical-align: middle;
  text-align: center;
  color: #818181;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
  border-top: 1px solid #000000;
  font-size: 13px;
}

.bills-mobile {

    @include tablet {
        display: none;
    }
}

#bills-table-container {
    .error-offset {
        margin-top: 50px;
    }
}

.custom-table-bills {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: $color_3;
  margin-bottom: 30px;
  width: 100%;
  max-width: 1170px;
  table-layout: fixed;


  @include max-tablet {
    margin-top: 10px;
    line-height: 18px;
  }

  .start-balance {
    background: #F9F9F9;

    td.totalValue {
      &.text-green, &.text-red {
        @include tablet {
          color: black;
        }
      }
    }
  }

  .cell-borderless-vertical {
    border-top: none;
    border-bottom: none;
  }

  .nowrap {
    white-space: nowrap;
  }

  th, td {
    font-family: "Roboto", sans-serif;
  }

  tr > td.no-border {
    border-right: none;
  }

  tr > td.no-border + td {
    border-left: none;
  }

  th {

    &.bills-header-type {
      width: 20%;

      @include max-tablet {
        display: none;
      }
    }

    &.bills-header-month {
      width: 8.2%;
      min-width: 94px;

      @include max-tablet {
        width: 18%;
        min-width: initial;
      }
    }

    &.bills-header-description {
      width: 62.35%;

      @include max-tablet {
        width: 61.5%;
      }
    }

    &.bills-header-charge {
      width: 11.4%;

      @include max-tablet {
        width: 20.5%;
      }
    }
  }

  td {
    border: 1px solid #CCCCCC;
    word-break: break-all;
  }

  td.date-cell {
    vertical-align: top;

    .date-desktop {
      font-size: 13px;
      vertical-align: top;
    }

    .date-year {
      font-size: 12px;
    }

    .date-day {
      font-size: 14px;
    }
  }

  td:first-child {
    border-left: none;
  }

  td:last-child {
    border-right: none;
    text-align: right;
  }

  tbody {
    tr.payment {
      @include tablet {
        background: #F9F9F9;
      }

      div.payment-mobile-label {
        color: #737474;
        font-size: 12px;
        margin-top: 1px;
        line-height: 16px;
      }
    }

    tr.total-group {
      td:first-child {
        text-align: right
      }

      .borderless {
        @include max-tablet {
          border: none;
        }
      }
    }

    tr > td {
      &.chargeTotal {
        @include tablet {
          background: #E7F9FE;
          font-weight: bold;
        }
      }

      &.charge-total-label {
        @include tablet {
          font-weight: bold;
        }
      }
    }

    td {
      padding: 10px;
      padding-left: 15px;
      padding-right: 15px;

      @include max-tablet {
        padding: 11px 0px 11px 9px;
      }
    }

    td:last-child {
      padding-right: 14px;
      padding-left: 1px;

      @include max-tablet {
        padding-right: 10px;
      }
    }

    td.groupName,
    td.totalValue {
      @include tablet {
        font-weight: bold;
      }
    }

    td.paymentGroup {
      font-weight: bold;
      background: #fff;
    }

    .chargeParts {
      @include max-tablet {
        font-weight: normal;
        color: #A72301;
      }
    }

    .charge-parts-compact {
      @include max-tablet {
        padding-top: 14px;
        padding-bottom: 5px;
      }
    }

    .charge-parts-compact-middle {
      @include max-tablet {
        padding: 2px 11px;
      }
    }

    .charge-parts-compact-last {
      @include max-tablet {
        padding-top: 8px;
        padding-bottom: 15px;
      }
    }

    tr.postitiveTotalRow {
      background: #CEEFE5;

      @include max-tablet {
        font-weight: bold;
        height: 59px;
      }
    }

    tr.negativeTotalRow {
      background: #FCEDDE;

      @include max-tablet {
        font-weight: bold;
        height: 59px;
      }
    }
  }

  .ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.text-bold {
    font-weight: bold !important;
}

.month-picker-details.month-picker-details {
    @media(max-width: 600px) {
        width: calc(100% - 20px) !important;
        margin: auto;
    }
}

.balance-label-ribbon-mobile {
    font-size: 13px;
    display: flex;
    flex-flow: row nowrap;
    font-weight: bold;
    min-height: 40px;
    background-color: #DAF0F6;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    padding: 10px 10px 10px 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;

    @include small-desktop {
        display: none;
    }

    .balance-value {
        color: #A73B0B;
    }

    .balance-value {
      @include max-tablet {
        font-size: 16px;
      }
    }
}

.pending-payment-star-mobile {
    color: #FF0000;
}

.balance-label-ribbon-mobile-details {
    padding: 10px 10px 10px 10px;
    margin-bottom: 0;
}

.show-mobile-details {
  display: block !important;
}

.bills-table-mobile-details {
  display: none;

  .border-bottom-none.border-bottom-none {
    border-bottom: none;
  }

  .bills-details-table {
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid #0C2733;
    margin-top: 24px;
    font-size: 13px;

    .charge-cost-type-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-entry-description {
          color:#767676;
      }
    }

    .entry-date {
      display: block;

      @media(min-width: 555px) {
        display: flex;
        flex-flow: row nowrap;
      }
    }

    .align-top {
      vertical-align: top;
    }

    .align-center {
      vertical-align: middle;
    }

    .charge-label-mobile-container {
      position: relative;
    }

    .charge-label-mobile {
      position: absolute;
      right: 10px;
      top: 18px;
      color: #767676;
    }

    .leftover-label-mobile {
      color: #767676;
    }

    .charges-table-row {
      background-color: #F9E8D9;
      position: relative;
    }

    .previous-charges-table-row {
      background-color: #E8E8E8;
      position: relative;
    }

    .balance-ribbon-container {
      padding: 0;
    }

    .payments-table-header {
      background-color: #E3F3F3;
    }

    .table-header {
      font-weight: bold;
    }

    .table-header-with-other-columns {
      display: block;
    }

    .pending-payment {
      color: #767676;
    }

    .full-date {
      display: none;

      @media(min-width: 555px) {
        display: inline;
      }
    }

    .word-break-all {
      word-break: break-all;
    }

    .bills-table-date-header {
      width: 18%;

      @media(min-width: 350px) {
        width: 16%;
      }

      @media(min-width: 420px) {
        width: 13%;
      }

      @media(min-width: 555px) {
        width: 13%;
      }
    }

    .bills-table-cost-type-header {
      width: 32%;

      @media(min-width: 350px) {
        width: 47%;
      }

      @media(min-width: 420px) {
        width: 53%;
      }

      @media(min-width: 555px) {
        width: 46%;
      }
    }

    .bills-table-cost-type-price {
      width: 22%;

      @media(min-width: 420px) {
        width: 18%;
      }

      @media(min-width: 555px) {
        width: 10%;
      }
    }

    .bills-table-pending-header {
      width: 21%;


      @media(min-width: 420px) {
        width: 16%;
      }

      @media(min-width: 555px) {
        width: 12%;
      }
    }

    td {
      padding: 10px;
      height: 50px;
    }

    tr {
      border-bottom: 1px solid #CCCCCC;
    }
  }

  .has-pending-amount {
    word-break: break-word;
  }

  .bills-table-mobile-container.bills-table-mobile-container {
    .mobile-balance-label {
      margin-top: 0;
    }
  }

  .bills-table-mobile.bills-table-mobile {
    padding: 0;

    .individual-balance-total {
      padding-right: 10px;
    }
  }
}

.hide-bills-table-mobile-container {
  display: none;
}

.billsTableAndSelectorGroup {
  padding-bottom: 30px;

  .monthPicker {
    display: none;
  }

  .monthPicker {
    width: calc(100% - 20px);
    //margin: 10px 5px;

    @include tablet {
      //margin: 20px 0 10px auto;
      max-width: 370px;
    }
  }

  .table-monthPicker-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    min-height: 30px;
  }

  @include max-tablet {
    .table-monthPicker-container {
      overflow: auto;
    }
  }

  @include small-desktop {

    .table-monthPicker-container {
      position: relative;
      overflow: auto;
      display: block;

      .monthPicker {
        display: block;
        position: absolute;
        top: 0px;
        right: 0;
      }
    }
  }
}

.bank-info-row {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: $color_3;
    margin-bottom: 18px;
    padding: 0;
    max-width: 100vw;

    @include max-tablet {
        margin-bottom: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    .holder {
        width: 100%;
        padding: 0;
        // @include flex-container(map-merge($flex-container-default, ( direction: column-reverse, vertical: center, horizontal: flex-start, wrap: wrap )));
        //@include flex-container(map-merge($flex-container-default, ( direction: row-reverse, vertical: center, horizontal: flex-start, wrap: wrap )));

        .search-container {
            margin-bottom: 0;
            width: 100%;
            display: table;

            @include tablet-landscape {
                margin-bottom: 0;
            }



            .search-box, .filter-modal-box {
                display: table-cell;
                vertical-align: top;
            }

            .search-box {
                width: 100%;
            }

            .filter-modal-box {
                padding-left: 10px;
                display: table-cell;

                @include tablet-landscape {
                    display: none;
                }

                .btn {
                    min-width: 1px;
                    width: auto;
                    white-space: nowrap;
                }
            }
        }

        & > div {
            display: none;
            width: 100%;

            &.flex {
                display: none;
                @include flex(1, 1, 100%);
            }


            &.responsive {
                margin-bottom: 10px;
                display: block;

                @include max-tablet {
                    margin-bottom: 0;
                }

                .bank-details {
                    /*@include tablet {
                        display: none;
                    }*/
                }

                .dropdown-label-mobile {
                    @include max-tablet {
                        min-height: 35px;
                        padding: 7px 0 9px;
                        min-width: 100%;
                    }
                }

                &.action-button {
                    text-align: center;
                    order: 2;
                    display: none;

                    @include tablet {
                        text-align: left;
                        order: unset;
                    }

                    .btn {
                        width: 100%;

                        @include tablet {
                            width: 293px;
                        }
                    }
                }
            }

            &:last-child {
                .form-group {
                    padding-right: 0;
                }
            }
        }

        &[data-responsive="mobile"] {
            @include tablet {
                @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
            }

            @include tablet-landscape {
                padding: 0;
            }

            & > .bank-details {
                @include tablet {
                    width: auto;
                    height: 40px;
                    padding-top: 9px;
                }
            }

            & > div {
                @include tablet {
                    display: block;
                    width: auto;
                }

                &.flex {
                    @include tablet {
                        display: block;
                        @include flex(1, 1, auto);
                    }
                }

                &.responsive {
                    @include tablet {
                        margin-bottom: 0;
                    }
                }
            }

            .form-group {
                @include tablet {
                    padding: 0 10px;
                    @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                }

                .form-field {
                    @include tablet {
                        @include flex2(1);
                    }
                }
            }
        }

        &[data-responsive="tablet"] {
            @include tablet-landscape {
                padding: 0;
                @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
            }

            & > div {
                @include tablet-landscape {
                    display: block;
                    width: auto;
                }

                &.flex {
                    @include tablet-landscape {
                        display: block;
                        @include flex(1, 1, auto);
                    }
                }

                &.responsive {
                    @include tablet-landscape {
                        margin-bottom: 0;
                    }
                }
            }

            .form-group {
                @include tablet-landscape {
                    padding: 0 10px;
                    @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                }

                .form-field {
                    @include tablet-landscape {
                        @include flex2(1);
                    }
                }
            }
        }

        &[data-responsive="desktop"] {
            @include desktop {
                padding: 0;
                @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
            }

            & > div {
                @include desktop {
                    display: block;
                    width: auto;
                }

                &.flex {
                    @include desktop {
                        display: block;
                        @include flex(1, 1, auto);
                    }
                }

                &.responsive {
                    @include desktop {
                        margin-bottom: 0;
                    }
                }
            }

            .form-group {
                @include desktop {
                    padding: 0 10px;
                    @include flex-container(map-merge($flex-container-default, ( direction: row, vertical: center, horizontal: flex-start, wrap: nowrap )));
                }

                .form-field {
                    @include desktop {
                        @include flex2(1);
                    }
                }
            }
        }

        .bank-details {
        }

        .how-to-pay-label {
            width: fit-content;
            font-size: 14px;
            line-height: inherit;
            max-width: 100%;
            white-space: normal;
            padding-left: 22px;

            @include max-tablet {
                width: 100%;
            }

            span {
                width: 100%;
            }
        }
    }
}

.filters-table {
    padding: 0 0 9px 0;

    input, .custom-datepicker, .custom-dropdown {
        width: 100%;
        max-width: 100%;
        min-width: 135px;
        display: block;
    }

    .custom-dropdown {
        @include tablet-landscape {
            min-width: 135px;
        }

        @include desktop {
            min-width: 200px;
        }
    }

    .label {
        /*display: block;*/
        display: none;

        @include desktop {
            margin: 0 10px 0 0;
            display: block;
        }
    }

    .filter-count-button {
        font-size: 14px;
        color: #191919;
        border: 1px solid #95989A;
        padding-left: 22px;
        padding-right: 22px;

        &.active {
            background-color: #F6E3BC;
        }
    }

    .dropdown-label-mobile {

        @include tablet {
            display: none;
        }
    }
}

#myMaintenanceRequestsTable {

    .odd {
        background-color: #FFFFFF;
    }

    .even {
        background-color: #F9F9F9;

        td {
            background-color: transparent;
        }
    }

    td {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .maintenance {
        &-area, &-created-date {
            width: 15%;
            padding-bottom: 10px;
            padding-top: 10px;
        }

        &-status {
            width: 12%;
            padding-bottom: 10px;
            padding-top: 10px;
        }
    }
}

#myConciergeRequestsTable {
    margin-top: 9px;

    th {
        color: #737474;
    }

    .odd {
        background-color: #FFFFFF;
    }

    .even {
        background-color: #F9F9F9;

        td {
            background-color: transparent;
        }
    }

    td {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .concierge {
        &-service, &-created-date {
            width: 15%;
        }

        &-status {
            width: 10%;
        }
    }
}

.table-category {
    font-size: 16px;
    color: #000000;
    font-weight: bold !important;

    @include tablet-landscape{
        font-size: 13px;
    }
}

.desktop-table {
    display: table-cell !important;

    @include max-tablet-landscape {
        display: none !important;
    }
}

.mobile-table {
    display: table-cell !important;
    vertical-align: top;

    @include tablet-landscape {
        display: none !important;
    }

    &.mobile-status {
        text-align: right !important;
    }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vat-details {
  min-width: 70px;
}
