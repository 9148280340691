@import "normalize.scss";
@import "variables.scss";
@import "mixins.scss";
@include generateRatios(16,9); // 16x9
@include generateRatios(16,10);  // 16x10
@include generateRatios(4,3);  // 4x3

@import "typography.scss";

@import "layout.scss";

@import "lib/animate.scss";
/*@import "lib/perfect-scrollbar.scss";*/
@import "lib/iziModal.scss";
@import "lib/datepicker/init.scss";
@import "lib/cropper/cropper.scss";

@import "lib/table/datatables.scss";
@import "lib/tooltipster.bundle.scss";

@import "components/paginations.scss";
@import "components/scroll.scss";
@import "components/forms.scss";
@import "components/iframes.scss";
@import "components/dropdowns.scss";
@import "components/buttons.scss";
@import "components/modals.scss";
@import "components/tables.scss"; 
@import "components/tabs.scss";
@import "components/articles.scss";
@import "components/services.scss";
@import "components/navigation.scss";
@import "components/confirmationdialogs.scss";
@import "components/privacy-page.scss";

@import "base.scss";



