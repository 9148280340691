﻿@import "../variables.scss";
@import "../mixins.scss";

.iziModal {
    /*border-radius: $modal-radius!important;*/
}

.info-modal-container {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.info-modal-content {
    background-color: #FFFFFF;
    margin: 13% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 500px;
    height: 260px;
    border-radius: 8px;

    @include max-tablet {
        width: 330px;
        height: 282px;
        margin: 21% auto;
    }

    .modal-header {
        margin-bottom: 14px;

        .icon-close-modal {
            display: block;
            margin-left: auto;
            width: 23px;
            height: 23px;
            position: relative;
            cursor: pointer;

            &::before {
                content: "\e90c";
                font-family: "icomoon";
                font-size: 34px;
                position: absolute;
                color: #000000;
                right: -7px;
                top: -2px;
            }
        }
    }

    .modal-sub-header {
        margin-bottom: 19px;

        .info-modal-icon {
            display: block;
            margin: auto;
            width: 40px;
            height: 40px;
            position: relative;

            &::before {
                font-family: "icomoon";
                content: "\e913";
                color: #00A9D6;
                font-size: 47px;
                position: absolute;
                right: -4px;
            }
        }
    }

    .modal-body {
        text-align: center;
        padding-left: 52px;
        padding-right: 46px;
        margin-bottom: 30px;
        line-height: 20px;

        @include max-tablet {
            padding: 0 5px;
        }


        #info-modal-content-text {
            font-size: 16px;
        }
    }

    #confirm-info-modal {
        height: 40px;
        width: 180px;
        display: block;
        border-radius: 6px;
        margin: auto;
        border: 0;
        background: #00A9D6;
        color: #FFFFFF;
        font-size: 15px;
    }
}

.custom-modal {
  background: $color_1;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  border-radius: $modal-radius;
  margin: 0 20px;

  .btn {
    font-weight: 300;
  }

  .modal-header {
    padding: 8px 36px 8px 8px;
    position: relative;
    background-color: $color_14;
    border-radius: $modal-radius $modal-radius 0 0;

    @include tablet {
      padding: 12px 36px 12px 16px;
    }

    @include tablet-landscape {
      padding: 16px 66px 16px 33px;
    }

    h2 {
      color: $color_1;
      font-weight: 300;
      line-height: 24px;
      font-size: 20px;

      @include tablet-landscape {
        line-height: 35px;
        font-size: 30px;
      }
    }

    .close-modal {
      -webkit-transition: all .3s ease-in;
      transition: all .3s ease-in;
      color: $color_1;
      position: absolute;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      right: 4px;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 28px;

      @include tablet-landscape {
        right: 33px;
      }

      &:before {
        display: inline-block;
        vertical-align: top;
      }
    }


    .bank-details-header-label {
      display: inline-block;
      float: left;
      font-size: 14px;
      color: #000000 !important;
    }
  }

  .bank-details-header {
    border-bottom: 1px solid #95989A;
    /*padding-bottom: 12px !important;*/
    padding: 12px 18px 12px 19px !important;
  }

  .modal-content {
    padding: 26px 8px 16px 8px;
    color: $color_14;

    @include tablet {
      padding: 26px 16px 16px 16px;
    }

    @include tablet-landscape {
      padding: 26px 21px 16px 21px;
    }
  }

  .modal-row {

    @include tablet-landscape {
      min-height: 92px;
      @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: nowrap, horizontal: flex-start, vertical: center )));
    }
  }

  .modal-footer {
  }

  .modal-actions {
    font-size: 0;
    text-align: right;
    padding: 0 8px 16px 8px;

    @include tablet {
      padding: 0 16px 24px 16px;
    }

    @include tablet-landscape {
      padding: 0 21px 26px 21px;
    }

    > .btn {
      min-width: 1px;
      margin-left: 10px;
      padding: 10px 30px;
      font-size: 12px;

      @include tablet {
        margin-left: 32px;
        min-width: 180px;
        padding: 10px;
        font-size: 15px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .modal-actions-details {
    text-align: center;
    padding: 9px 0px;
    border-top: 1px solid #95989A;

    > .btn-bank-details {
      font-size: 16px;
      min-width: 162px;
      height: 40px;
    }
  }

  .text-holder {
    @include tablet-landscape {
      padding-left: 27px;
    }

    p {
      color: #0C2733;
      font-size: 14px;

      @include tablet-landscape {
        max-width: 85%;
        font-size: 16px;
      }
    }
  }

  &.red-modal {
    .modal-header {
      background-color: $color_21;

      .close-modal {
        &:hover {
          color: #B52E20;
        }
      }
    }
  }

  &.orange-error-modal {
    .modal-header {
      background-color: #F57E4A;

      .close-modal {
        &:hover {
          color: #C45A2C;
        }
      }
    }

    .modal-actions {
      .btn-orange-error {
        display: none !important;
      }

      .cancel-label {
        display: none !important;
      }

      .close-label {
        display: block !important;
      }
    }

    .text-holder {
      p {
        max-width: 100% !important;
      }
    }
  }

  &.aqua-modal {
    .modal-header {
      background-color: $color_19;

      .close-modal {
        &:hover {
          color: #32A29E;
        }
      }
    }
  }

  &.blue-modal {
    .modal-header {
      background-color: $color_12;
    }
  }

  &.brown-modal {
    .modal-header {
      background-color: $color_31;

      .close-modal {
        &:hover {
          color: #885143;
        }
      }
    }
  }

  &.white-modal {
    border-radius: 0;

    .modal-header {
      background-color: $color_1;
      line-height: 18px;
      min-height: 18px;
      padding: 18px 24px 0 24px;
      text-align: right;
      /**/
      h3 {
        color: $color_14;
      }

      .close-modal {
        color: $color_14;
        right: 0;
        top: 0;
        width: 18px;
        height: 18px;
        line-height: 18px;
        display: inline-block;
        vertical-align: top;
        position: static;
        font-size: 18px;
        /*position: relative;
                
                left: 0;
                
                bottom: 0;*/
        /*display: inline-block;*/
        /*vertical-align: top;*/
        &:hover {
          color: $color_14;
        }
      }
    }

    .modal-content {
      padding-top: 0;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 37px;
    }

    .btns-group {
      margin: 0 -5px;
      @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: wrap, horizontal: space-around )));

      > div {
        @include flex2(1);
        margin: 0 5px;
      }

      .btn {
        width: 100%;
        min-width: 1px;
      }
    }
  }

  &.bottom-modal {
    border-radius: 0;
    margin: 0;

    .modal-content {
      /*xmin-height: 24vh;*/
      @include tablet-landscape {
        max-height: 180px;
      }
    }

    .modal-row {
      min-height: calc(24vh - 26px - 16px );
      @include flex-container(map-merge($flex-container-default, ( direction: row, wrap: nowrap, horizontal: space-between, vertical: center )));
    }
  }

  &.map-modal {
    .modal-header {
      background-color: $color_10;

      @include tablet {
        padding: 12px 36px 12px 16px;
      }

      @include tablet-landscape {
        padding: 16px 70px 16px 23px;
      }

      .close-modal {
        font-size: 22px;

        @include tablet-landscape {
          right: 15px;
        }
      }
    }

    .modal-content {
      border-radius: 0 0 $modal-radius $modal-radius;
      padding: 0;

      @include tablet {
        padding: 0;
      }

      @include tablet-landscape {
        padding: 0;
      }
    }

    .servises-map-holde {
      border-radius: 0 0 $modal-radius $modal-radius;
      height: calc(100vh - #{$page-header-mobile-height } - 100px);
      position: relative;
      overflow: hidden;

      @include tablet {
        height: calc(100vh - #{$page-header-tablet-height} - 300px);
      }

      @include tablet-landscape {
        height: 528px;
        width: 100%;
      }
    }
  }

  &.filters-modal {
    max-width: 340px;
    margin: 0 auto 100px auto;

    & .custom-form {
      padding: 0;

      .form-wrap {
        padding-top: 0;
      }
    }

    & .modal-content {
      padding: 20px 18px 30px 18px;
    }

    .grid {

      @include tablet {
        margin-right: -7px;
        margin-left: -7px;
      }

      .grid-item {
        padding-bottom: 7px;

        @include tablet {
          width: 50%;
          padding-right: 7px;
          padding-left: 7px;
        }
      }
    }
  }

  &.bank-details-modal {
    border: 1px solid #95989A;
    border-radius: 4px;
  }

  & .custom-dropdown {
    min-width: 1px;
    width: 100%;
  }

  .clear-filters {
    margin-top: 20px;
  }


  .bank-details-content {
    overflow: auto;
    font-size: 13px;
  }

  .bank-account-item:last-child {
    border-bottom: none;
  }

  .bank-account-item {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid #CCCCCC;
    user-select: text;

    .bank-account-BankName {
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .bank-account-Iban {
      & > label {
        font-weight: 600;
      }
    }

    .bank-account-Details {
      overflow: hidden;
      text-overflow: ellipsis;

      & > label {
        font-weight: 600;
      }
    }
  }
}

.bank-details-modal-content {
    padding: 0 19px !important;
    color: #0C2733;
}

.mobile-bank-details {

    .close-modal:before {
        color: #928D8D;
    }

    @include tablet-landscape {
        display: none;
    }
}

.desktop-bank-details {
    @include max-tablet-landscape {
        display: none;
    }

    width: 100%;

    .bank-details-content-holder {
        display: flex;
        justify-content: center;

        .bank-details-inner {
            background-color: #FFFFFF;
            border: 1px solid #95989a;
            border-radius: 4px;
            max-width: 960px;

            .bank-details-header {
                display: flex;
                justify-content: space-between;
                padding: 12px 18px 9px 29px;
                border-bottom: 1px solid #95989A;

                &-text {
                    font-size: 16px;
                    display: inline-block;
                }

                .close-modal {
                    color: #928D8D;
                    font-size: 20px;
                    display: inline-block;
                }
            }

            .bank-details-content {
                display: grid;
                grid-template-columns: 320px 320px 320px;
                row-gap: 45px;
                padding: 18px 0;
                color: #0C2733;
                font-size: 16px;
                line-height: 20px;
                padding-bottom: 25px;

                .bank-account {
                    width: 320px;
                    max-width: 320px;
                    padding-left: 29px;
                    border-right: 1px solid #CCCCCC;

                    &-bank-name {
                        font-weight: 600;
                        word-break: break-all;
                    }

                    &-bank-account-iban, &-details {
                        word-break: break-all;

                        &-label {
                            font-weight: 600;
                        }
                    }

                    &-details {
                        height: 95px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:nth-child(3n+3) {
                        border-right: 0;
                    }
                }
            }
        }
    }
}
