﻿@import "../variables.scss";
@import "../mixins.scss";


@import "../lib/scroll/scroll-variables.scss";
@import "../lib/scroll/scroll-mixins.scss";
@import "../lib/scroll/scroll-themes.scss";


.ps {
  @include ps-container(map-merge($ps-theme-default, (
    border-radius: 9px,
    scrollbar-y-rail-width: 9px,
    scrollbar-y-width: 9px,
    scrollbar-y-hover-width: 9px,
  )));
}

.custom-scroll{
    > .ps__scrollbar-y-rail{
        right: 0!important;
        > .ps__scrollbar-y{
            right: 0!important;
        }
    }
}