$font-version: 3;

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/icomoon.eot?v=#{$font-version}');
    src: url('../fonts/icomoon/icomoon.eot?v=#{$font-version}') format('embedded-opentype'), url('../fonts/icomoon/icomoon.ttf?v=#{$font-version}') format('truetype'), url('../fonts/icomoon/icomoon.woff?v=#{$font-version}') format('woff'), url('../fonts/icomoon/icomoon.svg?v=#{$font-version}#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ArrowBack-White:before {
    content: "\e911";
}

.icon-Share:before {
    content: "\e93e";
}

.icon-Language-LeftNav:before {
    content: "\e953";
}

.icon-ServiceDesk-LeftNav:before {
    content: "\e954";
}

.icon-Reports-LeftNav:before {
    content: "\e955";
}

.icon-Portal-LeftNav:before {
    content: "\e956";
}

.icon-History-LeftNav:before {
    content: "\e957";
}

.icon-FMC-LeftNav .path1:before {
    content: "\e958";
    color: rgb(12, 39, 51);
}

.icon-FMC-LeftNav .path2:before {
    content: "\e959";
    margin-left: -1em;
    color: rgb(15, 180, 225);
}

.icon-FMC-LeftNav .path3:before {
    content: "\e95a";
    margin-left: -1em;
    color: rgb(12, 39, 51);
}

.icon-FMC-LeftNav .path4:before {
    content: "\e95b";
    margin-left: -1em;
    color: rgb(12, 39, 51);
}

.icon-FMC-LeftNav .path5:before {
    content: "\e95c";
    margin-left: -1em;
    color: rgb(180, 230, 240);
}

.icon-FMC-LeftNav .path6:before {
    content: "\e95d";
    margin-left: -1em;
    color: rgb(180, 230, 240);
}

.icon-Distribution-LeftNav:before {
    content: "\e95e";
}

.icon-Clients-LeftNav:before {
    content: "\e95f";
}

.icon-Contracts_icn::before {
    content: "\e960";
}

.icon-ClientManagement:before {
    content: "\e952";
}

.icon-Bank:before {
    content: "\e951";
}

.icon-servicedesk:before {
    content: "\e94f";
}

.icon-clients:before {
    content: "\e950";
}

.icon-MobileListView:before {
    content: "\e94e";
}

.icon-Dollar:before {
    content: "\e94c";
}

.icon-CardPlus:before {
    content: "\e94b";
}

.icon-HomeStroke:before {
    content: "\e94a";
}

.icon-Grid:before {
    content: "\e946";
}

.icon-Home:before {
    content: "\e947";
}

.icon-Services:before {
    content: "\e948";
}

.icon-Signals:before {
    content: "\e949";
}

.icon-Drag-ign:before {
    content: "\e944";
}

.icon-History:before {
    content: "\e943";
}

.icon-Clear-Filter .path1:before {
    content: "\e941";
    color: rgb(141, 163, 169);
}

.icon-Clear-Filter .path2:before {
    content: "\e942";
    margin-left: -1em;
    color: rgb(185, 0, 0);
}

.icon-ChargesArrows:before {
    content: "\e93b";
}

.icon-ChargesSolid:before {
    content: "\e93c";
}

.icon-ChargesStroke:before {
    content: "\e93d";
}

.icon-Published .path1:before {
    content: "\e937";
    color: rgb(63, 200, 172);
}

.icon-Published .path2:before {
    content: "\e938";
    margin-left: -1em;
    color: rgb(123, 236, 213);
}

.icon-Filter-stroke:before {
    content: "\e931";
}

.icon-Filter:before {
    content: "\e932";
}

.icon-Excel .path1:before {
    content: "\e92a";
    color: rgb(61, 179, 158);
}

.icon-Excel .path2:before {
    content: "\e92f";
    margin-left: -1em;
    color: rgb(47, 138, 120);
}

.icon-Excel .path3:before {
    content: "\e930";
    margin-left: -1em;
    color: rgb(186, 232, 224);
}

.icon-Printer:before {
    content: "\e923";
}

.icon-Arrow-Diagram:before {
    content: "\e924";
}

.icon-User-Warning .path1:before {
    content: "\e925";
    color: rgb(249, 203, 47);
}

.icon-User-Warning .path2:before {
    content: "\e93f";
    margin-left: -1em;
    color: rgb(35, 31, 32);
}

.icon-User-Warning .path3:before {
    content: "\e940";
    margin-left: -1em;
    color: rgb(35, 31, 32);
}

.icon-X:before {
    content: "\e926";
}

.icon-Star:before {
    content: "\e927";
}

.icon-Ad-Property:before {
    content: "\e928";
}

.icon-Validation-Icon:before {
    content: "\e929";
}

.icon-Warning-Dialog:before {
    content: "\e92b";
}

.icon-Question-Dialog:before {
    content: "\e92c";
}

.icon-NoAccess:before {
    content: "\e939";
}

.icon-Access:before {
    content: "\e93a";
}

.icon-Sort-down:before {
    content: "\e92d";
}

.icon-Sort-up:before {
    content: "\e92e";
}

.icon-No-data:before {
    content: "\e901";
}

.icon-Person:before {
    content: "\e933";
}

.icon-Company:before {
    content: "\e934";
}

.icon-Apartment:before {
    content: "\e935";
}

.icon-Dots:before {
    content: "\e936";
}

.icon-creditcard:before {
    content: "\e922";
}

.icon-EyeOpen:before {
    content: "\e91f";
}

.icon-EyeClosed:before {
    content: "\e920";
}

.icon-Settings:before {
    content: "\e91d";
}

.icon-Building:before {
    content: "\e91e";
}

.icon-NavigationIcon:before {
    content: "\e905";
}

.icon-Calendar:before {
    content: "\e900";
}

.icon-LogOut:before {
    content: "\e902";
}

.icon-Pass:before {
    content: "\e903";
}

.icon-User:before {
    content: "\e904";
}

.icon-Error-Dialog:before {
    content: "\e921";
}

.icon-Share:before {
    content: "\e93e";
}

.icon-Activate3:before {
    content: "\e91b";
}

.icon-Activate2:before {
    content: "\e91c";
}

.icon-Deactivate:before {
    content: "\e919";
}

.icon-Activate:before {
    content: "\e91a";
}

.icon-clip:before {
    content: "\e918";
}

.icon-MapPinIcn:before {
    content: "\e917";
}

.icon-Info:before {
    content: "\e913";
}

.icon-ListView:before {
    content: "\e914";
}

.icon-MapView:before {
    content: "\e915";
}

.icon-Search:before {
    content: "\e916";
}

.icon-More:before {
    content: "\e912";
}

.icon-ArrowBack:before {
    content: "\e911";
}

.icon-Notification:before {
    content: "\e906";
}

.icon-InsertImage:before {
    content: "\e907";
}

.icon-Edit:before {
    content: "\e908";
}

.icon-DropdownArrow:before {
    content: "\e909";
}

.icon-Delete:before {
    content: "\e90b";
}

.icon-Close:before {
    content: "\e90c";
}

.icon-ArrowDoubleLeft:before {
    content: "\e90d";
}

.icon-ArrowDoubleRight:before {
    content: "\e90e";
}

.icon-ArrowNext:before {
    content: "\e90f";
}

.icon-ArrowPrev:before {
    content: "\e910";
}

.icon-check:before {
    content: "\e90a";
}

.icon-credit-card:before {
    content: "\e945";
}

.icon-bin:before {
    content: "\e9ac";
}

.icon-coin-dollar:before {
    content: "\e94d";
}
