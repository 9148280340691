﻿@import "../variables.scss";
@import "../mixins.scss";

.custom-tabs{
    .tab{
        display: none;

        &.active{
            display: block;
        }
    }
}

.tabs-nav{
    line-height: 22px;
    padding: 14px 0;
    text-align: center;
    border-bottom: 1px solid $color_14;
    a{
        font-size: 13px;
        padding: 0 20px;
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin: 0 29px;
        color: $color_10;
        &.active, &:hover{
            color: $color_12;
        }
    }
}

.tabs-content{

}
