@mixin scrollbar-rail-default($theme) {
  display: none;
  position: absolute; /* please don't change 'position' */
  opacity: map_get($theme, rail-default-opacity);
  transition: background-color .2s linear, opacity .2s linear;
}

@mixin scrollbar-rail-hover($theme) {
  background-color: map_get($theme, rail-hover-bg);
  opacity: map_get($theme, rail-hover-opacity);
}

@mixin scrollbar-default($theme) {
  position: absolute; /* please don't change 'position' */
  background-color: map_get($theme, bar-container-hover-bg);
  border-radius: map_get($theme, border-radius);
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out,
              border-radius .2s ease-in-out;
}

@mixin scrollbar-hover($theme) {
  background-color: map_get($theme, bar-hover-bg);
}

@mixin in-scrolling($theme) {
  &.ps--in-scrolling {
    &.ps--x > .ps__scrollbar-x-rail {
      @include scrollbar-rail-hover($theme);
      > .ps__scrollbar-x {
        @include scrollbar-hover($theme);
        height: map_get($theme, scrollbar-x-hover-height);
      }
    }
    &.ps--y > .ps__scrollbar-y-rail {
      @include scrollbar-rail-hover($theme);
      > .ps__scrollbar-y {
        @include scrollbar-hover($theme);
        width: map_get($theme, scrollbar-y-hover-width);
      }
    }
  }
}

// Layout and theme mixin
@mixin ps-container($theme) {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;

  // Edge
  @supports (-ms-overflow-style: none) {
    overflow: auto !important;
  }
  // IE10+
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    overflow: auto !important;
  }

  &.ps--active-x > .ps__scrollbar-x-rail,
  &.ps--active-y > .ps__scrollbar-y-rail {
    display: block;
    background-color: map_get($theme, bar-bg);
  }

  @include in-scrolling($theme);

  > .ps__scrollbar-x-rail {
    @include scrollbar-rail-default($theme);
    bottom: map_get($theme, scrollbar-x-rail-bottom); /* there must be 'bottom' for ps__scrollbar-x-rail */
    height: map_get($theme, scrollbar-x-rail-height);

    > .ps__scrollbar-x {
      @include scrollbar-default($theme);
      bottom: map_get($theme, scrollbar-x-bottom); /* there must be 'bottom' for ps__scrollbar-x */
      height: map_get($theme, scrollbar-x-height);
    }
    &:hover,
    &:active {
      > .ps__scrollbar-x {
        height: map_get($theme, scrollbar-x-hover-height);
      }
    }
  }

  > .ps__scrollbar-y-rail {
    @include scrollbar-rail-default($theme);
    right: map_get($theme, scrollbar-y-rail-right); /* there must be 'right' for ps__scrollbar-y-rail */
    width: map_get($theme, scrollbar-y-rail-width);

    > .ps__scrollbar-y {
      @include scrollbar-default($theme);
      right: map_get($theme, scrollbar-y-right); /* there must be 'right' for ps__scrollbar-y */
      width: map_get($theme, scrollbar-y-width);
    }
    &:hover,
    &:active {
      > .ps__scrollbar-y {
        width: map_get($theme, scrollbar-y-hover-width);
      }
    }
  }

  &:hover {
    @include in-scrolling($theme);

    > .ps__scrollbar-x-rail,
    > .ps__scrollbar-y-rail {
      opacity: map_get($theme, rail-container-hover-opacity);
    }

    > .ps__scrollbar-x-rail:hover {
      @include scrollbar-rail-hover($theme);

      > .ps__scrollbar-x {
        @include scrollbar-hover($theme);
      }
    }

    > .ps__scrollbar-y-rail:hover {
      @include scrollbar-rail-hover($theme);

      > .ps__scrollbar-y {
        @include scrollbar-hover($theme);
      }
    }
  }
}
