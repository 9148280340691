﻿@import "../variables.scss";
@import "../mixins.scss";


.confirmationdialogs-background{
    width: 100%;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    background-color: rgba(1, 53, 67, 0.8);
    z-index: -1;
}

#confirmationdialogs-link {
  color: #0fb4e1;
}

#confirmationdialogs-custom-padding {
    padding: 15px 15px 15px 15px;
    line-height: 20px;
}

.confirmationdialogs-buttons{
   margin-bottom: 11px;
   width: 100%;

}

#confirmationdialogs-label-custom-font-size{
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 100%;
}

.confirmationdialogs-label-custom-font-size-note{
    font-size: 11px;
    width: 50%;
}


@include tablet {
    .confirmationdialogs-border {
         height: 407px;
         width: 590px;
         border: 2px solid #0FB4E1;
         border-radius: 8px;
         padding: 42px 94px 27px 96px;
         background-color: rgba(1, 53, 67, 0.4);
         transform: translate(-45px);
    }

    .confirmationdialogs-border > div {
        width: 400px;
    }
 
    .confirmationdialogs-buttons{
    width: 190px;
    }

    #confirmationdialogs-label-custom-font-size{
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 100%;
}

    .confirmationdialogs-label-custom-font-size-note{
    font-size: 13px;
    width: 50%;
}
    .confirmationdialogs-background{
        display: none;
    }
}

@include mobile-landscape{
     #custom-padding {
        padding-right: 20px;
    }
}

.confirmationdialogs-buttons-icon-info{
    color: $color_33;
    display: inline-block;
    vertical-align: top;
}


