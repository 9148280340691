﻿@use 'sass:math';
@import "datepicker-config";

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */

.datepickers-container {
  position: absolute;
  left: 0;
  top: 0;

  @media print {
    display: none;
  }
}

.datepicker {
  background: #fff;
  border: 1px solid map_get($datepickerBorderColor, default);
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  border-radius: $datepickerBorderRadius;
  box-sizing: content-box;
  font-family: $datepickerFontFamily, sans-serif;
  font-size: $datepickerFontSize;
  color: map_get($datepickerTextColor, common);
  width: $datepickerWidth;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity $datepickerTransitionSpeed $datepickerTransitionEase, transform $datepickerTransitionSpeed $datepickerTransitionEase, left 0s $datepickerTransitionSpeed;
  z-index: $datepickerZIndex;

  &.-from-top- {
    transform: translateY(-$datepickerTransitionOffset);
  }
  &.-from-right- {
    transform: translateX($datepickerTransitionOffset);
  }
  &.-from-bottom- {
    transform: translateY($datepickerTransitionOffset);
  }
  &.-from-left- {
    transform: translateX(-$datepickerTransitionOffset);
  }


  &.active {
    opacity: 1;
    transform: translate(0);
    transition: opacity $datepickerTransitionSpeed $datepickerTransitionEase, transform $datepickerTransitionSpeed $datepickerTransitionEase, left 0s 0s;
  }
}

.datepicker-inline {
  .datepicker {
    border-color: map-get($datepickerBorderColor, inline);
    box-shadow: none;
    position: static;
    left: auto;
    right: auto;
    opacity: 1;
    transform: none;
  }

  .datepicker--pointer {
    display: none;
  }
}

.datepicker--content {
  box-sizing: content-box;
  padding: $datepickerPadding;

  .-only-timepicker- & {
    display: none;
  }
}

//  Pointer
// -------------------------------------------------
$pointerHalfSize: math.div($datepickerPointerSize, 2) - 1;

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid map-get($datepickerBorderColor, default);
  border-right: 1px solid map-get($datepickerBorderColor, default);
  width: $datepickerPointerSize;
  height: $datepickerPointerSize;
  z-index: -1;

  //  Main axis
  // -------------------------

  .-top-left- &, .-top-center- &, .-top-right- & {
    top: calc(100% - #{$pointerHalfSize});
    transform: rotate(135deg);
  }

  .-right-top- &, .-right-center- &, .-right-bottom- & {
    right: calc(100% - #{$pointerHalfSize});
    transform: rotate(225deg);
  }

  .-bottom-left- &, .-bottom-center- &, .-bottom-right- & {
    bottom: calc(100% - #{$pointerHalfSize});
    transform: rotate(315deg);
  }

  .-left-top- &, .-left-center- &, .-left-bottom- & {
    left: calc(100% - #{$pointerHalfSize});
    transform: rotate(45deg);
  }

  //  Secondary axis
  // -------------------------

  .-top-left- &, .-bottom-left- & {
    left: $datepickerPointerOffset;
  }
  .-top-right- &, .-bottom-right- & {
    right: $datepickerPointerOffset;
  }
  .-top-center- &, .-bottom-center- & {
    $pointerHalfSize: math.div($datepickerPointerSize, 2) - 1;
  }

  .-left-top- &, .-right-top- & {
    top: $datepickerPointerOffset;
  }
  .-left-bottom- &, .-right-bottom- & {
    bottom: $datepickerPointerOffset;
  }
  .-left-center- &, .-right-center- & {
    top: calc(50% - #{$datepickerPointerSize} / 2);
  }

}

//  Body
// -------------------------------------------------

.datepicker--body {
  display: none;

  &.active {
    display: block;
  }
}