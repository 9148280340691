﻿@import "../variables.scss";
@import "../mixins.scss";

.custom-table{
     .pagination {
        display: inline-block;
        vertical-align: top;

        @include tablet {
            float: right;
        }
         
        li{
            float: left;

            span{
                font-size: 0;
                
                &:before{
                    font-family: 'icomoon';
                    font-size: 12px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    margin: auto;

                }
            }

            &:first-child{
                span{
                    &:before{
                        content: "\e910";
                    }
                }
            }

            &:last-child{
                span{
                    &:before{
                        content: "\e90f";
                    }
                }
            }

            &.disabled{
                a{
                    color: $color_6;
                }
            }

            &.active{
                a{
                    background-color: $color_12;
                    color: $color_1;
                    border: 1px solid $color_12;
                }
            }
        }

        a{
            font-size: 15px;
            color: $color_12;
            width: 30px;
            height: 30px;
            line-height: 28px;
            border: 1px solid $color_6;
            text-align: center;
            margin-left: 6px;

            position: relative;
            display: block;
        }
    }
}

.pagination-container{
    padding: 20px 0;
    width: 100%;
    text-align: center;

    @include tablet-landscape {
        text-align:right;
        margin-top:30px;
    }

     a{
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;

        font-size: 15px;
        color: $color_12;
        width: 30px;
        height: 30px;
        line-height: 28px;
        border: 1px solid $color_6;
        text-align: center;
        margin-left: 6px;

        position: relative;
        display: block;

        > span{
            display: block;
            height: 100%;
            line-height: 28px;
            font-size: 12px;
        }
        @media (hover: hover){
            &:hover{
                background-color: $color_12;
                color: $color_1;
                border: 1px solid $color_12;
            }
        }
    }

    

    ul{
        list-style: none;
        display: inline-block;
        vertical-align: top;
        @include clearfix();
    }

    li {
        float: left;
        &.active{
            span, a {
                background-color: $color_12;
                color: $color_1;
                border: 1px solid $color_12;
                cursor: text;
            }
        }

        &.disabled{

        }

        &.PagedList-skipToFirst{

        }

        &.PagedList-skipToPrevious{

        }

        &.PagedList-skipToNext{

        }

        &.PagedList-skipToLast{

        }

        > span {
            font-size: 15px;
            color: $color_12;
            width: 30px;
            height: 30px;
            line-height: 28px;
            border: 1px solid $color_6;
            text-align: center;
            margin-left: 6px;

            position: relative;
            display: block;
        }
      
    }

    
}

#monthPicker,
#monthPickerMobile {
  height: 30px;
  font-size: 14px;
  width: 100%;

  #monthLine,
  #monthLineMobile {
    margin: 0 5px;
    width: calc(100% - 70px);
    height: 100%;
    display: inline-block;
    vertical-align: top;
    float: left;
  }

  .monthPickerPrevious,
  .monthPickerNext,
  #monthLine div,
  #monthLineMobile div {
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0;
    background: #fff;
    cursor: pointer;
    border: 1px solid #B9C2C5;
    vertical-align: top;
    height: 100%;
    display: inline-block;
    color: #00A9D6;
    text-align: center;
    float: left;
  }

  .monthLineOneMonth {
    width: 100%
  }

  .monthLineTwoMonths {
    width: calc( (100% - 5px) / 2 );
    margin-right: 5px;
  }

  .monthLineThreeMonths {
    width: calc( (100% - 10px) / 3 );
    margin-right: 5px;
  }

  #monthLine div:last-child,
  #monthLineMobile div:last-child {
    margin-right: 0;
  }

  .monthPickerNext,
  .monthPickerPrevious {
    width: 30px;
    color: #00A9D6;
    font-family: "icomoon";
    font-size: 15px;
  }

  .monthPickerPrevious:hover,
  .monthPickerNext:hover,
  #monthLine div:hover,
  #monthLineMobile div:hover {
    color: #ffffff;
    background: #00A9D6;
    border: 1px solid #00A9D6;
  }

  .monthPickerNext:disabled,
  .monthPickerPrevious:disabled {
    color: #B9C2C5;
    border: 1px solid #B9C2C5;
    background: #ffffff;
    cursor: default;
  }

  .monthPickerNext:before {
    content: "\e90f";
    vertical-align: baseline;
  }

  .monthPickerPrevious:before {
    content: "\e910";
    vertical-align: baseline;
  }

  #monthLine .selectedMonth,
  #monthLineMobile .selectedMonth {
    color: #ffffff;
    background: #00A9D6;
    border: 1px solid #00A9D6;
  }
}