﻿@import "../variables.scss";
@import "../mixins.scss";

/* Default forms */
.custom-form {
    padding: 0 10px;

    form {
        display: block;
    }

    &.form-sm {
        @include form-container(map-merge($form-container-default, ( max-width: 350px )));

        .form-actions {
            @include mobile-landscape {
                @include flex-container(map-merge($flex-container-default, ( direction: row, horizontal: space-between, vertical: center )));
            }

            .btn {
                &:first-child {
                    margin-top: 0;
                }

                @include mobile-landscape {
                    margin-left: 0;
                    min-width: 1px;
                    width: calc(50% - 15px);

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &.form-sm-460 {
        @include form-container(map-merge($form-container-default, ( max-width: 482px )));

        .form-wrap {
            max-width: 256px;
        }

        .custom-dropdown {
            min-width: 100%;

            @include tablet {
                min-width: 1px;
                width: 100%;
            }
        }        

        P {
            margin: 13px 0;
        }
    }

    &.form-md {
        @include form-container(map-merge($form-container-default, ( max-width: 600px )));
    }

    &.form-mdl {
        @include form-container(map-merge($form-container-default, ( max-width: 742px )));
        /*.img-box{
           
            @include tablet-landscape {
                width: 400px;
                max-width: 360px;
                padding-right: 32px;
            }
        }

        .field-box{
            @include tablet-landscape {
                width: 100%;
                max-width: calc(100% - 360px);
            }
        }*/
        .form-actions {
            text-align: right;

            @include mobile-landscape {
                @include flex-container(map-merge($flex-container-default, ( direction: row, horizontal: flex-end, vertical: center )));
            }

            .btn {
                &:first-child {
                    margin-top: 0;
                }

                @include mobile-landscape {
                    margin-left: 0;
                    min-width: 1px;
                    width: calc(50% - 15px);
                    margin: 0 15px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &.form-lg {
        @include form-container(map-merge($form-container-default, ( max-width: 866px )));
    }

    .img-box {
        width: 100%;
        max-width: 100%;
    }

    .form-wrap {
        padding-top: 20px;
        padding-bottom: 20px;

        @include tablet {
            padding-top: 30px;
            padding-bottom: 50px;
        }
    }

    .label {
        font-size: 14px;
        line-height: 18px;
        display: inline-block;
        color: $color_3;
        vertical-align: top;
        margin-bottom: 5px;

        &.bold {
            font-weight: bold;
        }
        /*&:after{
            content: ":";
        }*/
    }

    input[type="text"],
    input[type="search"],
    input[type="email"],
    input[type="number"],
    input[type="phone"],
    input[type="password"],
    textarea, .fake-field {
        color: $color_3;
        font-size: 13px;
        line-height: 18px;
        padding: 10px 16px 10px 16px;
        display: block;
        background-color: $color_1;
        border: 1px solid $color_7;
        border-radius: $btn-radius;
        width: 100%;
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;
        outline: none;

        &:hover, &:focus {
            background-color: $color_9;
            border: 1px solid $color_12;
        }

        &.input-validation-error {
            border: 1px solid $color_21;
        }

        &[readonly] {
            background-color: $color_4;
            border: 1px solid $color_4;

            &:hover, &:focus {
                background-color: $color_4;
                border: 1px solid $color_4;
            }
        }
    }

    textarea {
        height: 167px;
        resize: none;

        @include tablet {
            height: 147px;
        }

        &.sm-field {
            height: 90px;
        }
    }

    .mce-panel {
        border-radius: $btn-radius;
        box-sizing: border-box;
    }

    .form-fieldset {
        padding-bottom: 40px;

        h4 {
            border-bottom: 1px dashed;
            padding: 30px 0 10px;
            margin-bottom: 6px;
        }

        .form-group {

            @include tablet {
                padding: 0 15px;
            }
        }
    }



    .form-group {
        display: block;
        position: relative;
        margin-top: 25px;
        @include clearfix();

        &:first-child {
            margin-top: 0;
        }
    }

    .form-field {
        display: block;
        position: relative;

        @include clearfix();
    }

    .inline-field {
        font-size: 16px;
        @include clearfix();

        input[type="text"],
        input[type="search"],
        input[type="email"],
        input[type="number"],
        input[type="phone"],
        input[type="password"] {
            margin: 0 8px;
            display: inline-block;
            vertical-align: middle;
            width: 74px;
            min-width: 74px;
        }


        .custom-checkbox {
            margin-top: 7px;
        }

        .btn {
            float: right;
        }
    }

    .form-actions {
        padding: 30px 0;

        @include mobile-landscape {
            margin: 0;
            @include flex-container(map-merge($flex-container-default, ( direction: row, horizontal: flex-end, vertical: center )));

            & .flex {
                @include flex();
            }
        }

        > div {
            white-space: nowrap;
            font-size: 0;

            &:last-child {
                text-align: right;
            }

            &:first-child {
                .btn {
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        .link {
            display: block;
            text-align: center;
            width: 100%;

            @include mobile-landscape {
                margin: 0;
                width: auto;
                /*margin: 0 10px;*/
                display: inline-block;
            }

            @include tablet {
            }
        }

        .btn {
            border-radius: 4px;
            width: 100%;
            min-width: 1px;
            margin-top: 15px;
            display: block;

            @include mobile-landscape {
                margin-top: 0;
                width: auto;
                /*margin: 0 10px;*/
                display: inline-block;
            }

            @include mobile-landscape {
                margin-left: 30px;
                min-width: 180px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .form-actions-center {
        text-align: center;
    }

    .custom-dropdown {
        min-width: 100%;

        @include tablet {
            min-width: 330px;
        }
    }

    .radio-group,
    .checkbox-group {
        .custom-radio,
        .custom-checkbox {
            margin-bottom: 10px;
            display: block;

            @include tablet-landscape {
                display: inline-block;
                margin-right: 25px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.custom-datepicker{
    position: relative;
    &.inline{
        display: inline-block;
        vertical-align: top;
    }

    input{
        position: relative;
        z-index: 3;
        padding-right: 48px!important;

        @include mobile-landscape{
             max-width: 100%;
        }

        &:focus + .trigger-datepicker{
            color: $color_12;
        }
    }


    .trigger-datepicker{
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        z-index: 4;
        margin: auto;
        height: 30px;
        line-height: 30px;
        width: 30px;
        color: $color_5;
        font-size: 28px;
        cursor: pointer;
    }
}

/* Plugin */
.datepicker{
    border-radius: 2px;

    @include max-mobile-landscape {
        font-size: 16px;
    }
    &--pointer{
        display: none!important;
    }

    &--nav{
        border: none;
    }

    &--cells-months{
        @include max-mobile-landscape {
            height: 30vh;
        }
    }
}

.simple-file-upload{
    position: relative;
    z-index: 1;
    overflow: hidden;

    & .upload-link{
        cursor: pointer;
        display: block;
        line-height: 18px;
        font-size: 14px;
        color: $color_12;
        margin-top: 10px;
        &:before{
            font-family: 'icomoon' !important;
            content: "\e918";
            font-size: 24px;
            display: inline-block;
            vertical-align: top;
            line-height: 16px;
            margin-left: 0;
            margin-right: 10px;
            color: #191919;
        }
    }

    [type=file] {
        position: absolute;
        z-index: 3;
        bottom: 0;
        right: 0;
        width: 1000%;
        height: 1000%;
        outline: none;
        display: inline-block;
        overflow: visible;
        background-color: transparent;
        cursor: pointer;

        &::-ms-value{
            background-color: red;
            position: absolute;
            z-index: 4;
            cursor: pointer;
            /*font-size: 0;*/
            border: none;
            width: 0;
            padding: 0;
            left: 0;
            top: 0;

        }

        &::-ms-browse {
            position: absolute;
            z-index: 5;
            background-color: transparent;
            cursor: pointer;
            border: none;
            padding: 50%;
        }
    }

     .file-name-preview{
        padding: 4px 0;
        font-size: 12px;
        span{
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.custom-file-upload {
    position: relative;
    z-index: 1;
    overflow: hidden;
    & .upload-btn {
        display: inline-block;
        vertical-align: top;
        width: 36px;
        height: 36px;
        line-height: 36px;
        z-index: 2;
        position: relative;
        font-size: 36px;
        color: $color_10;

        &:before{
            font-family: "icomoon";
            content: "\e907";
            
            display: inline-block;
            vertical-align: top;

        }
    }

    & .upload-link{
        cursor: pointer;
        display: block;
        line-height: 18px;
        font-size: 14px;
        color: $color_12;
        margin-top: 10px;
    }

    &:hover {
        & .upload-btn {
            color: $color_12;
        }
    }

    & .upload-input {
        position: absolute;
        z-index: 3;
        bottom: 0;
        right: 0;
        width: 1000%;
        height: 1000%;
        outline: none;
        display: inline-block;
        overflow: visible;
        //background: red;
        background-color: transparent;
        cursor: pointer;

        &::-ms-value{
            background-color: red;
            position: absolute;
            z-index: 4;
            cursor: pointer;
            /*font-size: 0;*/
            border: none;
            width: 0;
            padding: 0;
            left: 0;
            top: 0;

        }

        &::-ms-browse {
            position: absolute;
            z-index: 5;
            background-color: transparent;
            cursor: pointer;
            border: none;
            padding: 50%;
        }
    }

   
}

.cell-upload{
    margin-bottom: 20px;
    @include tablet-landscape{
        text-align: right;
        padding-right: 46px;
        margin-bottom: 0;
    }
    .custom-file-upload{
        text-align: left;
        vertical-align: top;
        
        @include tablet-landscape{
            display: inline-block;
        }
    }

    .custom-file-upload{

    }
}

.custom-file-preview{
    width: 100%;
    overflow: hidden;
    display: block;
    text-align: center;

    img {
        border: 1px solid $color_7;
        border-radius: $btn-radius;
        width: auto;
        height: auto;
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
    }

    &.upload-btn-preview{
        img{
            border-radius: 0;
            border: none;
            /*max-width: 100px;*/
        }
        
    }
}

$checkbox-size: 26px;
$checkbox-tick-size: 20px;
.custom-checkbox {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    min-height: $checkbox-size;
    
    cursor: pointer;

    > span {
        display: inline-block;
        vertical-align: top;
        
        position: relative;
        z-index: 5;

        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        min-height: $checkbox-size;

        font-size: 14px;
        line-height: 18px;
        padding-left: calc(#{$checkbox-size} + 10px);
        padding-top: calc((#{$checkbox-size} - 18px)/2);
        padding-bottom: calc((#{$checkbox-size} - 18px)/2);

        &:before, &:after {
            box-sizing: border-box;
            width: $checkbox-size;
            height: $checkbox-size;

            position: absolute;
            z-index: 6;
            top: 0;
            left: 0;
        }
            
        &:before {
            content: "";
            border: 1px solid $color_24;
            background-color: $color_1;
            -webkit-transition: all .2s ease-in;
                    transition: all .2s ease-in;
        }

        &:after{
            font-family: "icomoon";
            content: "\e90a";
            background-color: transparent;
            font-size: $checkbox-tick-size;
            text-align: center;
            line-height: $checkbox-size;
            color: $color_1;
            z-index: 7;

            -webkit-transition: all .2s ease-in;
                    transition: all .2s ease-in;

            -webkit-transform: rotate(360deg) scale(0);
                    transform: rotate(360deg) scale(0);
        }
    }

    [type="checkbox"] {
        position: absolute;
        width: calc(#{$checkbox-size} - 4px);
        height: calc(#{$checkbox-size} - 4px);
        left: 2px;
        top: 2px;
        margin: 0;
        padding: 0;
        z-index: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; 
    }


    &:hover {

        > span {
            &:before {
                border: 1px solid $color_12;
                background-color: $color_9;
            }

            &:after {
                color: $color_9;
            }
        }

        &.checked {
            span {
                &:before{
                    border: 1px solid $color_12;
                    background-color: $color_9;
                }

                &:after{
                    color: $color_12;
                }
            }

        }
        
    }

    &.checked{
        span {
            &:before{
                border: 1px solid $color_12;
                background-color: $color_9;
            }
            &:after{
                 color: $color_12;
                -webkit-transform: rotate(0deg) scale(1);
                        transform: rotate(0deg) scale(1);
            }
        }
    }

    &.readonly{
        opacity: 0.5;

        &:hover {
            cursor: default;
            > span {
                &:before {
                    border: 1px solid $color_24;
                    background-color: $color_1;
                }

                &:after {
                    color: $color_1;
                }
            }

            &.checked {
                span {
                    &:before{
                        border: 1px solid $color_12;
                        background-color: $color_9;
                    }

                    &:after{
                        color: $color_12;
                    }
                }

            }
        
        }
    }
}

.custom-radio {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    min-height: $checkbox-size;
    
    cursor: pointer;

    > span {
        display: inline-block;
        vertical-align: top;
        
        position: relative;
        z-index: 5;

        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        min-height: $checkbox-size;

        font-size: 14px;
        line-height: 18px;
        padding-left: calc(#{$checkbox-size} + 10px);
        padding-top: calc((#{$checkbox-size} - 18px)/2);
        padding-bottom: calc((#{$checkbox-size} - 18px)/2);

        &:before, &:after {
            box-sizing: border-box;
            width: $checkbox-size;
            height: $checkbox-size;

            position: absolute;
            z-index: 6;
            top: 0;
            left: 0;

            border-radius: 50%;
        }
            
        &:before {
            content: "";
            border: 1px solid $color_24;
            background-color: $color_1;
            -webkit-transition: all .2s ease-in;
                    transition: all .2s ease-in;
        }

        &:after{
            content: "";
            background-color: transparent;
            text-align: center;
            z-index: 7;
            -webkit-transition: all .2s ease-in;
                    transition: all .2s ease-in;

            -webkit-transform: rotate(360deg) scale(0);
                    transform: rotate(360deg) scale(0);
        }
    }

    [type="radio"] {
        position: absolute;
        width: calc(#{$checkbox-size} - 4px);
        height: calc(#{$checkbox-size} - 4px);
        left: 2px;
        top: 2px;
        margin: 0;
        padding: 0;
        z-index: 1;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; 
    }

    &:hover {
        > span {
            &:before {
                border: 1px solid $color_12;
                background-color: $color_9;
            }

            &:after {
                color: $color_9;
            }
        }

        &.checked {
            span {
                &:before{
                    border: 1px solid $color_12;
                    background-color: $color_9;
                }

                &:after{
                    color: $color_12;
                }
            }

        }
    }

    &.checked{
        span {
            &:before{
                border: 1px solid $color_12;
                background-color: $color_9;
            }
            &:after{
                 background-color: $color_12;
                -webkit-transform: rotate(0deg) scale(0.5);
                        transform: rotate(0deg) scale(0.5);
            }
        }
    }
}


.field-validation-error {
   
}

.msg{
    font-size: 12px;
    font-weight: 300;
    display: block;

    /* colors */
    &.msg-error{
        &.msg-text{
            color: $color_21;
        }
        &.msg-box{
            color: $color_1;
            background-color: $color_21;
        }
    }

    &.msg-success {
        &.msg-text{
            color: $color_29;
        }
        &.msg-box{
            color: $color_1;
            background-color: $color_29;
        }
    }

    &.msg-info{
        &.msg-text{
            color: $color_12;
        }
        &.msg-box{
            color: $color_1;
            background-color: $color_12;
        }
    }

    /* types */

    &.msg-text{
        text-align: left;
    }

    &.msg-box{
        padding: 5px;
        text-align: center;
        line-height: 18px;
        font-size: 14px;
        margin: 11px 0 10px 0;
        
    }

   
    
   
}


/* Anonymous Form */
.anonymous-form-light {
    display: inline-block;
    vertical-align: top;
    max-width: 350px;
    width: 100%;
    padding-bottom: 35px;

    @media (max-width: 380px) {
        max-width: 100%;
    }

    @include max-tablet {
        padding: 0 20px 35px 20px;
    }

    form {
        display: block;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #fff3d4 inset;
    }

    .large-icon {
        margin: 35px auto 31px auto;

        .letter-icon {
            display: block;
            margin: auto;
        }
    }

    .general-text {
        font-size: 15px;
        line-height: 22px;
        text-align: center;
    }

    .cannot-use-account {
        margin-top: 60px;
        padding-bottom: 3px;

        @include tablet {
            margin-top: 75px;
        }
    }

    .separating-line-thin {
        background: #CCCCCC;
        height: 1px;
        width: 100%;
        margin: 25px 0;
        margin-top: 23px;
    }

    input[type="text"],
    input[type="search"],
    input[type="email"],
    input[type="number"],
    input[type="phone"],
    input[type="password"] {
        width: 100%;
        display: block;
        background-color: $page_background;
        color: $text_color;
        border: 1px solid $field_border;
        border-radius: 4px;
        margin: 0;
        font-size: 13px;
        line-height: 28px;
        padding: 5px 12px 5px 12px;


        &:focus {
            border-color: $field_focused_border;
            outline: none;
            background: $color_9;
        }

        &.input-validation-error {
            border-color: #FF0000;
        }
    }

    .field-validation-error {

        .msg.msg-error.msg-text {
            font-size: 13px;
            color: #FF0000;
            line-height: 18px;
            font-weight: 400;
        }
    }



    label {
        color: $text_color;
        text-align: left;
        font-size: 15px;
        margin-bottom: 8px;
    }

    .custom-checkbox {
        > span {
            padding-left: calc(20px + 9px);
            font-size: 13px;

            &:before, &:after {
                top: 2px;
                width: 20px;
                height: 20px;
            }

            &:after {
                line-height: 20px;
            }
        }

        &.input-validation-error > span:before {
            border-color: #FF0000;
        }

        &.checked {
            span {
                &:before {
                    border: 1px solid #00A9D6;
                    background-color: #E7F9FE;
                }

                &:after {
                    color: #00A9D6;
                }
            }
        }
    }

    .form-group {
        display: block;
        position: relative;
        margin-top: 20px;
        @include clearfix();

        &.form-first-element {
            margin-top: 0;
        }
    }

    .form-group-noSpace {
        margin-top: 0px;

        .validation-container {
            min-height: 18px;
            margin-top: 2px;

            .field-validation-error {
                position: relative;
                text-align: right;

                .msg.msg-error.msg-text {
                    text-align: right;
                }
            }
        }
    }

    .invisible {
        display: none;
    }

    .page-top-header {
        margin-bottom: 15px;
        margin-top: 18px;

        @include max-tablet {
            margin-top: 11px;
        }

        .page-header-text {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 15px;
            text-align: left;

            @include max-tablet {
                margin-bottom: 10px;
            }
        }

        .review-details-text {
            margin-bottom: 16px;

            @include max-tablet {
                margin-bottom: 10px;
            }
        }

        .separator-line {
            background: #0C2733;
            height: 4px;
            width: 100%;
        }
    }

    .check-email-header {
        margin-top: 18px;

        @include max-tablet {
            margin-top: 11px;
        }
    }

    .captcha {
        margin-top: 18px;

        .validation-container-left {
            min-height: 18px;
            margin-top: 5px;
            text-align: left;

            .field-validation-error {
                position: relative;
                color: #FF0000;
                font-size: 13px;
                display: block;
                text-align: left;
            }
        }
    }

    .terms-container {
        margin-top: 5px;
        margin-bottom: 8px;
        text-align: left;
        position: relative;

        @include mobile-portrait {
            margin-top: 7px;
        }

        .terms-text {
            font-size: 13px;
            text-align: left;
            line-height: 18px;

            a {
                color: $link_redirect;
                text-decoration: underline;
            }

            span {
                color: $text_color;
            }
        }

        .form-group {
            margin-top: 9px;

            .custom-checkbox {
                margin-bottom: 0;
            }
        }

        .form-group-first {
            margin-top: 14px;
        }

        .field-validation-error {
            position: relative;
            color: #FF0000;
            font-size: 13px;
            display: block;
        }

        .validation-container {
            min-height: 18px;
            margin-top: 5px;
        }
    }

    .form-field {
        display: block;
        position: relative;

        @include clearfix();

        label {
            font-size: 13px;
            line-height: 18px;
            display: block;
            position: relative;
        }

        input[type="text"]:read-only {
            background: #F2F2F2;
            color: $field_border;
        }

        .visiblePassword {
            position: relative;

            input::-ms-reveal,
            input::-ms-clear {
                display: none;
            }

            input[type="text"],
            input[type="password"] {
                padding-right: 40px;
                text-overflow: ellipsis;

                &:focus ~ .showButton {
                    background: $color_9;
                }
            }

            .showButton {
                height: 30px;
                width: 30px;
                position: absolute;
                top: 6px;
                right: 8px;
                background: white;
            }

            .showButton::after {
                font-family: 'icomoon';
                color: #767676;
                content: "\e91f";
                line-height: 27px;
                font-size: 27px;
            }

            .showButton.active::after {
                content: "\e920";
                color: black;
            }
        }

        .custom-dropdown {
            width: 100%;

            .dropdown-trigger {
                background-color: $page_background;
                color: $text_color;
                height: 40px;
                border-radius: 4px;
                padding: 0;
                border: $field_border solid 1px;
                font-size: 13px;
                display: flex;

                span {
                    overflow: hidden;
                    line-height: initial;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    margin: auto 10px;
                    margin-right: 35px;
                }

                &.input-validation-error {
                    border-color: #FF0000;
                }
            }

            .dropdown-trigger::before {
                color: $field_border;
                font-size: 14px;
                right: 12px;
            }
        }

        .custom-dropdown.open {
            outline: $field_focused_border solid 1px;
        }

        #projectDropdown.custom-dropdown {
            .dropdown-content {
                max-width: 100%;
            }

            li {
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .checkbox-group {
            color: $text_color;
            float: left;
            text-align: left;
            display: table-cell;
            vertical-align: middle;
            margin-bottom: 8px;

            .custom-radio,
            .custom-checkbox {
                margin-bottom: 10px;
                display: block;

                @include tablet-landscape {
                    display: inline-block;
                    margin-right: 25px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .form-actions {
        /* @include tablet {
            padding-bottom: 70px;
        }*/
        .links-row {
            margin-top: 8px;
            text-align: right;
            line-height: 18px;

            .forgot-password-link {
                font-size: 13px;
                margin-top: 0px;
                margin-bottom: 10px;
            }
        }

        .links-row-register {
            font-size: 15px;
            text-align: center;

            .register-link {
                font-size: 15px;
                margin-top: 30px;
                line-height: 20px;
            }
        }

        .btns-row {
            padding: 10px 0 0 0;
        }



        .btn {
            width: 100%;
            border-radius: 6px;
            line-height: 28px;
            font-size: 15px;
        }

        .btn.btn-blue {
            background: #00A9D6;
            border: none;
            min-height: 50px;
        }

        .link {
            font-size: 13px;
            font-weight: 400;
        }
    }

    .forgot-password-validation > div.msg.msg-box,
    .forgot-password-validation > div {
        margin-top: 10px;
    }

    .fade-in {
        animation: fadeIn ease 2.5s;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
.anonymous-form {
    padding: 0 20px;
    display: inline-block;
    vertical-align: top;
    max-width: 520px;
    width: 100%;

    @include tablet {
        padding: 0 10px;
    }


    form {
        display: block;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #123743 inset;
    }

    input[type="text"],
    input[type="search"],
    input[type="email"],
    input[type="number"],
    input[type="phone"],
    input[type="password"] {
        width: 100%;
        display: block;
        background-color: rgba($color_17, 0.7);
        color: $color_1;
        border: none;
        margin: 0;
        -webkit-text-fill-color: $color_1 !important;
        font-size: 13px;
        line-height: 28px;
        padding: 11px 11px 11px 40px;


        &:focus {
            outline: $color_12 solid 2px;
        }

        @include placeholder {
            color: $color_10;
            -webkit-text-fill-color: $color_10 !important;
        }


        @include tablet {
            font-size: 15px;
            padding: 11px 11px 11px 62px;
        }
    }

    .form-group {
        display: block;
        position: relative;
        margin-top: 8px;
        @include clearfix();

        &:first-child {
            margin-top: 0;
        }
    }

    .invisible {
        display: none;
    }

    .form-field {
        display: block;
        position: relative;

        @include clearfix();

        label {
            display: block;
            position: relative;
        }

        .user-icon,
        .password-icon {

            &:before {
                font-family: 'icomoon';
                color: $color_1;
                font-size: 26px;
                width: 32px;
                height: 32px;
                line-height: 32px;
                margin: auto;
                top: 0;
                bottom: 0;
                left: 5px;
                position: absolute;
                text-align: center;

                @include tablet {
                    /*font-size: 31px;*/
                    left: 10px;
                }
            }
        }

        .user-icon {
            &:before {
                font-size: 22px;
                content: "\e904";
            }
        }

        .password-icon {
            &:before {
                font-size: 25px;
                content: "\e903";
            }
        }


        &.empty-field {
            .user-icon,
            .password-icon {
                &:before {
                    color: $color_10;
                }
            }
        }

        .custom-dropdown {
            width: 100%;

            .dropdown-trigger {
                background-color: rgba($color_17, 0.7);
                color: white;
                height: 50px;
                border-radius: 0px;
                padding-top: 16px;
                border: none;
                font-size: 15px;
            }
        }

        .custom-dropdown.open {
            outline: $color_12 solid 2px;
        }
    }

    .form-actions {
        @include tablet {
            padding-bottom: 70px;
        }

        .links-row {
            padding: 16px 0 0 0;
            text-align: right;
            line-height: 22px;
        }

        .btns-row {
            padding: 21px 0 0 0;
        }



        .btn {
            width: 100%;
            border-radius: 8px;
            line-height: 28px;
            font-size: 16px;

            @include tablet {
                font-size: 18px;
            }

            @include tablet-landscape {
                line-height: 32px;
                font-size: 22px;
            }
        }

        .link {
            font-size: 12px;
            font-weight: 300;

            @include tablet {
                font-size: 16px;
            }
        }
    }

    .logo {
        width: 100%;
        margin: 0 0 50px 0;

        @include tablet-landscape {
            margin: 0 0 96px 0;
        }

        figure {
            width: 100%;
            text-align: center;
            margin: 0;
            padding: 0;
        }

        img {
            display: inline-block;
            vertical-align: top;
            width: 46%;
            max-width: 332px;

            @include tablet-landscape {
                width: auto;
                max-width: none;
                max-height: 92px;
            }
        }
    }
}


.flex-fields{

    @include mobile-landscape {
         @include flex-container(map-merge($flex-container-default, (
            direction: row,
            wrap: nowrap,
            horizontal: flex-start,
            vertical: center
        )));
    }
   
    .flex{
        @include mobile-landscape {
            @include flex();
        }
    }
}

.two-column-holder{
    max-width: 580px;
    margin: auto;
    @include tablet-landscape{
        display: table;
        width: 100%;
        max-width: 100%;
        table-layout: fixed;

    }

    .cell{
        @include tablet-landscape{
            display: table-cell;
            vertical-align: top;

             &:first-child{
                width: 266px;
            }
        }
    }
}


.form-list{
    .form-list-tool{
        padding: 10px 8px;
    }

    .form-list-holder{
        height: 30vh;
        overflow: auto;
        border: 1px solid $color_24;
        display: block;
        padding: 8px;

    }
    
    li{
        display: block;

        margin-top: 12px;
        &:first-child{
            margin-top:0;
        }
    }
}
.mce-container{
    .tinymce-msg{
        display: none;

        position: absolute;

        padding: 6px 12px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 300;
        left: 10px;
        right: 10px;
        top: 0;
        margin: auto;

        &[data-type=success]{
            background-color: $color_29;
            color: $color_1;
        }

        &[data-type=info]{
            background-color: $color_13;
            color: $color_1;
        }

        &[data-type=error]{
            background-color: $color_28;
            color: $color_1;
        }


        &.open{
            display: block;
        }

        .close-tinymce-msg{
            position: absolute;
            right: 5px;
            top: 5px;
            height: 16px;
            width: 16px;
            text-align: center;
            vertical-align: 16px;
            font-size: 12px;
            color: $color_1;


            &:before{
                font-family: "icomoon";
                content: "\e90c";
                display: inline-block;
                vertical-align: top;
            }
        }
    }
}


#global_filter {
    padding: 10px 90px 10px 10px;
}

.search-box {
    position: relative;

    input[type="search"],
    input[type="text"] {
        box-sizing: border-box;
        color: $color_3;
        font-size: 13px;
        line-height: 18px;
        padding: 10px 53px 10px 10px;
        display: block;
        background-color: $color_1;
        border: 1px solid $color_7;
        border-radius: $btn-radius;
        width: 100%;
        -webkit-transition: all .2s ease-in;
        transition: all .2s ease-in;
        outline: none;

        &:hover, &:focus {
            background-color: $color_9;
            border: 1px solid $color_12;
        }

        &.input-validation-error {
            border: 1px solid $color_21;
        }

        &[readonly] {
            /*border: 1px solid $color_1;
                font-style: italic;

                &:hover, &:focus {
                background-color: $color_1;
                border: 1px solid $color_1;
            }*/
        }
    }

    .search-btn {
        background-color: $blue_btn;
        color: $blue_btn_text;
        position: absolute;
        right: 0;
        top: 0;
        height: 40px;
        line-height: 40px;
        width: 43px;
        text-align: center;
        border: 1px solid #8f9292;
        font-size: 22px;
        border-radius: 0 $btn-radius $btn-radius 0;

        @include desktop {
            &:hover {
                background-color: $blue_btn_hover;
                color: $blue_btn_hover_text;
                border: 1px solid $blue_btn_hover;
            }
        }

        &:before {
            font-family: 'icomoon';
            content: "\e916";
            display: inline-block;
            vertical-align: top;
            height: 38px;
            line-height: 38px;
        }
    }
}


.flex-form {

    @include tablet-landscape{
         @include flex-container(map-merge($flex-container-default, (
            direction: row,
            horizontal: flex-start,
            vertical: flex-start
        )));
    }
   

    > .flex {
        @include tablet-landscape{
            @include flex(1, 1, 1px);
            padding: 0 16px;
        }
    }
}

input.hidden {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    outline: none;
    border: 0px;
    display: none;
}

.popUpLoader {
  width: 100%;
  height: 100%;
  z-index:1000;
  margin: 0em;
  left: 0em;
  top: 0em;
  display:none;
  position: fixed;

  .popupImage {
    width: 35px;
    height: 35px;
    z-index:1000;
    left: calc(50% - 17.5px);
    top: calc(50% - 17.5px);
    position: fixed;
    background-image: url(../images/loading-circle.png);
    background-size: 35px 35px;
  }
}